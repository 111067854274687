import {Injectable} from '@angular/core';
import {BAT_CALCULATION_ID} from "@modules/calculation-impl/batting/_calculator/batting-calculation.module";
import {BLOW_IN_CALCULATION_ID} from "@modules/calculation-impl/blow-in-calculator/blow-in-calculator.module";
import {FOAM_CALCULATION_ID} from "@modules/calculation-impl/foam/foam-calculation.module";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {
  BattingInsulationProductTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/CategoryTransformers/batting-insulation-product-migrator.service";
import {
  BattingWoolProductTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/CategoryTransformers/batting-wool-product-migrator.service";
import {
  BlowInBlowProductTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/CategoryTransformers/blow-in-blow-product-migrator.service";
import {
  BlowInNetProductTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/CategoryTransformers/blowin-net-product-migrator.service";
import {
  FoamProductTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/CategoryTransformers/foam-product-migrator.service";

@Injectable({
  providedIn: 'root'
})
export class TransformerFactory {

  constructor(
    private readonly foamProductMigrator: FoamProductTransformer,
    private readonly battingInsulationProductMigrator: BattingInsulationProductTransformer,
    private readonly battingMineralWoolMigrator: BattingWoolProductTransformer,
    private readonly blowInProductMigrator: BlowInBlowProductTransformer,
    private readonly blowInNetProductMigrator: BlowInNetProductTransformer,
  ) {
  }

  execute(strategy: CalculationStrategyID): AbstractTransformer[] {
    if (strategy.equals(FOAM_CALCULATION_ID)) {
      return [this.foamProductMigrator];
    }
    if (strategy.equals(BAT_CALCULATION_ID)) {
      return [this.battingInsulationProductMigrator, this.battingMineralWoolMigrator];
    }

    if (strategy.equals(BLOW_IN_CALCULATION_ID)) {
      return [this.blowInProductMigrator, this.blowInNetProductMigrator];
    }

    return [];
  }
}
