import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  AppInitBusinessUnitsProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-business-units-provider.service";
import {
  AppInitUserProfileProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-user-profile-provider.service";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";

@Injectable({
  providedIn: 'root'
})
export class GetUserBusinessUnitQuery implements UseCase<void, BusinessUnit> {
  constructor(
    private readonly appInitUserProvider: AppInitUserProfileProvider,
    private readonly appInitBusinessUnitsProvider: AppInitBusinessUnitsProvider,
  ) {
  }

  execute(): BusinessUnit {
    const userBusinessUnitID = this.appInitUserProvider.getUserProfile().businessUnitID;
    const availableBusinessUnits = this.appInitBusinessUnitsProvider.getBusinessUnits();
    const userBusinessUnit = availableBusinessUnits.getById(userBusinessUnitID);

    if (!userBusinessUnit) {
      throw new Error('User business unit not found in available business units');
    }

    return userBusinessUnit;
  }
}
