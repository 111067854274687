import {MiscAreaItem} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {
  CalculationAreaTotalField
} from "@modules/calculation/Domain/CalculationAreaTotal/VO/calculation-area-total-field";
import {FieldType} from "@modules/calculation/Domain/CalculationAreaTotal/VO/field-type";

export class MiscAreaTotal implements CalculationAreaTotal {
  readonly fields: CalculationAreaTotalField[] = [];
  readonly totalCost: number;

  constructor(public readonly misc: MiscAreaItem[]) {
    let total = 0;
    for (const item of misc) {
      total += item.getTotal();
      this.fields.push(new CalculationAreaTotalField(item.name, item.getTotal(), FieldType.CURRENCY));
    }
    this.totalCost = total;
  }
}
