@if (loading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}

@if (!loading) {
  <ion-list>
    @if (selectedProducts.length) {
      <form [formGroup]="form">
        <ion-item lines="none">
          <ion-input
            clear-input
            errorText="Overridden price must be a number"
            formControlName="overriddenPrice"
            inputmode="numeric"
            label="Input overridden price"
            label-placement="floating"
            placeholder="Overridden Price"
            type="text"
          ></ion-input>
          <ion-button
            (click)="overridePrice()"
            [disabled]="!form.valid"
            expand="block"
            slot="end"
          >
            Save
          </ion-button>
        </ion-item>
      </form>
    }
    @if (!selectedProducts.length) {
      <ion-item>
        <ion-label>
          <ion-note>
            No products selected
          </ion-note>
        </ion-label>
      </ion-item>
    }
    @for (product of products; track product) {
      <ion-item
        (click)="toggleProduct(product, $event)"
      >
        <ion-checkbox
          [checked]="selectedProducts.includes(product)"
          slot="start"
        ></ion-checkbox>
        <ion-label>
          <ion-text color="primary">
            <h2>{{ product.name }}</h2>
          </ion-text>
          <ion-text>
            <p>{{ product.getPrice() | currency: 'USD': 'symbol' : '1.0-10' }}</p>
          </ion-text>
        </ion-label>
      </ion-item>
    }
  </ion-list>
}
