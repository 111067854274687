import {BlowInBlowProduct} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/blow-in-blow-product";
import {
  BlowInAreaTypeEnum
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/Enum/blow-in-service-type-enum";
import {Products} from "@modules/product/old-products/Domain/Product/products";

export class BlowInBlowProducts extends Products<BlowInBlowProduct> {
  filterProductsByBlowInType(selectedBlowInType: BlowInServiceTypeEnum): BlowInBlowProducts {
    const products = this.products.filter(product => product.blowInType === selectedBlowInType);
    return new BlowInBlowProducts(products);
  }

  getProductById(id: string): BlowInBlowProduct | undefined {
    return this.products.find(product => product.blowInProduct === id);
  }

  getBlowInTypes(): BlowInServiceTypeEnum[] {
    const availableTypes = this.products.map(product => product.blowInType);
    return [...new Set(availableTypes)];
  }

  filterProductsByAreaType(areaType: BlowInAreaTypeEnum): BlowInBlowProducts {
    const products = this.products.filter(product => product.isApplicableForAreaType(areaType));
    return new BlowInBlowProducts(products);
  }

  filterProductsByRValue(rValue: number): BlowInBlowProducts {
    const products = this.products.filter(product => product.rValue === rValue);
    return new BlowInBlowProducts(products);
  }

  getRValues(): number[] {
    const rValues = this.products.map(product => product.rValue);
    const uniqueRValues = [...new Set(rValues)];
    return uniqueRValues.sort((a, b) => a - b);
  }
}
