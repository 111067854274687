import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";

export class Categories {
  constructor(private readonly categories: Category[]) {
  }

  filterBySearchTerm(searchTerm: string): Categories {
    const categories = this.categories.filter(
      category => category.isApplicableToSearchTerm(searchTerm)
    );
    return new Categories(categories);
  }

  sortByName(): Categories {
    const categories = this.categories.sort(
      (a, b) => a.name.getValue().localeCompare(b.name.getValue())
    );
    return new Categories(categories);
  }

  toArray(): Category[] {
    return this.categories;
  }

  findByID(id: CategoryID): Category | undefined {
    return this.categories.find(category => category.id.equals(id))
  }
}
