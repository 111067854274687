import {Injectable} from '@angular/core';
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {
  EstimateID as ServiceTitanEstimateID
} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";
import {PushedEstimate} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/pushed-estimate";
import {PushedEstimates} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/pushed-estimates";
import {
  DynamicsPushedEstimate
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/PushedEstimate/Model/dynamics-pushed-estimate";

@Injectable({
  providedIn: 'root'
})
export class DynamicsPushedEstimateMapper {
  private readonly estimateTableName = "cr9b4_ifoamestimatecalculations";

  mapToDomain(models: DynamicsPushedEstimate[]): PushedEstimates {
    const estimates = models.map(
      (model) => new PushedEstimate(
        new EstimateID(model._cr9b4_estimate || ''),
        new ServiceTitanEstimateID(Number(model.cr9b4_servicetitanid)),
        Number(model.cr9b4_amount)
      )
    );
    return new PushedEstimates(estimates);
  }

  mapFromDomain(estimate: PushedEstimate): DynamicsPushedEstimate {
    return {
      cr9b4_servicetitanid: estimate.serviceTitanID.toString(),
      cr9b4_amount: estimate.amount,
      "cr9b4_Estimate@odata.bind": `/${this.estimateTableName}(${estimate.estimateID.toString()})`
    };
  }
}
