export class ValidationError {
  constructor(
    public readonly field: string,
    public readonly message: string
  ) {
  }

}

export class ValidationResult {
  constructor(
    public readonly isValid: boolean,
    public readonly errors: ValidationError[] = []
  ) {
  }

  static combine(...results: ValidationResult[]): ValidationResult {
    const errors = results.flatMap(result => result.errors);
    return new ValidationResult(errors.length === 0, errors);
  }

  static fromStrings(fieldName: string, errors: string[]): ValidationResult {
    return new ValidationResult(
      errors.length === 0,
      errors.map(error => new ValidationError(fieldName, error))
    );
  }
}
