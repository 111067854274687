@if (form) {
  <form [formGroup]="form">
    <ion-list lines="none">
      <app-width-length [form]="form"></app-width-length>
      <app-batting-area-product-selector [form]="form"></app-batting-area-product-selector>
      <app-crew-selector [form]="form"></app-crew-selector>
      <ion-item>
        <ion-input
          appClearZero
          errorText="Project Job Hours is required"
          formControlName="projHours"
          inputmode="numeric"
          label="Project Job Hours"
          label-placement="floating"
          placeholder="Project Job Hours"
          type="number"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          appClearZero
          errorText="Miscellaneous is required"
          formControlName="miscellaneous"
          inputmode="numeric"
          label="Miscellaneous"
          label-placement="floating"
          placeholder="Miscellaneous"
          type="number"
        ></ion-input>
      </ion-item>
    </ion-list>
    @if (form.valid && calculationResult && isBattingServiceSelected()) {
      <ion-list>
        <ion-item>
          <ion-label>Select the materials cost source:</ion-label>
        </ion-item>
        <ion-radio-group formControlName="materialSource">
          <ion-item lines="none">
            <ion-label (click)="this.form.patchValue({materialSource:BattingMaterialSourceEnum.bagsCost})">
              Job Cost
            </ion-label>
            <ion-radio [value]="BattingMaterialSourceEnum.bagsCost" aria-label="Bags Cost" slot="start"></ion-radio>
            <ion-note color="primary" slot="end">{{ calculationResult.bagsCost | currency }}</ion-note>
          </ion-item>
          <ion-item>
            <ion-label (click)="this.form.patchValue({materialSource:BattingMaterialSourceEnum.fullStackCost})">
              Purchase / Order Cost
            </ion-label>
            <ion-radio
              [value]="BattingMaterialSourceEnum.fullStackCost"
              aria-label="Full Stack Cost"
              slot="start">
            </ion-radio>
            <ion-note color="primary" slot="end">{{ calculationResult.fullStackCost | currency }}</ion-note>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    }
    @if (form.invalid) {
      <ion-list>
        <ion-item>
          <ion-label>
            Please fix the following fields before you are able to see and select between Job Cost or
            Purchase/Order Cost:
            <ion-note color="danger">{{ getFormErrors().join(', ') }}</ion-note>
          </ion-label>
        </ion-item>
      </ion-list>
    }
  </form>
}
