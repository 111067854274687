import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {Product} from "@modules/product/product/Domain/Product/product";


/**
 * In old the product model fields are named as follows:
 * - product: name
 * - orderPricingPerSqft: price
 */
export class BattingMineralWoolProduct extends Product {
  public readonly rValue: RValue;
  public readonly onCenter: OnCenter;
  public readonly depth: number;

  constructor(...params: ConstructorParameters<typeof Product>) {
    super(...params);

    this.rValue = new RValue(this.getDropDownField('rValue'));
    this.onCenter = new OnCenter(Number(this.getDropDownField('onCenter')));
    this.depth = this.getNumberField('depth');
  }

  static fromProduct(product: Product): BattingMineralWoolProduct {
    return new BattingMineralWoolProduct(
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      product.price,
      product.ownerBusinessUnitID,
      product.category,
      product.getAllFields(),
      product.rootBusinessUnitID,
      product.originalProduct
    )
  }
}
