import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {ActionSheetButton, ActionSheetController, ActionSheetOptions} from "@ionic/angular";
import {MenuEntry} from "../../Domain/menu-entry";

@Component({
  selector: 'app-tree-dot-menu',
  templateUrl: './tree-dot-menu.component.html',
  styleUrls: ['./tree-dot-menu.component.scss'],
  standalone: false
})
export class TreeDotMenuComponent implements OnChanges, OnDestroy {
  @Input() menuEntries: MenuEntry[] | undefined;
  protected buttonID: string = Math.random().toString(36).substring(7);
  protected loading: boolean = false;
  private currentActionSheet: HTMLIonActionSheetElement | undefined;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private readonly router: Router
  ) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['menuEntries']) {
      await this.currentActionSheet?.dismiss();
    }
  }

  ngOnDestroy(): void {
    this.currentActionSheet?.dismiss();
  }

  routerHandler(routerLink: string[]): Promise<boolean> {
    return this.router.navigate(routerLink);
  }

  async openMenu(): Promise<void> {
    if (!this.menuEntries) return;

    const buttons: ActionSheetButton[] = this.menuEntries.map(menuEntry => {
      const handler = this.createHandler(menuEntry);
      return {
        text: menuEntry.title,
        icon: menuEntry.icon,
        handler: handler
      }
    });
    buttons.push({
      text: 'Cancel',
      role: 'cancel'
    });

    const options: ActionSheetOptions = {
      buttons: buttons,
    };

    this.currentActionSheet = await this.actionSheetCtrl.create(options);
    await this.currentActionSheet.present();
  }

  private createHandler(menuEntry: MenuEntry) {
    if (menuEntry.routerLink) {
      const routerLink = menuEntry.routerLink;
      return () => this.routerHandler(routerLink);
    }

    if (menuEntry.callback) {
      const callback = menuEntry.callback;
      return async () => {
        this.loading = true;
        try {
          await callback()
        } finally {
          this.loading = false;
        }
        return Promise.resolve(true);
      };
    }

    throw new Error('Invalid menu entry');
  }
}
