import {Injectable} from "@angular/core";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {
  BattingMineralWoolProduct
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-product";
import {
  BattingMineralWoolProducts
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-products";
import {
  BattingMineralWoolDynamicsModel
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Infrastructure/Model/batting-mineral-wool-dynamics-model";
import {
  DynamicsProductsFactory
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductRepository/Factory/dynamics-products-factory";

@Injectable({'providedIn': 'root'})
export class BattingMineralWoolDynamicsProductsFactory extends DynamicsProductsFactory<BattingMineralWoolProducts, BattingMineralWoolProduct> {
  execute(data: BattingMineralWoolDynamicsModel[]): BattingMineralWoolProducts {
    return new BattingMineralWoolProducts(
      data.map((product) => this.createMineralWoolProduct(product))
    );
  }

  private createMineralWoolProduct(data: BattingMineralWoolDynamicsModel) {
    const rValue = new RValue(data.cr9b4_rvalue);
    const onCenter = new OnCenter(data.cr9b4_oncenter);

    return new BattingMineralWoolProduct({
      id: data.cr9b4_ifoamicalcproductsbattingmineralwoolid,
      product: data.cr9b4_product,
      rValue: rValue,
      onCenter: onCenter,
      widthInches: data.cr9b4_widthinches,
      tallInches: data.cr9b4_tallinches,
      orderPricingPerSqft: data.cr9b4_orderpricingpersqft,
      sqftPerPkg: data.cr9b4_sqftperpkg,
      costPer1000Sqft: data.cr9b4_costper1000sqft,
      depth: data.cr9b4_depth,
    });
  }
}
