<ion-content>

  <app-header [explicitBackUrl]="backLink" [rightButton]="rightButton" [title]="title"></app-header>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  <ion-searchbar
    (ngModelChange)="searchBusinessUnits($event)"
    [(ngModel)]="searchTerm"
    [debounce]="100"
    placeholder="Search"
  ></ion-searchbar>

  @if (filteredBusinessUnits) {
    <ion-list>
      @for (businessUnit of filteredBusinessUnits.toArray(); track businessUnit; let i = $index) {
        <ion-item
          (click)="selectBusinessUnit(businessUnit)"
          [routerLink]="[]"
          >
          @if (selectedBusinessUnit && selectedBusinessUnit.id.equals(businessUnit.id)) {
            <ion-label
              color="primary"
              >
              <span>{{ businessUnit.name }}</span> (*)
            </ion-label>
          } @else {
            <ion-label>{{ businessUnit.name }}</ion-label>
          }
        </ion-item>
      }
    </ion-list>
  }
</ion-content>
