import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink} from "@angular/router";
import {IonicModule} from "@ionic/angular";
import {SavingStatusComponent} from "@modules/_shared/Component/saving-status/saving-status.component";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {environment} from "@modules/environments/environment";
import {HeaderModule} from "@modules/header/header.module";
import {PRODUCT_PRICES_TABLE} from "@modules/product/old-products/Application/DI/product-prices-table-token";
import {ProductRepository} from "@modules/product/old-products/Domain/Product/Repository/product-repository";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/old-products/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";
import {
  DynamicsProductOverriddenPriceRepository
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductOverriddenPriceRepository/dynamics-product-overridden-price-repository.service";
import {
  DynamicsProductRepository
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductRepository/dynamics-product-repository";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterLink,
    HeaderModule,
    SavingStatusComponent,
    ClearZeroDirective,
    ReactiveFormsModule
  ],
  providers: [
    {provide: PRODUCT_PRICES_TABLE, useValue: environment.productPricesTable},
    {provide: ProductRepository, useExisting: DynamicsProductRepository},
    {provide: ProductOverriddenPriceRepository, useClass: DynamicsProductOverriddenPriceRepository}
  ],
  exports: []
})
export class OldProductsModule {
}
