import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {
  UpdateCalculationAreaUseCase
} from "@modules/calculation-area/Application/UseCase/Command/UpdateCalculationArea/update-calculation-area-use-case";
import {
  GetCalculationAreaUseCase
} from "@modules/calculation-area/Application/UseCase/Query/GetCalculationArea/get-calculation-area-use-case";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-factory";
import {
  MiscAreaItem,
  ProductMiscAreaItem
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {
  MiscAreaLinkProvider
} from "@modules/calculation-impl/miscellaneous-calculator/Infrastructure/Provider/misc-area-link-provider.service";
import {
  MiscAreaItemFormGroupFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Factory/misc-area-item-form-group-factory.service";
import {
  MiscAreaItemMapper
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Mapper/misc-area-item-mapper.service";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({providedIn: 'root'})
export class MiscItemCreateUpdateService {
  constructor(
    private readonly getCalculationAreaUseCase: GetCalculationAreaUseCase,
    private readonly miscAreaItemsFormGroupFactory: MiscAreaItemFormGroupFactory,
    private readonly miscAreaItemMapper: MiscAreaItemMapper,
    private readonly updateCalculationAreaUseCase: UpdateCalculationAreaUseCase,
    private readonly miscAreaFactory: MiscAreaFactory
  ) {
  }

  createEmptyFormGroup(): FormGroup {
    return this.miscAreaItemsFormGroupFactory.createEmptyFormGroup();
  }

  createFormGroup(item: MiscAreaItem): FormGroup {
    return this.miscAreaItemsFormGroupFactory.createFormGroup(item);
  }

  async getCalculationArea(areaID: string): Promise<MiscArea> {
    return await this.getCalculationAreaUseCase.execute<MiscArea>(areaID);
  }

  async mapToDomain(form: FormGroup): Promise<MiscAreaItem | ProductMiscAreaItem> {
    return await this.miscAreaItemMapper.mapToDomain(form);
  }

  async updateCalculationArea(area: MiscArea): Promise<void> {
    return this.updateCalculationAreaUseCase.execute(
      area.id,
      await this.miscAreaFactory.createState(area)
    );
  }

  getMiscAreaLink(calculationID: CalculationID): string[] {
    return MiscAreaLinkProvider.getMiscAreaLink(calculationID);
  }
}
