import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {
  CalculationAreaTotalField
} from "@modules/calculation/Domain/CalculationAreaTotal/VO/calculation-area-total-field";
import {FieldType} from "@modules/calculation/Domain/CalculationAreaTotal/VO/field-type";

export class BlowInAreaTotal implements CalculationAreaTotal {
  readonly fields: CalculationAreaTotalField[] = [];

  constructor(
    public readonly laborHours: number,
    public readonly laborCost: number,
    public readonly netProduct: string | null,
    public readonly blowInProduct: string,
    public readonly sqft: number,
    public readonly bags: number,
    public readonly materialCost: number,
    public readonly materialSalesTax: number,
    public readonly totalCost: number,
    public readonly misc: number,
    public readonly blowStacksNeeded: number,
    public readonly netRollsUsed: number,
    public readonly netMaterialCost: number,
    public readonly blowInMaterialCost: number
  ) {
    if (this.netProduct) {
      this.fields.push(new CalculationAreaTotalField('Net Product', this.netProduct, FieldType.STRING));
    }

    this.fields.push(
      new CalculationAreaTotalField('BlowIn Product', blowInProduct, FieldType.STRING),
      new CalculationAreaTotalField('Labor Hours', laborHours),
      new CalculationAreaTotalField('SQ/FT', sqft),
      new CalculationAreaTotalField('Material Cost', materialCost, FieldType.CURRENCY),
      new CalculationAreaTotalField('Material Sales Tax', materialSalesTax, FieldType.CURRENCY),
      new CalculationAreaTotalField('Bags', bags),
    );
  }
}
