import {Component, OnInit} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {RequestQueueService} from "@modules/_shared/Service/request-queue.service";
import {
  GetAllCalculationsQuery
} from "@modules/calculation/Application/UseCase/Query/get-all-calculations-query.service";
import {
  ProductsMigratorAreaProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Extract/migrate-area-provider.service";
import {
  MigratorArea
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Extract/migrator-area";
import {
  ProductsMigratorAreaSaver,
  ProductsMigratorAreaSaverRequest
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Load/products-migrator-area-saver.service";
import {
  ProductsInAreasMigrator
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/products-migrator.service";
import {
  ProductsMigrator
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/products-migrator.service";

@Component({
  selector: 'app-products-migrator',
  templateUrl: './products-migrator.component.html',
  styleUrls: ['./products-migrator.component.scss'],
  standalone: false
})
export class ProductsMigratorComponent implements OnInit {
  title: string = 'Products Migrator';
  protected loading: boolean = false
  protected migrating: boolean = false;
  protected calculationsCount: number = 0;
  protected processedCalculationsCount: number = 0;
  private areas: MigratorArea[] = [];

  constructor(
    private readonly getAllCalculations: GetAllCalculationsQuery,
    private readonly productsMigratorAreaProvider: ProductsMigratorAreaProvider,
    private readonly productsInAreasMigrator: ProductsInAreasMigrator,
    private readonly notificationService: NotificationService,
    private readonly requestQueueService: RequestQueueService,
    private readonly productsMigratorAreaSaver: ProductsMigratorAreaSaver,
    private readonly productsMigrator: ProductsMigrator
  ) {
  }

  ngOnInit() {
    this.loadCalculations().then();
  }

  getReadyPercent(): number {
    return this.processedCalculationsCount / this.calculationsCount;
  }

  migrateProducts() {
    this.migrating = true
    this.productsMigrator.execute().then().finally(() => this.migrating = false);
  }

  async migrateAreas(): Promise<void> {
    this.migrating = true;
    const batchSize = 1000;
    const processed: ProductsMigratorAreaSaverRequest[][] = [];
    let currentBatch: ProductsMigratorAreaSaverRequest[] = [];
    let totalCount = 0;

    for (const area of this.areas) {
      const request = await this.processMigration(area);
      if (request) {
        currentBatch.push(request);
        totalCount++;
        if (currentBatch.length === batchSize) {
          processed.push(currentBatch);
          currentBatch = [];
        }
      }
    }

    // Push any remaining requests in the last batch
    if (currentBatch.length > 0) {
      processed.push(currentBatch);
    }

    try {
      this.calculationsCount = totalCount;
      this.processedCalculationsCount = 0;

      await Promise.all(
        processed.map(async (batch) => {
          await this.productsMigratorAreaSaver.execute(batch);
          this.processedCalculationsCount += batch.length;
        })
      );
      this.notificationService.execute('Areas migrated').then();
    } catch (error) {
      await this.notificationService.execute(error);
      throw error;
    } finally {
      this.migrating = false;
    }
  }

  private async loadCalculations(): Promise<void> {
    this.loading = true
    try {
      this.areas = await this.productsMigratorAreaProvider.execute();
      this.calculationsCount = this.areas.length;
      this.processedCalculationsCount = 0;
    } catch (e) {
      await this.notificationService.execute(e)
      throw e;
    } finally {
      this.loading = false
    }
  }

  private increaseProcessedCalculationsCount() {
    this.processedCalculationsCount++
  }

  private async processMigration(area: MigratorArea): Promise<ProductsMigratorAreaSaverRequest | undefined> {
    try {
      return this.productsInAreasMigrator.execute(area)
    } catch (e) {
      await this.notificationService.execute(e)
      throw e;
    } finally {
      this.increaseProcessedCalculationsCount()
    }
  }
}
