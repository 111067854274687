import {NgOptimizedImage} from "@angular/common";
import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule, ModalController} from "@ionic/angular";

@Component({
  selector: 'app-energy-star-recommendation',
  templateUrl: './energy-star-recommendation.component.html',
  styleUrls: ['./energy-star-recommendation.component.scss'],
  imports: [
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgOptimizedImage
  ],
  standalone: true
})
export class EnergyStarRecommendationComponent {

  constructor(
    private readonly modalCtrl: ModalController,
  ) {
  }

  async cancel() {
    await this.modalCtrl.dismiss();
  }
}
