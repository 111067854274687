import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {Products} from "@modules/product/product/Domain/Product/products";
import {
  BattingMineralWoolProduct
} from "@modules/product/products/batting-mineral-wool-product/Domain/Product/batting-mineral-wool-product";

export class BattingMineralWoolProducts extends Products<BattingMineralWoolProduct> {

  getRValueItems(): RValue[] {
    const rvalues = this.products.map(product => product.rValue);
    const uniqueRvalues = Array.from(
      new Set(rvalues.map(rvalue => rvalue.value)))
      .map(value => new RValue(value));

    uniqueRvalues.sort((a, b) => {
      return a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'});
    });
    return uniqueRvalues;
  }

  getOnCenterItems(): OnCenter[] {
    const onCenters = this.products.map(product => product.onCenter);
    return onCenters
      .filter((onCenter, index, self) =>
          index === self.findIndex((t) => (
            t.equals(onCenter)
          ))
      )
      .sort((a, b) => a.greaterThan(b));
  }

  filterProductsByOnCenter(onCenter: OnCenter): BattingMineralWoolProducts {
    const products = this.products.filter(product => product.onCenter.equals(onCenter));
    return new BattingMineralWoolProducts(products);
  }

  filterProductsByRValue(rValue: RValue): BattingMineralWoolProducts {
    const products = this.products.filter(product => product.rValue.equals(rValue));
    return new BattingMineralWoolProducts(products);
  }

  filterProductsByDepth(depth: number): BattingMineralWoolProducts {
    const products = this.products.filter(product => product.depth === depth);
    return new BattingMineralWoolProducts(products);
  }

  getDepths(): number[] {
    const depths = this.products.map(product => product.depth);
    return Array.from(new Set(depths)).sort((a, b) => a - b);
  }
}
