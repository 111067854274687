import {Injectable} from '@angular/core';
import {
  RecordShownContextNotificationCommand
} from "@modules/area-context-notifications/Application/UseCase/Command/record-shown-context-notification-command.service";
import {
  GetNotificationsQuery
} from "@modules/area-context-notifications/Application/UseCase/Query/get-notifications-query.service";
import {
  UserNotificationEnabledQuery
} from "@modules/area-context-notifications/Application/UseCase/Query/IsUserOptedOutOfNotificationQuery/is-user-opted-out-of-notification-query.service";
import {
  NotificationActionProcessor
} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action-processor";
import {Notifications} from "@modules/area-context-notifications/Domain/Notification/notifications";
import {NotificationEvent} from "@modules/area-context-notifications/Domain/NotificationEvent/notification-event";
import {
  NotificationEventInput
} from "@modules/area-context-notifications/Domain/NotificationEvent/notification-event-input";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {BehaviorSubject, combineLatest} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ContextNotification {
  // Subjects that emit events when template, area, or input changes
  private readonly templateChanged$ = new BehaviorSubject<CalculationStrategyID | undefined>(undefined);
  private readonly areaChanged$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly inputEvent$ = new BehaviorSubject<NotificationEventInput | undefined>(undefined);
  private notifications?: Notifications;

  constructor(
    private readonly getNotificationsQuery: GetNotificationsQuery,
    private readonly actionProcessor: NotificationActionProcessor,
    private readonly userNotificationEnabledQuery: UserNotificationEnabledQuery,
    private readonly recordShownContextNotificationCommand: RecordShownContextNotificationCommand,
  ) {
    this.subscribeToNotifications();
  }

  async getNotifications(): Promise<Notifications> {
    if (!this.notifications) {
      this.notifications = await this.getNotificationsQuery.execute();
    }
    return this.notifications;
  }


  templateDestroyed(): void {
    this.templateChanged$.next(undefined);
    this.areaChanged$.next(undefined);
    this.inputEvent$.next(undefined);
  }

  onTemplateSelected(templateName: CalculationStrategyID): void {
    this.templateChanged$.next(templateName);
  }

  onAreaNameChange(areaName: string): void {
    this.areaChanged$.next(areaName);
  }

  onInputEvent(event: Event): void {
    const inputName = (event.target as HTMLInputElement).name;
    const eventName = event.type;
    const value = (event.target as HTMLInputElement).value;

    this.inputEvent$.next({
      inputName,
      eventName,
      value
    });
  }

  private subscribeToNotifications(): void {
    const events$ = combineLatest([
      this.templateChanged$,
      this.areaChanged$,
      this.inputEvent$
    ]);

    events$.subscribe(([template, area, event]) => {
      if (!template || !area) return;
      this.processEvent(template, area, event).then(r => r);
    });
  }

  private async processEvent(
    calculation: CalculationStrategyID,
    area: string,
    inputEvent?: NotificationEventInput
  ): Promise<void> {
    const trigger: NotificationEvent = {
      calculation,
      area,
      inputEvent
    }

    const notifications = await this.getNotifications();
    const applicableNotifications = notifications.getNotificationsByEvent(trigger);

    const enabledNotifications = await Promise.all(
      applicableNotifications.map(
        async notification => {
          const shouldShow = await this.userNotificationEnabledQuery.execute(notification);
          return {notification, shouldShow};
        }
      )
    );

    const filteredNotifications = enabledNotifications
      .filter(result => result.shouldShow)
      .map(result => result.notification);

    filteredNotifications.forEach(notification => {
      this.recordShownContextNotificationCommand.execute(notification.notificationID);
    });

    this.actionProcessor.showNotifications(filteredNotifications).then();
  }
}
