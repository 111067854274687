import {Injectable} from '@angular/core';
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsArea
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Extract/dynamics-area";
import {
  MigratorArea
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Extract/migrator-area";
import {RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class ProductsMigratorAreaProvider {

  constructor(
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery
  ) {
  }

  public async execute(): Promise<MigratorArea[]> {
    const request: RetrieveMultipleRequest = {
      collection: 'cr9b4_ifoamcalculationtabs',
      select: ['cr9b4_state', 'cr9b4_ifoamcalculationtabid'],
      //filter: 'cr9b4_areastate eq null',
      expand: [
        {
          property: 'cr9b4_EstimateCalculationID',
          select: ['cr9b4_calculationtype', '_owningbusinessunit_value']
        }
      ]
    };

    const result = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsArea>(request);
    return result.value
      .filter(area => area.cr9b4_EstimateCalculationID)
      .map((area: DynamicsArea) => {
        return {
          id: area.cr9b4_ifoamcalculationtabid,
          calculationStrategyID: new CalculationStrategyID(area.cr9b4_EstimateCalculationID.cr9b4_calculationtype),
          state: area.cr9b4_state,
          rowBusinessUnitID: area.cr9b4_EstimateCalculationID._owningbusinessunit_value
        }
      });
  }
}
