import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {
  DynamicFormFieldsToFormTransformer
} from "@modules/dynamic-form/Domain/DynamicFormField/Service/dynamic-form-fields-to-form-transformer";

@Injectable({
  providedIn: 'root'
})
export class TransformDynamicFormFieldsToFormGroupCommand implements UseCase<DynamicFormFields, FormGroup> {

  constructor(
    private readonly dynamicFormFieldsToFormTransformer: DynamicFormFieldsToFormTransformer<FormGroup>
  ) {
  }

  execute(formFields: DynamicFormFields): FormGroup {
    return this.dynamicFormFieldsToFormTransformer.transform(formFields);
  }
}
