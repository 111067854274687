import {AccountInfo, AuthenticationResult} from '@azure/msal-browser';
import {Observable} from 'rxjs';

export abstract class IMsAlAccountService {
  abstract getCurrentAccountPromise(): Promise<AccountInfo | null>;

  abstract getCurrentAccount(): Observable<AccountInfo>;

  abstract getIsLoggedIn$(): Observable<boolean>;

  abstract setAccountPayload(payload: AuthenticationResult): void;

  abstract checkAndSetActiveAccount(): void;
}
