import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  CategoryProductCreationManager
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {
  IsCurrentUserCorporateQuery
} from "@modules/product/product/Application/Service/is-current-user-corporate.service";
import {
  ZeeUpdateProductStrategy
} from "@modules/product/product/Application/UseCase/Command/UpdateProduct/zee-update-product-strategy.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {
  UserBusinessUnitProvider
} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/user-business-unit-provider";


@Injectable({
  providedIn: 'root'
})
export class UpdateProductCommand implements UseCase<Product, Promise<Product>> {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly businessUnitProvider: UserBusinessUnitProvider,
    private readonly isCurrentUserCorporate: IsCurrentUserCorporateQuery,
    private readonly categoryProductCreationManager: CategoryProductCreationManager,
    private readonly zeeUpdateProductStrategy: ZeeUpdateProductStrategy
  ) {
  }

  async execute<T extends Product>(product: Product): Promise<T> {
    const userBusinessUnit = await this.businessUnitProvider.getUserBusinessUnit();
    const isCorporateUser = this.isCurrentUserCorporate.execute();

    const finishedProduct = isCorporateUser
      ? await this.productRepository.update(product)
      : await this.zeeUpdateProductStrategy.execute(product, userBusinessUnit.id);

    return this.categoryProductCreationManager.createProduct<T>(finishedProduct);
  }
}
