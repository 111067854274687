import {Injectable} from '@angular/core';
import {GetCurrentGmInputs} from "@modules/gm-inputs/Application/UseCase/Query/get-current-gm-inputs.service";

@Injectable({
  providedIn: 'root'
})
export class PinAuthService {
  private readonly pinStorageKey = 'pinData';
  private readonly pinValidityDuration = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds

  constructor(
    private readonly getCurrentGmInputs: GetCurrentGmInputs,
  ) {
  }

  async login(pin?: string): Promise<boolean> {
    if (!pin) {
      pin = this.loadStoredPin();
      if (!pin) return false;
    }

    const inputs = await this.getCurrentGmInputs.execute();
    if (inputs.checkPin(pin)) {
      this.storePin(pin);
      return true;
    }

    return false;
  }

  storePin(pin: string): void {
    const expiration = new Date().getTime() + this.pinValidityDuration;
    localStorage.setItem(this.pinStorageKey, JSON.stringify({pin, expiration}));
  }

  loadStoredPin(): string | undefined {
    const storedPinData = localStorage.getItem(this.pinStorageKey);
    if (storedPinData) {
      const {pin, expiration} = JSON.parse(storedPinData);
      if (new Date().getTime() < expiration) {
        return pin;
      } else {
        this.clearStoredPin();
      }
    }
    return undefined;
  }

  clearStoredPin(): void {
    localStorage.removeItem(this.pinStorageKey);
  }
}
