import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {FoamProduct} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-product";
import {Products} from "@modules/product/old-products/Domain/Product/products";

export class FoamProducts extends Products<FoamProduct> {
  filterProductsByService(service: FoamService): FoamProducts {
    return new FoamProducts(
      this.products.filter(product => product.service.isEqual(service))
    );
  }
}
