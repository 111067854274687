import {
  RoofPitchCalculationMethod
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/roof-pitch-calculation-method";
import {
  RoofPitchCalculationMethodName
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/VO/roof-pitch-calculation-method-name";

export class RoofPitchCalculationMethods {
  public readonly methods: RoofPitchCalculationMethod[];

  constructor() {
    this.methods = [RoofPitchCalculationMethods.PitchFactor, RoofPitchCalculationMethods.ValleyHipFactor];
  }

  static get PitchFactor(): RoofPitchCalculationMethod {
    return new RoofPitchCalculationMethod(new RoofPitchCalculationMethodName('Roof Pitch Factor'));
  }

  static get ValleyHipFactor(): RoofPitchCalculationMethod {
    return new RoofPitchCalculationMethod(new RoofPitchCalculationMethodName('Valley Hip Factor'));
  }


  findByName(name: RoofPitchCalculationMethodName): RoofPitchCalculationMethod | undefined {
    return this.methods.find(method => method.name.equals(name));
  }

}
