import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {GeneralManagerAuthGuard} from "@modules/gm-inputs/Infrastructure/Auth/can-activate-gm-inputs";
import {
  ProductCategoriesListComponent
} from "@modules/product/product-category/Presentation/products-categories-list/product-categories-list.component";


const routes: Routes = [
  {
    path: '',
    component: ProductCategoriesListComponent,
    canActivate: [GeneralManagerAuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class ProductCategoryRoutingModule {
}
