import {Inject, Injectable} from '@angular/core';
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {
  GetUserBusinessUnitQuery
} from "@modules/business-unit/Application/UseCase/Query/get-user-business-unit-query.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

@Injectable({
  providedIn: 'root'
})
export class IsCurrentUserCorporateQuery {
  constructor(
    private readonly getUserBusinessUnitQuery: GetUserBusinessUnitQuery,
    private readonly getBusinessUnitsUseCase: GetBusinessUnitsUseCase,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
  }

  execute(): boolean {
    const allBusinessUnits = this.getBusinessUnitsUseCase.execute();
    const userBusinessUnitID = this.getUserBusinessUnitQuery.execute().id;
    const rootBusinessUnitID = new BusinessUnitID(this.rootBusinessUnit);

    /**
     if (environment.environment === EnvironmentType.local) {
     return userBusinessUnitID.equals(rootBusinessUnitID);
     }
     **/

    return allBusinessUnits.checkIfSameOrAscendant(userBusinessUnitID, rootBusinessUnitID);
  }
}
