@if (dataState===DataSavingState.DIRTY) {
  <ion-badge color="warning">not saved</ion-badge>
}
@if (dataState===DataSavingState.SAVING) {
  <ion-badge color="warning">saving...</ion-badge>
}
@if (dataState===DataSavingState.SAVED) {
  <ion-badge color="success">saved</ion-badge>
}
@if (dataState===DataSavingState.ERROR) {
  <ion-badge color="danger">error</ion-badge>
}
