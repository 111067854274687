import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {DynamicFormField} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-field";

@Component({
  selector: 'app-dynamic-from-fields-number',
  templateUrl: './dynamic-from-fields-number.component.html',
  styleUrls: ['./dynamic-from-fields-number.component.scss'],
  imports: [
    ReactiveFormsModule,
    IonicModule,
    ClearZeroDirective
  ],
  standalone: true
})
export class DynamicFromFieldsNumberComponent {
  @Input({required: true}) formGroup?: FormGroup;
  @Input({required: true}) field?: DynamicFormField<ControlTypes>;
}
