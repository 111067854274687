import {OnCenterValue} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValueOption} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {FieldValidator} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validator";

export class BattingMineralWoolCategoryFieldsConfig extends FieldsConfig {
  constructor() {
    super(BattingMineralWoolCategoryFieldsConfig.getFields());
  }

  private static getFields(): FieldConfig[] {
    return [
      new FieldConfig(new FieldConfigName('rValue'), 'R-Value', 'R-Value', FieldType.Dropdown, new FieldValidator([]), [
        ...Object.values(RValueOption)
      ]),
      new FieldConfig(new FieldConfigName('onCenter'), 'On Center', 'On Center', FieldType.Dropdown, new FieldValidator([]), [
        ...Object.values(OnCenterValue)
          .filter(v => typeof v === 'number')
          .map(v => v.toString())
      ]),
      new FieldConfig(new FieldConfigName('depth'), 'Width', 'Width', FieldType.Number, new FieldValidator([])),
    ];
  }
}
