import {Injectable} from "@angular/core";
import pLimit from "p-limit";

@Injectable({providedIn: 'root'})
export class RequestQueueService {
  async execute<R>(
    promiseFunctions: (() => Promise<R>)[],
    batchSize: number = 50
  ): Promise<R[]> {
    const limit = pLimit(batchSize);
    return Promise.all(promiseFunctions.map((promiseFunction) => limit(promiseFunction)));
  }
}
