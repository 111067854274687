<div [formGroup]="form">
  <ion-item>
    <ion-select
      formControlName="blowInType"
      label="Select Blow In Service"
      label-placement="floating"
      placeholder="Select Service"
    >
      @for (item of blowInTypes; track item) {
        <ion-select-option [value]="item">
          {{ item }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="construction"
      label="Select Construction"
      label-placement="floating"
      placeholder="Select construction"
    >
      @for (item of constructionSize; track item.name) {
        <ion-select-option [value]="item.name">
          {{ item.name }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="rValue"
      label="Select R-Value"
      label-placement="floating"
      placeholder="Select R-Value"
    >
      @for (rValue of rValueItems; track rValue.toString()) {
        <ion-select-option [value]="rValue">
          {{ rValue }}
        </ion-select-option>
      }
    </ion-select>
    <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
  </ion-item>

  <ion-item>
    <ion-select
      formControlName="product"
      label="Select Product"
      label-placement="floating"
      placeholder="Select Product"
    >
      @for (product of filteredProducts; track product.id.toString()) {
        <ion-select-option [value]="product.id.toString()">
          {{ product.name.getValue() }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>
  @if (filteredProducts && filteredProducts.length === 0) {
    <ion-item>
      <ion-note color="danger">No product exists in the database for this scenario</ion-note>
    </ion-item>
  }
</div>
