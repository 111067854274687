import {Injectable} from '@angular/core';
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  BattingAreaOptions
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-options";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";

@Injectable({
  providedIn: 'root'
})
export class BattingInsulationProductTransformer extends AbstractTransformer {
  async execute(area: BattingAreaOptions): Promise<BattingAreaOptions> {
    if (area.battingServiceName !== BattingServiceEnum.BattInsulation) return Promise.resolve(area);

    const productID = area.productID as unknown;
    if (undefined === productID) return Promise.resolve(area);

    if (!this.isNumeric(productID) || 0 === productID) {
      area.productID = undefined;
      return Promise.resolve(area);
    }

    const newProducts = await this.getNewProducts(new BattingInsulationCategoryName());
    const newProduct = newProducts.toArray().find(
      product => product.name.getValue().startsWith(Number(productID).toString())
    )

    if (!newProduct) throw new Error(`Cannot find new product for old ID ${productID}`);

    area.productID = newProduct.id.getValue();

    return area;
  }

  isNumeric = (num: unknown) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num as number);

}
