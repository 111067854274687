import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {
  TransformDynamicFormFieldsToFormGroupCommand
} from "@modules/dynamic-form/Application/UseCase/Command/TransformDFFToForm/transform-dynamic-form-fields-to-form-group-command.service";
import {
  FormGroupToTransformDynamicFormFieldsCommand
} from "@modules/dynamic-form/Application/UseCase/Command/TransformFormToDFF/transform-form-group-to-dynamic-form-fields-command.service";
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {
  DynamicFormFieldsToFormTransformer
} from "@modules/dynamic-form/Domain/DynamicFormField/Service/dynamic-form-fields-to-form-transformer";
import {
  DynamicFormFieldsToFormGroupTransformer
} from "@modules/dynamic-form/Infrastructure/Service/form-fields-to-form-group-transformer.service";
import {
  DynamicFormFieldsDropdownComponent
} from "@modules/dynamic-form/Presentation/Component/dynamic-form-fields-dropdown/dynamic-form-fields-dropdown.component";
import {
  DynamicFormFieldsStringComponent
} from "@modules/dynamic-form/Presentation/Component/dynamic-form-fields-string/dynamic-form-fields-string.component";
import {
  DynamicFromFieldsNumberComponent
} from "@modules/dynamic-form/Presentation/Component/dynamic-from-fields-number/dynamic-from-fields-number.component";

@Component({
  selector: 'app-dynamic-form-fields-renderer',
  templateUrl: './dynamic-form-fields-renderer.component.html',
  styleUrls: ['./dynamic-form-fields-renderer.component.scss'],
  providers: [
    {provide: DynamicFormFieldsToFormTransformer, useExisting: DynamicFormFieldsToFormGroupTransformer},
    TransformDynamicFormFieldsToFormGroupCommand,
    FormGroupToTransformDynamicFormFieldsCommand
  ],
  imports: [
    IonicModule,
    DynamicFromFieldsNumberComponent,
    DynamicFormFieldsStringComponent,
    DynamicFormFieldsDropdownComponent,
    ReactiveFormsModule
  ],
  standalone: true
})
export class DynamicFormFieldsRendererComponent implements OnInit {
  @Input({required: true}) fields?: DynamicFormFields;
  formGroup?: FormGroup;
  @Output() fieldsChanged = new EventEmitter<DynamicFormFields>();
  protected readonly ControlTypes = ControlTypes;

  constructor(
    private readonly fieldsToFormTransformer: TransformDynamicFormFieldsToFormGroupCommand,
    private readonly formToFieldTransformer: FormGroupToTransformDynamicFormFieldsCommand
  ) {
  }

  ngOnInit(): void {
    if (!this.fields) throw new Error('Fields is required to render the form');

    this.formGroup = this.fieldsToFormTransformer.execute(this.fields);
    this.formGroup.valueChanges.subscribe(
      () => this.processChangedForm()
    );
  }

  private processChangedForm() {
    if (!this.formGroup) throw new Error('Form is required to process the form');
    if (!this.fields) throw new Error('Fields is required to render the form');

    const updatedFields = this.formToFieldTransformer.execute({
      formGroup: this.formGroup,
      fields: this.fields
    });
    this.fieldsChanged.emit(updatedFields);
  }
}
