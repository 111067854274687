import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmDialogService} from "@modules/_shared/Service/ConfirmDialog/confirm-dialog.service";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  UpdateProductCommand
} from "@modules/product/product/Application/UseCase/Command/UpdateProduct/update-product-command.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-products-batch-mode-editor',
  templateUrl: './products-batch-mode-editor.component.html',
  styleUrls: ['./products-batch-mode-editor.component.scss'],
  standalone: false
})
export class ProductsBatchModeEditorComponent implements OnInit {
  @Input({required: true}) products: Product[] = [];
  @Output() pricesUpdated = new EventEmitter<Product[]>();

  protected loading = false;

  protected selectedProducts: Product[] = [];
  protected form: FormGroup = new FormGroup({
    overriddenPrice: new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.pattern(/^\d+(\.\d{1,2})?$/),
    ]),
  });

  constructor(
    private readonly confirmDialogService: ConfirmDialogService,
    private readonly notificationService: NotificationService,
    private readonly updateProductCommand: UpdateProductCommand,
    private readonly loadingService: LoadingService,
  ) {
  }

  ngOnInit() {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);

    this.selectedProducts = [];
  }

  toggleProduct(product: Product, $event: MouseEvent) {
    this.selectedProducts = this.selectedProducts.includes(product) ?
      this.selectedProducts.filter(p => p !== product) :
      this.selectedProducts.concat(product);

    $event.stopPropagation();
  }

  async overridePrice() {
    if (this.form.invalid) {
      return;
    }

    if (!this.selectedProducts.length) {
      return;
    }

    const newPrice = parseFloat(this.form.value.overriddenPrice);

    const message = `
    Are you sure you want to override the price for ${this.selectedProducts.length} products to $${newPrice}?
    This action cannot be undone.
    `;

    const confirmed = await this.confirmDialogService.confirm(message, 'Override Price', 'destructive');
    if (!confirmed) return;

    this.loading = true;

    try {
      const newPrices = await this.updateProductsPrice(newPrice);
      this.pricesUpdated.emit(newPrices);
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  private async updateProductsPrice(newPrice: number): Promise<Product[]> {
    return await Promise.all(this.selectedProducts.map(async product => {
      const newProduct = product.setPrice(newPrice);
      return this.updateProductCommand.execute(newProduct);
    }));
  }
}
