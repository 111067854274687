import {Inject, Injectable} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {GetProductsByType} from "@modules/product/old-products/Application/UseCase/Query/get-products-by-type";
import {Product as ProductOld} from "@modules/product/old-products/Domain/Product/product";
import {Products as ProductsOld} from "@modules/product/old-products/Domain/Product/products";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {
  GetCategoryByNameQuery
} from "@modules/product/product-category/Application/UseCase/Query/GetCategoryByName/get-category-by-name-query.service";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductBusinessOwnerID} from "@modules/product/product/Domain/Product/VO/product-business-owner-id";
import {ProductDescription} from "@modules/product/product/Domain/Product/VO/product-description";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductManufacturer} from "@modules/product/product/Domain/Product/VO/product-manufacturer";
import {ProductName} from "@modules/product/product/Domain/Product/VO/product-name";
import {ProductPrice} from "@modules/product/product/Domain/Product/VO/product-price";

@Injectable({
  providedIn: 'root'
})
export abstract class ProductProvider<
  TOldProducts extends ProductsOld<TOldProduct>,
  TOldProduct extends ProductOld
> {
  private category?: Category;

  constructor(
    private readonly getProductsByType: GetProductsByType,
    private readonly getCategoryByNameQuery: GetCategoryByNameQuery,
    private readonly notificationService: NotificationService,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
  }


  async execute(): Promise<Product[]> {
    const oldProducts = await this.getProductsByType.execute<TOldProducts, TOldProduct>(
      this.getProductType().id
    )
    this.notificationService.execute(`Migrating ${oldProducts.products.length} ${this.getCategoryName()} products`).then();
    return this.mapProducts(oldProducts.products);
  }

  protected abstract getProductType(): ProductType;

  protected abstract getFields(product: TOldProduct): Fields;

  protected abstract getManufacturer(product: TOldProduct): string;

  protected abstract getCategoryName(): CategoryName;

  private async mapProduct(product: TOldProduct): Promise<Product> {
    const category = await this.getCategory();
    const rootBusinessUnit = new ProductBusinessOwnerID(this.rootBusinessUnit);
    try {
      return new Product(
        new ProductID('00000000-0000-0000-0000-000000000000'),
        new ProductName(product.name),
        new ProductDescription('Migrated product'),
        new ProductManufacturer(this.getManufacturer(product)),
        new ProductPrice(product.getOriginalPrice().value),
        rootBusinessUnit,
        category,
        this.getFields(product),
        new BusinessUnitID('00000000-0000-0000-0000-000000000000'),
        undefined
      );
    } catch (e) {
      this.notificationService.execute(e).then();
      throw e;
    }
  }

  private async mapProducts(oldProducts: TOldProduct[]): Promise<Product[]> {
    return await Promise.all(
      oldProducts.map(async oldProduct => await this.mapProduct(oldProduct))
    );
  }

  private async getCategory(): Promise<Category> {
    if (!this.category) {
      this.category = await this.getCategoryByNameQuery.execute(this.getCategoryName());
    }

    return this.category;
  }
}
