import {Injectable} from '@angular/core';
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {
  UserOwnsProductQuery
} from "@modules/product/product/Application/UseCase/Query/UserOwnsProduct/user-owns-product-query.service";
import {Product, PRODUCT_PRICE_FIELD} from "@modules/product/product/Domain/Product/product";
import {
  CategoryToProductDFFTransformer
} from "@modules/product/product/Presentation/Service/ProductToDffTransformer/category-to-product-d-f-f-transformer.service";

@Injectable({
  providedIn: 'root'
})
export class ProductToDffTransformer {
  constructor(
    private readonly categoryToDFFTransformerService: CategoryToProductDFFTransformer,
    private readonly userOwnsProductQuery: UserOwnsProductQuery
  ) {
  }

  async execute(product: Product): Promise<DynamicFormFields> {
    const categoryFields = this.categoryToDFFTransformerService.execute(product.category);
    const shouldDisableFields = !this.userOwnsProductQuery.execute(product);

    if (shouldDisableFields) {
      return this.fillValues(product, categoryFields.disableAllExcept(PRODUCT_PRICE_FIELD));

    }

    return this.fillValues(product, categoryFields);
  }

  private fillValues(product: Product, fields: DynamicFormFields): DynamicFormFields {
    const values = product.getValues();
    return fields.setValues(values);
  }
}
