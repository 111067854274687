import {CalculationStrategy} from "@modules/calculation-strategy/Domain/CalculationStrategy/calculation-strategy";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";

export abstract class CalculationRepository {
  abstract getCalculationsByEstimateID(estimateID: EstimateID): Promise<Calculation[]>;

  abstract deleteCalculationFromEstimate(calculationID: CalculationID): Promise<void>;

  abstract getCalculationByID<TStrategy extends CalculationStrategy>(calculationID: CalculationID): Promise<Calculation<TStrategy>>;

  abstract createCalculation(
    estimateID: EstimateID,
    strategyID: CalculationStrategyID
  ): Promise<Calculation>;

  abstract getAllCalculations(): Promise<Calculation[]>;
}
