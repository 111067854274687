import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";


export class FieldNumber extends Field<number> {
  constructor(value: number, name: FieldName) {
    super(value, name, FieldType.Number);
  }
}
