import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProfileID} from "@modules/profile/Domain/Profile/VA/profile-id";

interface ProfileOptions {
  profileID: string;
  fullName: string;
  businessUnitId: string;
  businessUnitName: string;
  email: string;
  title: string;
}

export class Profile {
  public readonly profileID: ProfileID;
  public readonly fullName: string;
  public readonly businessUnitID: BusinessUnitID;
  public readonly businessUnitName: string;
  public readonly email: string;
  public readonly title: string;

  constructor(options: ProfileOptions) {
    if (!options.profileID) {
      throw new Error('Profile profileID is required');
    }

    if (!options) {
      throw new Error('Profile options are required');
    }

    if (!options.fullName) {
      throw new Error('Profile fullName is required');
    }

    if (!options.businessUnitId) {
      throw new Error('Profile businessUnitId is required');
    }

    if (!options.businessUnitName) {
      throw new Error('Profile businessUnitName is required');
    }
    if (!options.email) {
      throw new Error('Profile email is required');
    }

    if (!options.title) {
      options.title = 'Test';
    }

    this.profileID = new ProfileID(options.profileID);
    this.fullName = options.fullName;
    this.businessUnitID = new BusinessUnitID(options.businessUnitId);
    this.businessUnitName = options.businessUnitName;
    this.email = options.email;
    this.title = options.title;
  }
}
