import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";


export class FieldDropdown extends Field<string> {
  protected override _type!: void;

  constructor(value: string, name: FieldName) {
    super(value, name, FieldType.Dropdown);
  }
}
