import {inject, Injectable} from "@angular/core";
import {CanActivate, CanActivateFn, Router} from "@angular/router";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {PinAuthService} from "@modules/gm-inputs/Presentation/Service/pin-auth.service";

@Injectable({
  providedIn: 'root'
})
export class CanActivateGmInputs implements CanActivate {
  private userIsAuthenticated: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly pinStorageService: PinAuthService
  ) {
  }

  async canActivate(): Promise<boolean> {
    if (environment.environment === EnvironmentType.test) return true;
    if (environment.environment === EnvironmentType.local) return true;

    if (this.userIsAuthenticated) {
      return true;
    }

    if (await this.pinStorageService.login()) {
      this.userIsAuthenticated = true;
      return true;
    }

    await this.router.navigate(['/gm-inputs', 'pin']);
    return false;
  }
}

export const GeneralManagerAuthGuard: CanActivateFn = async (): Promise<boolean> => {
  return await inject(CanActivateGmInputs).canActivate();
}
