import {Injectable} from "@angular/core";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CrewsMapperService
} from "@modules/calculation-tabs/Infrastructure/Repository/DynamicsCaltulatorTabRepository/Factory/crews-mapper.service";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculatorTabFactory} from "../../../../Application/Factory/CalculatorTab/calculator-tab-factory";
import {CalculatorTabsFactory} from "../../../../Application/Factory/CalculatorTab/calculator-tabs-factory";
import {CalculatorTabs} from "../../../../Domain/CalculatorTab/Aggreagate/calculator-tabs";
import {CalculatorTab} from "../../../../Domain/CalculatorTab/calculator-tab";
import {CalculatorTabOptions} from "../../../../Domain/CalculatorTab/calculator-tab-options";
import {DynamicsCalculatorTab} from "../Type/dynamics-calculator-tab";

interface TabState {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

@Injectable({
  providedIn: 'root'
})
export class DynamicsCalculatorTabFactory {
  private requiredFields: (keyof DynamicsCalculatorTab)[] = [
    "cr9b4_ifoamcalculationtabid",
    "cr9b4_name",
    "_cr9b4_estimatecalculationid_value"
  ];

  constructor(
    private readonly calculatorTabFactory: CalculatorTabFactory,
    private readonly calculatorTabsFactory: CalculatorTabsFactory,
    private readonly requiredValidator: RequiredFieldsValidator,
    private readonly crewsMapperService: CrewsMapperService
  ) {
  }


  async createCalculatorTabs(tabs: DynamicsCalculatorTab[]): Promise<CalculatorTabs> {
    const tabOptions: CalculatorTabOptions[] = [];
    for (const tab of tabs) {
      try {
        this.requiredValidator.validate(tab, ["cr9b4_name"]);
        const tabOption = await this.createCalculatorTabOption(tab);
        tabOptions.push(tabOption);
      } catch (e: unknown) {
        console.error(`Tab with id ${tab.cr9b4_ifoamcalculationtabid}: ${e}`);
      }
    }

    return this.calculatorTabsFactory.create(tabOptions);
  }

  createDynamicsCalculatorFields(tab: Partial<CalculatorTab>): Partial<DynamicsCalculatorTab> {
    const options: Partial<DynamicsCalculatorTab> = {};

    if (tab.name) {
      options.cr9b4_name = tab.name;
    }

    if (tab.businessUnitID) {
      tab.businessUnitID.getValue();
    }

    if (tab.state) {
      const state = {
        ...tab.state,
        laborCrew: this.crewsMapperService.mapFrom((tab.state as TabState)['laborCrew'])
      };
      options.cr9b4_areastate = JSON.stringify(state);
    }

    return options;
  }

  async createCalculatorTab(response: DynamicsCalculatorTab): Promise<CalculatorTab> {
    if (!response.cr9b4_areastate) {
      response.cr9b4_areastate = JSON.stringify({});
    }
    this.requiredValidator.validate(response, this.requiredFields);
    const tabOption = await this.createCalculatorTabOption(response);
    return this.calculatorTabFactory.create(tabOption);
  }

  private async createCalculatorTabOption(tab: DynamicsCalculatorTab): Promise<CalculatorTabOptions> {
    let state = JSON.parse(tab.cr9b4_areastate);
    if (!state) {
      state = {};
    }

    state.businessUnitID = new BusinessUnitID(tab._owningbusinessunit_value);
    state.laborCrew = await this.crewsMapperService.mapTo(state.laborCrew, state.businessUnitID);

    return {
      id: tab.cr9b4_ifoamcalculationtabid,
      name: tab.cr9b4_name,
      state: state,
      calculationID: new CalculationID(tab._cr9b4_estimatecalculationid_value),
      businessUnitID: new BusinessUnitID(tab._owningbusinessunit_value)
    };
  }
}
