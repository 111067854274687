import {Injectable} from '@angular/core';
import {Product} from "@modules/product/old-products/Domain/Product/product";
import {Products} from "@modules/product/old-products/Domain/Product/products";
import {
  ProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/product-provider.service";
import {
  BattingInsulationProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/providers/batting-insulation-product-provider.service";
import {
  BattingMineralWoolProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/providers/batting-mineral-wool-product-provider.service";
import {
  BlowInBlowProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/providers/blow-in-blow-product-provider.service";
import {
  BlowInNetProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/providers/blow-in-net-product-provider.service";
import {
  FoamProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/providers/foam-product-provider.service";

@Injectable({
  providedIn: 'root'
})
export class ProvidersFactory {
  constructor(
    private readonly foamProvider: FoamProductProvider,
    private readonly battingInsulationProvider: BattingInsulationProvider,
    private readonly battingMineralWoolProductProvider: BattingMineralWoolProductProvider,
    private readonly blowInBlowProductProvider: BlowInBlowProductProvider,
    private readonly blowInNetProductProvider: BlowInNetProductProvider,
  ) {
  }

  execute(): ProductProvider<Products<Product>, Product>[] {
    return [
      this.foamProvider,
      this.battingInsulationProvider,
      this.battingMineralWoolProductProvider,
      this.blowInBlowProductProvider,
      this.blowInNetProductProvider
    ];
  }
}
