import {
  NotificationCategoryID
} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-category-i-d";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

export class UserInteractionEvent {
  constructor(
    public readonly category: NotificationCategoryID,
    public readonly notificationID?: NotificationID,
  ) {
  }
}
