<app-header
  [explicitBackUrl]="getBackUrl()"
  [rightButton]="rightButton"
  [title]="title"
  titleInfo="Please enter the miscellaneous product name. You can select an existing product or set up a custom one."
></app-header>

<ion-content>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }
  @if (form) {
    <app-misc-item-form [form]="form"></app-misc-item-form>
  }
</ion-content>
