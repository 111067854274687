import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
  MiscAreaItem,
  ProductMiscAreaItem
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";
import {
  GetProductsByCategoryNameRequest
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-request";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";
import {MiscProducts} from "@modules/product/products/misc-product/Domain/Product/misc-products";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaItemMapper {
  constructor(
    private readonly getProductsByCategoryNameQuery: GetProductsByCategoryNameQuery
  ) {
  }

  async mapToDomain(form: FormGroup): Promise<MiscAreaItem | ProductMiscAreaItem> {
    const productID = form.get('productID')?.value;
    if (productID) {
      return this.mapProductToDomain(form, productID);
    }

    return new MiscAreaItem(
      form.get('name')?.value,
      form.get('value')?.value,
      form.get('count')?.value
    );
  }

  private async mapProductToDomain(form: FormGroup, productID: string): Promise<ProductMiscAreaItem> {
    const request: GetProductsByCategoryNameRequest = {categoryName: new MiscCategoryName()};
    const products = await this.getProductsByCategoryNameQuery.execute<MiscProducts>(request);
    const product = products.getByID(new ProductID(productID));
    if (!product) {
      throw new Error(`Product with ID ${productID} not found`);
    }
    return new ProductMiscAreaItem(product, form.get('count')?.value);
  }
}
