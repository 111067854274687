import {Categories} from "@modules/product/product-category/Domain/Category/categories";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";

export abstract class CategoryRepository {
  abstract getAll(): Promise<Categories>

  abstract getByName(name: CategoryName): Promise<Category>

  abstract getByID(id: CategoryID): Promise<Category>

  abstract create(category: Category): Promise<Category>
}
