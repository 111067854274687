import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  ProductGroupProvider
} from "@modules/product/old-products/Application/Configuration/product-types-provider.service";
import {Product} from "@modules/product/old-products/Domain/Product/product";
import {Products} from "@modules/product/old-products/Domain/Product/products";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/old-products/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";
import {
  DynamicsProductRepository
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductRepository/dynamics-product-repository";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({providedIn: 'root'})
export class GetProductsByType {
  constructor(
    private readonly dynamicsProductRepository: DynamicsProductRepository<Products<Product>, Product>,
    private readonly productGroupProvider: ProductGroupProvider,
    private readonly productPriceRepository: ProductOverriddenPriceRepository,
    private readonly getProfileUseCase: GetCurrentUserProfileUseCase
  ) {
  }

  async execute<P extends Products<T>, T extends Product>(
    productTypeID: string,
    businessUnitID?: BusinessUnitID
  ): Promise<P> {
    if (!businessUnitID) {
      const profile = await this.getProfileUseCase.execute();
      businessUnitID = profile.businessUnitID;
    }

    const [productType, configuration] = await Promise.all([
      this.productGroupProvider.getTypeByID(productTypeID),
      this.productGroupProvider.getDynamicsConf(productTypeID)
    ]);

    const products: P = await this.dynamicsProductRepository.getAll(configuration) as P;
    const prices = await this.productPriceRepository.getAll(productType, businessUnitID);

    products.assignPrices(prices);
    return products;
  }
}
