import {Injectable} from '@angular/core';
import {
  BusinessUnitStateProvider
} from "@modules/_shared/Service/SelectedBusinessUnit/SavedBusinessUnit/business-unit-state-provider";
import {
  GetBusinessUnitsUseCase
} from "@modules/business-unit/Application/UseCase/Query/get-business-units-use-case.service";
import {
  GetUserAvailableBusinessUnitsQuery
} from "@modules/business-unit/Application/UseCase/Query/get-user-available-b-u-query.service";
import {
  GetUserBusinessUnitQuery
} from "@modules/business-unit/Application/UseCase/Query/get-user-business-unit-query.service";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {BehaviorSubject, take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserSelectedBusinessUnit {
  private readonly _selectedBusinessUnit$ = new BehaviorSubject<BusinessUnit | null>(null);
  public readonly selectedBusinessUnit$ = this._selectedBusinessUnit$.asObservable();

  constructor(
    private readonly getBusinessUnitsUseCase: GetBusinessUnitsUseCase,
    private readonly getUserBusinessUnitQuery: GetUserBusinessUnitQuery,
    private readonly businessUnitStateProvider: BusinessUnitStateProvider,
    private readonly getUserAvailableBusinessUnitsQuery: GetUserAvailableBusinessUnitsQuery
  ) {
  }

  public init(): void {
    const selectedBusinessUnitID = this.businessUnitStateProvider.getSelectedBusinessUnit();
    if (selectedBusinessUnitID) {
      this.setSelectBusinessUnitByID(selectedBusinessUnitID);
      return;
    }
    this.setDefaultSelectedBusinessUnit();
  }

  public getSelectedBusinessUnitPromise(): Promise<BusinessUnit> {
    return new Promise((resolve, reject) => {
      this.selectedBusinessUnit$.pipe(take(1)).subscribe({
        next: (businessUnit) => {
          if (!businessUnit) {
            reject(new Error('Business unit is not set.'));
          } else {
            resolve(businessUnit);
          }
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  setSelectedBusinessUnit(businessUnit: BusinessUnit): void {
    this.businessUnitStateProvider.setSelectedBusinessUnit(businessUnit.id);
    this._selectedBusinessUnit$.next(businessUnit);
  }

  private setSelectBusinessUnitByID(businessUnitID: BusinessUnitID): void {
    const businessUnits = this.getBusinessUnitsUseCase.execute();
    const selectedBusinessUnit = businessUnits.getById(businessUnitID);

    if (!selectedBusinessUnit) throw new Error('Business unit not found');

    this.setSelectedBusinessUnit(selectedBusinessUnit);
    this.checkBusinessUnitAvailability(businessUnitID);
  }

  private setDefaultSelectedBusinessUnit(): void {
    const userBusinessUnit = this.getUserBusinessUnitQuery.execute();
    this.setSelectedBusinessUnit(userBusinessUnit);
  }

  private checkBusinessUnitAvailability(businessUnitID: BusinessUnitID) {
    this.getUserAvailableBusinessUnitsQuery.execute().then(
      (availableBusinessUnits) => {
        if (!availableBusinessUnits.getById(businessUnitID)) {
          this.setDefaultSelectedBusinessUnit();
        }
      }
    );
  }
}
