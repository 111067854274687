import {Injectable} from '@angular/core';
import {
  UserBusinessUnitProvider
} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/user-business-unit-provider";

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitHeadersProvider {
  private readonly BUSINESS_UNIT_ID_HEADER = "X-Business-Unit-ID";

  constructor(
    private readonly businessUnitProvider: UserBusinessUnitProvider
  ) {
  }

  async execute(): Promise<{ [key: string]: string }> {
    const businessUnit = await this.businessUnitProvider.getUserBusinessUnit();
    return {
      [this.BUSINESS_UNIT_ID_HEADER]: businessUnit.id.toString()
    };
  }
}
