import {Injectable} from "@angular/core";
import {
  UserNotificationStatusRepository
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/Repository/user-notification-status-repository";
import {
  UserNotificationStatus
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/user-notification-status";
import {
  DynamicsUserNotificationStatusMapper
} from "@modules/area-context-notifications/Infrasturcutre/Repository/UserNotificationStatus/Mapper/dynamics-user-notification-status-mapper";
import {
  DynamicsUserNotificationStatus
} from "@modules/area-context-notifications/Infrasturcutre/Repository/UserNotificationStatus/Model/dynamics-user-notification-status";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {CreateRequest, RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({providedIn: 'root'})
export class DynamicsUserNotificationStatusRepository extends UserNotificationStatusRepository {
  private readonly tableName = 'cr9b4_ifoamicalcnotificationsuseroptedouts';

  constructor(
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsUserNotificationStatusMapper: DynamicsUserNotificationStatusMapper,
  ) {
    super();
  }

  async getStatus(userID: string, notificationID: string): Promise<UserNotificationStatus> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `cr9b4_name eq '${notificationID}' and _createdby_value eq '${userID}'`,
    }

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsUserNotificationStatus>(request);
    if (response.value.length === 0) {
      return this.dynamicsUserNotificationStatusMapper.mapFrom({
        cr9b4_name: notificationID,
        statecode: 1,
        _createdby_value: userID
      });
    }
    return this.dynamicsUserNotificationStatusMapper.mapFrom(response.value[0]);
  }

  createStatus(userNotificationStatus: UserNotificationStatus): Promise<void> {
    const createRequest: CreateRequest = {
      collection: this.tableName,
      data: this.dynamicsUserNotificationStatusMapper.mapTo(userNotificationStatus),
      returnRepresentation: false
    }

    return this.dynamicsCreateCommand.execute(createRequest);
  }
}
