@if (form) {
  <form [formGroup]="form">
    <ion-list lines="none">
      <app-width-length [form]="form"></app-width-length>
      <app-crew-selector [form]="form"></app-crew-selector>
      <ion-item>
        <ion-input
          appClearZero
          errorText="Project Hours is required"
          formControlName="projHours"
          inputmode="numeric"
          label="Project Hours"
          label-placement="floating"
          placeholder="Project Hours"
          type="number"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          appClearZero
          formControlName="misc"
          inputmode="numeric"
          label="Miscellaneous"
          label-placement="floating"
          placeholder="Miscellaneous"
          type="number"
        ></ion-input>
      </ion-item>
    </ion-list>
  </form>
}
