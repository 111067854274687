import {Injectable} from '@angular/core';
import {
  BlowInAreaOptions
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-options";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";

@Injectable({
  providedIn: 'root'
})
export class BlowInBlowProductTransformer extends AbstractTransformer {
  async execute(area: BlowInAreaOptions): Promise<BlowInAreaOptions> {
    const productID = area.blowInProduct as unknown;

    if (undefined === productID) return Promise.resolve(area);
    if (!productID) {
      area.blowInProduct = undefined;
      return Promise.resolve(area);
    }

    const newProducts = await this.getNewProducts(new BlowInInsulationCategoryName());
    const newProduct = newProducts.toArray().find(
      product => product.name.getValue().startsWith(productID.toString())
    )

    if (!newProduct) throw new Error(`Cannot find new product for old ID ${productID}`);

    area.blowInProduct = newProduct.id.getValue();

    return Promise.resolve(area);
  }

}
