import {Component, Input} from '@angular/core';
import {ConfirmDialogService} from "@modules/_shared/Service/ConfirmDialog/confirm-dialog.service";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {
  IsCurrentUserCorporateQuery
} from "@modules/product/product/Application/Service/is-current-user-corporate.service";
import {
  DeleteProductCommand
} from "@modules/product/product/Application/UseCase/Command/DeleteProduct/delete-product-command.service";
import {
  UserOwnsProductQuery
} from "@modules/product/product/Application/UseCase/Query/UserOwnsProduct/user-owns-product-query.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductLinkProvider} from "@modules/product/product/Infrastructure/Provider/product-link-provider";
import {UntilDestroy} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-product-list-display',
  templateUrl: './product-list-display.component.html',
  styleUrls: ['./product-list-display.component.scss'],
  standalone: false
})
export class ProductListDisplayComponent {
  @Input() products: Product[] = [];
  private isCurrentUserCorporate: boolean = this.isCurrentUserCorporateQuery.execute();

  constructor(
    private readonly userProductAccessControl: UserOwnsProductQuery,
    private readonly deleteProductCommand: DeleteProductCommand,
    private readonly loadingService: LoadingService,
    private readonly confirmDialog: ConfirmDialogService,
    private readonly isCurrentUserCorporateQuery: IsCurrentUserCorporateQuery
  ) {
  }

  async deleteItem(product: Product): Promise<void> {
    const title = `Are you sure you want to delete ${product.name.getValue()}?`;
    const confirmed = await this.confirmDialog.confirm(title, 'Delete Product', 'destructive');
    if (!confirmed) return;

    await this.loadingService.execute({
      executeCb: () => this.deleteProductCommand.execute(product),
      finallyCb: () => this.products = this.products.filter(p => !p.id.equals(product.id))
    })
  }

  userCanDeleteProduct(product: Product): boolean {
    return this.userProductAccessControl.execute(product);
  }

  getProductEditLink(product: Product): string[] | undefined {
    if (!this.isCurrentUserCorporate && !product.isEditable) return undefined;
    return ProductLinkProvider.getProductEditRoute(product);
  }
}
