import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationRepository} from "@modules/calculation/Domain/Calculation/Repository/calculation-repository";

@Injectable({
  providedIn: 'root'
})
export class GetAllCalculationsQuery implements UseCase<void, Promise<Calculation[]>> {

  constructor(
    private readonly calculationRepository: CalculationRepository,
  ) {
  }

  execute(): Promise<Calculation[]> {
    return this.calculationRepository.getAllCalculations();
  }
}
