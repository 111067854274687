<ion-content>
  <app-header [explicitBackUrl]="['/']" [title]="title"></app-header>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  @if (categories) {
    <ion-list data-cy="categories-list">
      @for (category of categories.sortByName().toArray(); track category.id.getValue(); let i = $index) {
        <ion-item [routerLink]="getCategoryLink(category)">
          <ion-note slot="start">{{ i + 1 }}</ion-note>
          <ion-label>
            <ion-text>{{ category.title }}</ion-text>
          </ion-label>
        </ion-item>
      }
    </ion-list>
  }
</ion-content>
