import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {
  RemovalAreaOptions
} from "@modules/calculation-impl/removal-calculator/Domain/CalculationArea/removal-area-options";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {RemovalArea} from "../../Domain/CalculationArea/removal-area";
import {RemovalAreaFactory} from "../../Domain/CalculationArea/removal-area-factory";

@Component({
    selector: 'app-removal-area',
    templateUrl: './removal-area.component.html',
    styleUrls: ['./removal-area.component.scss'],
    standalone: false
})
export class RemovalAreaComponent implements CalculatorAreaComponent<RemovalArea>, OnInit, OnDestroy {
  @Input() inputModel: RemovalArea | undefined;
  @Output() inputModelChange: EventEmitter<RemovalArea> = new EventEmitter<RemovalArea>();

  protected form: FormGroup;
  protected number: number = 0;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private removalAreaFactory: RemovalAreaFactory,
  ) {
    this.form = new FormGroup({
      sqft: new FormControl(),
      laborCrew: new FormControl([]),
      projHours: new FormControl(),
      misc: new FormControl(),
    });
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processChanges(values?: any | null) {
    if (!this.inputModel) return;

    const params: RemovalAreaOptions = {
      id: this.inputModel?.id || '',
      name: this.inputModel?.name || '',
      sqft: values.sqft,
      laborCrew: values.laborCrew.map((crewID: number) => new CrewID(crewID)),
      projHours: values.projHours,
      miscellaneous: values.misc,
      businessUnitID: this.inputModel.businessUnitID,
    };

    const inputModel = this.removalAreaFactory.execute(params);
    this.inputModelChange.emit(inputModel);
  }

  private initForm() {
    if (!this.inputModel) {
      throw new Error('inputModel is required');
    }

    this.form.patchValue({
      sqft: this.inputModel.sqft,
      laborCrew: this.inputModel.laborCrew.map((crewID) => crewID.getValue()),
      projHours: this.inputModel.projHours,
      misc: this.inputModel.miscellaneous,
    });


    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => this.processChanges(values));
  }
}
