import {Injectable} from '@angular/core';
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {
  CategoryDffFieldsProvider
} from "@modules/product/product/Presentation/Service/ProductToDffTransformer/category-dff-fields-provider.service";
import {
  ProductStaticFieldsDFFProvider
} from "@modules/product/product/Presentation/Service/ProductToDffTransformer/product-static-fields-d-f-f-provider.service";

@Injectable({
  providedIn: 'root'
})
export class CategoryToProductDFFTransformer {
  constructor(
    private readonly productStaticFieldsDFFProvider: ProductStaticFieldsDFFProvider,
    private readonly categoryDffFieldsProvider: CategoryDffFieldsProvider
  ) {
  }


  execute(category: Category): DynamicFormFields {
    const staticFields = this.productStaticFieldsDFFProvider.execute();
    const categoryFields = this.categoryDffFieldsProvider.execute(category);

    return new DynamicFormFields([
      ...staticFields.getFields(),
      ...categoryFields.getFields()
    ]);
  }
}
