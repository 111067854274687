import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {IonItemSliding} from "@ionic/angular";
import {ConfirmDialogService} from "@modules/_shared/Service/ConfirmDialog/confirm-dialog.service";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaLinkProvider
} from "@modules/calculation-impl/miscellaneous-calculator/Infrastructure/Provider/misc-area-link-provider.service";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculationLinkProvider} from "@modules/calculation/Infrastructure/Provider/calculation-link-provider.service";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MiscCalculationService} from '../../Service/misc-calculation.service'; // Import the new service

@UntilDestroy()
@Component({
  selector: 'app-misc-calculation-area',
  templateUrl: './misc-calculation.component.html',
  styleUrls: ['./misc-calculation.component.scss'],
  standalone: false
})
export class MiscCalculationComponent {
  @Input({required: true}) calculationID?: string;

  calculation?: Calculation;
  miscArea?: MiscArea;
  loading = true;
  error?: string;
  rightButton: RightButton = {
    title: 'New Item',
    callback: () => this.createNewMiscItem(),
  };
  protected title = 'Miscellaneous';
  protected titleInfo = 'You can swipe to delete an item.';

  constructor(
    private readonly miscCalculationService: MiscCalculationService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly confirmDialogService: ConfirmDialogService
  ) {
  }

  async ionViewDidEnter(): Promise<void> {
    this.subscribeToLoading();
    if (!this.calculationID) throw new Error('Calculator ID is required');
    const calculationID = new CalculationID(this.calculationID);

    await this.loadData(calculationID);
  }

  async deleteItem(i: number, slidingItem: IonItemSliding): Promise<void> {
    if (!this.miscArea) throw new Error('Misc Area is required');

    const confirmed = await this.confirmDialogService.confirm('Are you sure you want to delete this item?');
    if (!confirmed) return;

    this.miscArea = this.miscArea.removeItem(i);
    await this.updateArea(this.miscArea, slidingItem);
  }

  getUpdateMiscItemLink(itemID: number): string[] {
    return this.miscArea && this.calculation
      ? MiscAreaLinkProvider.getUpdateMiscItemLink(this.calculation.id, this.miscArea.id, itemID.toString())
      : [];
  }

  protected getBackUrl(): string[] {
    return this.calculation && this.calculationID
      ? CalculationLinkProvider.getCalculationsListRouterLink(this.calculation.estimateID)
      : [];
  }

  private subscribeToLoading() {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);
  }

  private async loadData(calculationID: CalculationID) {
    await this.loadingService.execute({
      executeCb: async () => {
        const [area, calculation] = await Promise.all([
          this.miscCalculationService.loadArea(calculationID),
          this.miscCalculationService.loadCalculation(calculationID),
        ])
        this.miscArea = area;
        this.calculation = calculation;
      }
    });
  }

  private async updateArea(area: MiscArea, slidingItem: IonItemSliding) {
    await this.loadingService.execute({
      executeCb: () => this.miscCalculationService.updateArea(area),
      finallyCb: () => slidingItem.close(),
    });
  }

  private createNewMiscItem() {
    if (this.calculation && this.miscArea) {
      const url = MiscAreaLinkProvider.getCreateNewMiscItemLink(this.calculation.id, this.miscArea.id);
      this.router.navigate(url);
    }
  }
}
