import {Injectable} from '@angular/core';
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {DynamicFormFieldNumber} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-number";
import {DynamicFormFieldString} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-string";
import {PRODUCT_PRICE_FIELD} from "@modules/product/product/Domain/Product/product";


/**
 * This service provides product's static fields as Dynamic Form Fields
 *
 * @see Product
 */
@Injectable({
  providedIn: 'root'
})
export class ProductStaticFieldsDFFProvider {

  constructor() {
  }

  execute(): DynamicFormFields {
    return new DynamicFormFields([
      new DynamicFormFieldString({
        key: 'name',
        value: undefined,
        required: true,
        label: 'Product Name',
        placeholder: 'Product Name',
        errorMessage: 'Product Name is required'
      }),
      new DynamicFormFieldString({
        key: 'description',
        value: undefined,
        required: false,
        label: 'Product Description',
        placeholder: 'Product Description',
        errorMessage: 'Product Description is required'
      }),
      new DynamicFormFieldString({
        key: 'manufacturer',
        value: undefined,
        required: true,
        label: 'Product Manufacturer',
        placeholder: 'Product Manufacturer',
        errorMessage: 'Product Manufacturer is required'
      }),
      new DynamicFormFieldNumber({
        key: PRODUCT_PRICE_FIELD,
        value: undefined,
        required: true,
        label: 'Product Price',
        placeholder: 'Product Price',
        errorMessage: 'Product Price is required'
      }),
    ]);
  }
}
