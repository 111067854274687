<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Select Roof Pitch</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="apply()" strong="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="form">
    @if (roofPitches) {
      <ion-item>
        <ion-select
          formControlName="roofPitch"
          label="Select Roof Pitch"
          label-placement="floating"
          placeholder="Roof Pitch"
        >
          @for (pitch of roofPitches.pitches; track pitch) {
            <ion-select-option [value]="pitch.name.getValue()">
              {{ pitch.name.getValue() }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
    }

    @if (calculationMethods) {
      <ion-item>
        <ion-select
          formControlName="calculationMethod"
          label="Select Calculation Method"
          label-placement="floating"
          placeholder="Calculation Method">
          @for (method of calculationMethods.methods; track method) {
            <ion-select-option [value]="method.name.getValue()">
              {{ method.name.getValue() }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
    }

    @if (finalResult && inputModel) {
      <ion-item>
        <ion-text>
          <span>{{ inputModel.getRawSqft() }} sqft</span> * <span>{{ selectedRoofPitchFactor }}</span> =
          <b>{{ finalResult | number: '1.0-2' }} sqft total</b>
        </ion-text>
      </ion-item>
    }

    @if (!finalResult && !form.untouched) {
      <ion-item>
        <ion-note>Please choose the Roof Pitch and Calculation Method</ion-note>
      </ion-item>
    }
  </form>
</ion-content>
