import {Injectable} from "@angular/core";
import {WorkOrderPdfGenerator} from "@modules/work-order-pdf/Domain/Domain/Service/work-order-pdf-generator";
import {WorkOrder, WorkOrderCalculation} from "@modules/work-order-pdf/Domain/Domain/work-order";
import {AutoTableWithHeader} from "@modules/work-order-pdf/Infrastructure/Service/auto-table-with-header.service";
import jsPDF from "jspdf";
import autoTable, {CellInput, Color, RowInput} from "jspdf-autotable";

@Injectable({'providedIn': 'root'})
export class WorkOrderJsPdfGeneratorService extends WorkOrderPdfGenerator {
  private readonly labourHoursColor: Color = [230, 230, 230];

  constructor(private readonly autoTableWithHeader: AutoTableWithHeader) {
    super();
  }

  async generatePdf(order: WorkOrder): Promise<Blob> {
    // noinspection JSPotentiallyInvalidConstructorUsage
    const doc = new jsPDF();
    await this.addLogo(doc);
    this.createHeader(doc, order);
    this.autoTableWithHeader.addText(doc, 'Job Details', undefined, [0, 174, 239], 1);
    this.createJobDetails(doc, order);
    this.createMiscellaneousList(doc, order);
    this.createTotalJobHours(doc, order);

    return doc.output('blob');
  }

  private createHeader(doc: jsPDF, order: WorkOrder): void {
    const pageWidth = doc.internal.pageSize.getWidth();
    const tableWidth = 120;
    const margin = (pageWidth - tableWidth) / 2;
    autoTable(doc, {
      body: [
        ['Job Number', order.jobID.toString()],
        ['Owner', `${order.owner}`],
        ['Customer Name', order.customerName],
        ['Customer Address', order.customerAddress]
      ],
      tableWidth: tableWidth,
      startY: 5,
      margin: {left: margin, right: margin},
      theme: 'grid',
      bodyStyles: {
        textColor: [0, 0, 0]
      },
      columnStyles: {
        0: {cellWidth: 40},
        1: {cellWidth: 'auto'}
      },
    });
  }

  private createJobDetails(doc: jsPDF, order: WorkOrder): void {
    let firstTableAdded = false;
    order.calculations.forEach(calculation => {
      const headerText = calculation.calculationName;
      const tableColumns = ['Area', ...calculation.columns];
      const columnsData = this.createJobCalculationDetailsTableRows(calculation);

      if (firstTableAdded) {
        this.autoTableWithHeader.addAutoTable(doc, headerText, tableColumns, columnsData);
      } else {
        this.autoTableWithHeader.addAutoTable(doc, headerText, tableColumns, columnsData, 15);
        firstTableAdded = true;
      }
    });
  }


  private createMiscellaneousList(doc: jsPDF, order: WorkOrder): void {
    const headerText = 'Miscellaneous';
    const tableColumns: string[] = ['Name', 'Quantity'];
    const columnsData: RowInput[] = order.miscItems.map(item => [
      {
        content: item.name,
      },
      {
        content: item.quantity
      }
    ]);
    this.autoTableWithHeader.addAutoTable(doc, headerText, tableColumns, columnsData);
  }

  private createJobCalculationDetailsTableRows(calculation: WorkOrderCalculation): RowInput[] {
    const rows: RowInput[] = [];
    calculation.areas.forEach(area => {
      const fields: CellInput[] = [];
      fields.push(area.areaName);
      area.fields.forEach(field => {
        if (typeof field === 'number') {
          field = Math.round(field * 100) / 100;
        }
        fields.push(field as CellInput)
      });
      rows.push(fields);
    });

    const crewNames: string[] = Array.from(new Set(
      calculation.areas
        .map(area => area.crewNames)
        .flat()
        .filter(n => n)
    ));

    rows.push([
      {
        content: 'Labor Hours',
        styles: {
          fontStyle: 'bold',
          fillColor: this.labourHoursColor,
        }
      },
      {
        content: calculation.labourTotal + ' (' + crewNames.join(',') + ')',
        styles: {
          fontStyle: 'bold',
          fillColor: this.labourHoursColor,
        },
        colSpan: calculation.columns.length
      }
    ]);

    return rows;
  }

  private async loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (error) => {
        console.error(`Failed to load image from URL: ${url}`, error);
        reject(new Error('Failed to load image'));
      };
    });
  }

  private async addLogo(doc: jsPDF) {
    const maxWidth = doc.internal.pageSize.getWidth();
    const imageUrl = '../../../assets/ifoam_logo_black.png';
    const image = await this.loadImage(imageUrl);

    const imageWidth = 25;
    const imageHeight = imageWidth / image.width * image.height;

    doc.addImage(
      image,
      'PNG',
      maxWidth - imageWidth - 15,
      30,
      imageWidth,
      imageHeight
    );
  }

  private createTotalJobHours(doc: jsPDF, order: WorkOrder) {
    const text = `Total Job Hours: ${order.totalJobHours}`;
    this.autoTableWithHeader.addText(doc, text, undefined, this.labourHoursColor, 0);
  }
}
