@if (menuEntries?.length) {
  <div>
    @if (!loading) {
      <ion-button (click)="openMenu()" [id]="buttonID">
        <ion-icon
          name="ellipsis-vertical-outline"
          slot="icon-only"
        ></ion-icon>
      </ion-button>
    }
    @if (loading) {
      <ion-button [id]="buttonID">
        <ion-spinner name="crescent"></ion-spinner>
      </ion-button>
    }
  </div>
}
