import {Component, Input} from "@angular/core";
import {TotalComponent} from "@modules/calculation-total/Domain/CalculatorTotalTabsComponent/total-component";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";


@Component({
    selector: 'app-blow-in-total',
    templateUrl: './blow-in-total.component.html',
    styleUrls: ['./blow-in-total.component.scss'],
    standalone: false
})

export class BlowInTotalComponent implements TotalComponent {
  @Input() totalModel: CalculationTotal | undefined;
  title: string = 'Blow In Total';
}
