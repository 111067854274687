import {
  ProductFactoryStrategy
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {
  BattingInsulationProductCreationStrategy
} from "@modules/product/products/batting-insulation-product/Application/batting-insulation-product-creation-strategy.service";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";
import {
  BattingMineralWoolProductCreationStrategy
} from "@modules/product/products/batting-mineral-wool-product/Application/batting-mineral-wool-product-creation-strategy.service";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";
import {
  BlowInBlowProductCreationStrategy
} from "@modules/product/products/blow-in-blow-product/Application/blow-in-blow-product-creation-strategy.service";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";
import {
  BlowInNetProductCreationStrategy
} from "@modules/product/products/blow-in-net-product/Application/blow-in-net-product-creation-strategy.service";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";
import {
  FoamProductCreationStrategy
} from "@modules/product/products/foam-product/Application/foam-product-creation-strategy.service";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {
  MiscProductCreationStrategy
} from "@modules/product/products/misc-product/Application/misc-product-creation-strategy.service";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";

export const availableStrategies: ProductFactoryStrategy[] = [
  {
    category: new FoamCategoryName(),
    strategy: new FoamProductCreationStrategy(),
  },
  {
    category: new BattingInsulationCategoryName(),
    strategy: new BattingInsulationProductCreationStrategy(),
  },
  {
    category: new BattingMineralWoolCategoryName(),
    strategy: new BattingMineralWoolProductCreationStrategy(),
  },
  {
    category: new BlowInInsulationCategoryName(),
    strategy: new BlowInBlowProductCreationStrategy(),
  },
  {
    category: new BlowInNetCategoryName(),
    strategy: new BlowInNetProductCreationStrategy(),
  },
  {
    category: new MiscCategoryName(),
    strategy: new MiscProductCreationStrategy(),
  }
]
