import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  UserOwnsProductQuery
} from "@modules/product/product/Application/UseCase/Query/UserOwnsProduct/user-owns-product-query.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";

@Injectable({
  providedIn: 'root'
})
export class DeleteProductCommand implements UseCase<Product, Promise<void>> {
  constructor(
    private readonly userOwnsProductQuery: UserOwnsProductQuery,
    private readonly productRepository: ProductRepository,
  ) {
  }

  execute(product: Product): Promise<void> {
    if (!this.userOwnsProductQuery.execute(product)) {
      throw new Error('User does not own this product');
    }

    return this.productRepository.delete(product.id);
  }
}
