import {Injectable} from '@angular/core';
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {
  BlowInBlowProductType
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Application/blow-in-blow-product-type";
import {
  BlowInBlowProduct
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/blow-in-blow-product";
import {
  BlowInBlowProducts
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/blow-in-blow-products";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  ProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/product-provider.service";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";

@Injectable({
  providedIn: 'root'
})
export class BlowInBlowProductProvider extends ProductProvider<BlowInBlowProducts, BlowInBlowProduct> {
  protected getFields(oldProduct: BlowInBlowProduct): Fields {
    return new Fields([
      new FieldDropdown(oldProduct.blowInType, new FieldName('blowInType')),
      new FieldDropdown(oldProduct.areaType, new FieldName('areaType')),
      new FieldNumber(oldProduct.bagsPerFullStack, new FieldName('bagsPerFullStack')),
      new FieldNumber(oldProduct.rValue, new FieldName('rValue')),
      new FieldNumber(oldProduct.maxNetCoveragePerBagSqFt, new FieldName('maxNetCoveragePerBagSqFt')),
    ]);
  }

  protected getManufacturer(oldProduct: BlowInBlowProduct): string {
    return oldProduct.name.split(' ')[0];
  }

  protected getProductType(): ProductType {
    return BlowInBlowProductType;
  }

  protected override getCategoryName(): CategoryName {
    return new BlowInInsulationCategoryName();
  }
}
