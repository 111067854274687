import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Notifications} from "@modules/area-context-notifications/Domain/Notification/notifications";
import {
  NotificationRepository
} from "@modules/area-context-notifications/Domain/Notification/Repository/notification-repository";

@Injectable({
  providedIn: 'root'
})
export class GetNotificationsQuery implements UseCase<void, Promise<Notifications>> {

  constructor(
    private readonly notificationRepository: NotificationRepository
  ) {
  }

  execute(): Promise<Notifications> {
    return this.notificationRepository.getNotifications();
  }
}
