import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductName} from "@modules/product/product/Domain/Product/VO/product-name";

export class Products<T extends Product = Product> {
  constructor(protected readonly products: T[]) {
    const ids = this.products.map(product => product.id.getValue());
    if (new Set(ids).size !== ids.length) {
      throw new Error("Product ID must be unique");
    }
    
    const originalProductIds = this.products
      .filter(p => p.originalProduct)
      .map(p => p.originalProduct?.id.getValue())

    this.products = this.products.filter(p => !originalProductIds.includes(p.id.getValue()))
  }

  getByID(id: ProductID): T | undefined {
    return this.products.find(product => product.id.equals(id));
  }

  getByName(name: ProductName): T | undefined {
    return this.products.find(product => product.isMatchesName(name));
  }

  filterBySearchTerm(searchTerm: string): Products<T> {
    return new Products<T>(
      this.products.filter(product => product.isMatchesSearchTerm(searchTerm))
    )
  }

  sortByName(): Products<T> {
    const sortedProducts = [...this.products].sort(
      (a, b) => a.name.getValue().localeCompare(b.name.getValue())
    );
    return new Products<T>(sortedProducts);
  }

  toArray(): T[] {
    return this.products;
  }

  updateProducts(products: T[]): Products<T> {
    const newProducts = [...this.products.map(
      product => products.find(p => p.id.equals(product.id)) || product
    )];
    return new Products<T>(newProducts);
  }

  isEmpty(): boolean {
    return this.products.length === 0;
  }
}
