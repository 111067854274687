import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

export const TOTAL_CALCULATION_STRATEGY_ID = new CalculationStrategyID('total');

export class EstimateSummaryTab {
  constructor(
    public readonly name: string,
    public readonly calculationID: CalculationID,
    public readonly icon: string = '',
  ) {
  }
}
