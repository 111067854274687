import {ConstructionType} from "@modules/calculation-impl/batting/construction-type/construction-type";
import {BattingConstruction} from "@modules/calculation-impl/batting/construction/Domain/batting-construction";
import {Facing} from "@modules/calculation-impl/batting/facing/Domain/facing";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {
  BattingInsulationProduct
} from "@modules/product/old-products/Configurations/batting-insulation-product/Domain/batting-insulation-product";
import {Products} from "@modules/product/old-products/Domain/Product/products";

export class BattingInsulationProducts extends Products<BattingInsulationProduct> {

  getOnCenterItems(): OnCenter[] {
    const onCenters = this.products.map(product => product.onCenter);
    return onCenters
      .filter((onCenter, index, self) =>
          index === self.findIndex((t) => (
            t.equals(onCenter)
          ))
      )
      .sort((a, b) => a.greaterThan(b));
  }

  filterProductsByConstructionType(constructionType: ConstructionType): BattingInsulationProducts {
    const products = this.products.filter(product => product.isApplicableForConstructionType(constructionType));
    return new BattingInsulationProducts(products);
  }

  filterProductsByOnCenter(onCenter: OnCenter): BattingInsulationProducts {
    const products = this.products.filter(product => product.onCenter.equals(onCenter));
    return new BattingInsulationProducts(products);
  }

  filterProductsByRValue(rValue: RValue): BattingInsulationProducts {
    const products = this.products.filter(product => product.rValue.equals(rValue));
    return new BattingInsulationProducts(products);
  }

  filterProductsByFacing(facing: Facing): BattingInsulationProducts {
    const products = this.products.filter(product => product.facing.equals(facing));
    return new BattingInsulationProducts(products);
  }

  getRValueItems(construction?: BattingConstruction): RValue[] {
    const rvalues = this.products.map(product => product.rValue);
    const uniqueRvalues = rvalues.filter((rvalue, index, self) =>
        index === self.findIndex((t) => (
          t.equals(rvalue)
        ))
    );

    uniqueRvalues.sort((a, b) => {
      return a.value.localeCompare(b.value, undefined, {numeric: true, sensitivity: 'base'});
    });

    if (!construction) {
      return uniqueRvalues;
    }

    return uniqueRvalues.filter(rvalue => rvalue.isApplicableToConstruction(construction));
  }
}
