import {MiscProduct} from "@modules/product/products/misc-product/Domain/Product/misc-product";

export class MiscAreaItem {
  protected _type!: void;

  constructor(
    public readonly name: string,
    private readonly value: number,
    public readonly count: number
  ) {
  }

  getTotal(): number {
    return this.value * this.count
  }

  getRawValue(): number {
    return this.value
  }
}

export class ProductMiscAreaItem extends MiscAreaItem {
  protected override _type!: void;

  constructor(
    public readonly product: MiscProduct,
    public override readonly count: number
  ) {
    super(product.name.getValue(), product.price.getValue(), count);
  }
}
