import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {IonViewDidEnter} from "@modules/_shared/Interface/ion-view-did-enter";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscItemCreateUpdateService
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Service/misc-item-create-update-service";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-create-misc-item',
  templateUrl: './create-misc-item.component.html',
  styleUrls: ['./create-misc-item.component.scss'],
  standalone: false
})
export class CreateMiscItemComponent implements IonViewDidEnter {
  @Input({required: true}) calculationID?: string;
  @Input({required: true}) miscAreaID?: string;

  rightButton: RightButton = {title: 'Save', callback: () => this.save()};
  title = 'Create';
  form?: FormGroup;
  miscArea?: MiscArea;
  protected loading: boolean = false;

  constructor(
    protected readonly miscItemService: MiscItemCreateUpdateService,
    protected readonly loadingService: LoadingService,
    protected readonly router: Router
  ) {
  }

  getBackUrl(): string[] {
    if (!this.calculationID) return [];
    const calculationID = new CalculationID(this.calculationID);
    return this.miscItemService.getMiscAreaLink(calculationID);
  }

  async ionViewDidEnter(): Promise<void> {
    if (!this.miscAreaID) throw new Error('Misc Area ID is required');
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);

    const areaID = this.miscAreaID;

    await this.loadingService.execute({
      executeCb: () => this.loadArea(areaID),
      finallyCb: () => this.createForm()
    });
  }

  createForm() {
    this.form = this.miscItemService.createEmptyFormGroup();
  }

  async process(form: FormGroup, miscArea: MiscArea): Promise<void> {
    const areaItem = await this.miscItemService.mapToDomain(form);
    const area = miscArea.addItem(areaItem);

    return this.miscItemService.updateCalculationArea(area);
  }

  async save(): Promise<void> {
    if (!this.form) throw new Error('Form is required');
    if (!this.miscArea) throw new Error('Misc Area is required');

    const form = this.form;
    const miscArea = this.miscArea;
    await this.loadingService.execute({
      executeCb: () => this.process(form, miscArea),
      finallyCb: () => this.router.navigate(this.getBackUrl())
    });
  }

  protected async loadArea(areaID: string) {
    this.miscArea = await this.miscItemService.getCalculationArea(areaID);
  }
}
