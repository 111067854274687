import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";

export class CategoryNotFoundException extends Error {
  constructor(name: CategoryName | CategoryID, originalError?: unknown) {
    let message: string = `Category ${name} not found`;
    if (originalError && (originalError instanceof Error)) {
      message += `: ${originalError.message}`;
    }

    super(message);
    this.name = 'CategoryNotFoundException';
  }
}
