import {Injectable} from "@angular/core";
import {MapperAsync} from "@modules/_shared/Domain/mapper";
import {
  MiscAreaFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-factory";
import {
  MiscAreaItemOptions
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-options";
import {
  MiscAreaTotal
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total";
import {
  MiscAreaTotalFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationTotal/misc-area-total-factory";
import {
  GetCalculationStrategyByIdQueryService
} from "@modules/calculation-strategy/Application/UseCase/Query/get-calculation-strategy-by-id-query.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";
import {EstimateArea} from "@modules/estimate-calculation-summary/Domain/EstimateArea/estimate-area";
import {EstimateAreaOptions} from "@modules/estimate-calculation-summary/Domain/EstimateArea/estimate-area-options";
import {
  EstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Domain/EstimateCalculationSummary/estimate-calculation-summary";
import {
  DynamicsEstimateCalculationSummary
} from "@modules/estimate-calculation-summary/Infrastructure/Repository/EstimateCalculationSummary/Model/dynamics-estimate-calculation-summary";

@Injectable({providedIn: 'root'})
export class DynamicsEstimateCalculationSummaryMapper implements MapperAsync<EstimateCalculationSummary, DynamicsEstimateCalculationSummary> {

  constructor(
    private readonly getCalculationStrategyByIdQueryService: GetCalculationStrategyByIdQueryService,
    private readonly miscAreaFactory: MiscAreaFactory
  ) {
  }

  async mapTo(param: DynamicsEstimateCalculationSummary): Promise<EstimateCalculationSummary> {
    if (typeof param.cr9b4_Calculation === 'undefined' || param.cr9b4_Calculation === null) {
      throw new Error('Missing required field: cr9b4_Calculation');
    }

    const calculation = param.cr9b4_Calculation;
    if (typeof calculation !== 'object' || calculation === null) {
      throw new Error('Invalid data type for cr9b4_Calculation');
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const strategyName = (calculation as any).cr9b4_calculationtype as string;
    if (typeof strategyName === 'undefined') {
      throw new Error('Missing required field: cr9b4_calculationtype');
    }

    const strategyID = new CalculationStrategyID(strategyName);
    const strategy = await this.getCalculationStrategyByIdQueryService.execute(strategyID);
    return new EstimateCalculationSummary({
      calculationID: param._cr9b4_calculation_value as string,
      totalCost: Number(param.cr9b4_totalcost),
      areas: await this.decodeAreas(param.cr9b4_areas as string, strategy.areaTotalFactory)
    });
  }

  mapFrom(param: EstimateCalculationSummary): Promise<DynamicsEstimateCalculationSummary> {
    const summary = {
      cr9b4_ifoamicalcestimatecalculationsumamryid: undefined,
      cr9b4_Calculation: undefined,
      _cr9b4_calculation_value: param.calculationID,
      cr9b4_totalcost: param.totalCost,
      cr9b4_areas: this.encodeAreas(param.areas),
    };

    return Promise.resolve(summary);
  }

  private encodeAreas(areas: EstimateArea[]): string {
    return JSON.stringify(areas);
  }

  private async decodeAreas(data: string, factory: CalculationAreaTotalFactory): Promise<EstimateArea[]> {
    const parsedAreas = JSON.parse(data) as Array<Partial<EstimateArea>>;

    return Promise.all(parsedAreas.map((area) => {
      return this.createArea(area, factory);
    }));
  }

  private async createArea(area: Partial<EstimateArea>, factory: CalculationAreaTotalFactory): Promise<EstimateArea> {
    if (typeof area !== 'object') throw new Error('Invalid data type');
    if (area === null) throw new Error('Invalid data type');

    if (area.id === undefined) throw new Error('Missing required field: id');
    if (area.areaName === undefined) throw new Error('Missing required field: areaName');
    if (area.areaTotal === undefined) throw new Error('Missing required field: areaTotal');

    let areaTotal = area.areaTotal;

    // for misc areas we have to unpack and create the items, because total logic is realtime
    if (factory instanceof MiscAreaTotalFactory) {
      const miscAreaItemsOptions = (area.areaTotal as MiscAreaTotal).misc as unknown as MiscAreaItemOptions[];
      const miscAreaItems = await this.miscAreaFactory.createItems(miscAreaItemsOptions);
      areaTotal = new MiscAreaTotal(miscAreaItems);
    }

    const params: EstimateAreaOptions = {
      id: area.id as string,
      areaName: area.areaName as string,
      areaTotal: factory.execute(areaTotal),
      crewNames: area.crewNames as string[],
    };

    return new EstimateArea(params);
  }
}
