import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {GetCurrentGmInputs} from "@modules/gm-inputs/Application/UseCase/Query/get-current-gm-inputs.service";
import {Crew} from "@modules/gm-inputs/Domain/Crew/crew";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";

@Component({
  selector: 'app-crew-selector',
  templateUrl: './crew-selector.component.html',
  styleUrls: ['./crew-selector.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule
  ],
  standalone: true
})
export class CrewSelectorComponent implements OnInit {
  @Input({required: true}) public form: FormGroup = new FormGroup({});

  protected loading = false;
  protected gmInputs: GmInputs | undefined;

  constructor(
    private readonly getCurrentUserGmInputs: GetCurrentGmInputs,
    private readonly notificationService: NotificationService,
  ) {
  }


  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      this.gmInputs = await this.getCurrentUserGmInputs.execute();
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }

  getInactiveCrewInForm(): Crew[] {
    if (!this.gmInputs) {
      return [];
    }

    const formIDs: number[] | undefined = this.form.value.laborCrew;
    const crewIDs = formIDs ? formIDs.map(id => new CrewID(id)) : [];
    return this.gmInputs.getCrews().getInactiveCrewsByIDs(crewIDs);
  }

  getCrewsToDisplay() {
    if (!this.gmInputs) {
      return [];
    }

    return [
      ...this.gmInputs.getCrews().getActiveCrews(),
      ...this.getInactiveCrewInForm()
    ]
  }
}
