import {Injectable} from '@angular/core';
import {Product} from "@modules/product/product/Domain/Product/product";

@Injectable({
  providedIn: 'root'
})
export class DynamicsUpdateProductPriceMapperService {
  execute(product: Product): Promise<{ cr9b4_price: number }> {
    const priceFields = {
      cr9b4_price: product.price.getValue(),
    }
    return Promise.resolve(priceFields);
  }


}
