import {Inject, Injectable} from "@angular/core";
import {CacheResolverService} from "@modules/_shared/Service/CacheResolver/cache-resolver.service";
import {DYNAMICS_TTL_TOKEN} from "@modules/microsoft/microsoft-dynamics/Application/Type/dynamics-ttl";
import {DynamicsWebApi, RetrieveRequest} from "dynamics-web-api";

@Injectable({'providedIn': 'root'})
export class DynamicsCountQuery {
  constructor(
    private readonly dynamicsWebApi: DynamicsWebApi,
    private readonly cacheResolverService: CacheResolverService,
    @Inject(DYNAMICS_TTL_TOKEN) private readonly ttl: number
  ) {
  }
  
  async execute(request: RetrieveRequest): Promise<number> {
    const key = JSON.stringify(request);
    const fn = async () => await this.dynamicsWebApi.countAll(request);
    return this.cacheResolverService.getOrFetch(key, fn, this.ttl);
  }
}
