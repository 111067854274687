import {CalculatorTab} from "@modules/calculation-tabs/Domain/CalculatorTab/calculator-tab";
import {EstimateService} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/estimate-service";
import {ServiceMatcher} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/service-matcher";
import {
  EstimateServiceCode
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-code";
import {
  EstimateServiceID
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-id";

export class EstimateServices {
  private readonly services: EstimateService[] = [];

  constructor(
    services: EstimateService[],
    private readonly matchers: ServiceMatcher[]
  ) {
    this.services = [...services];
  }

  findMatchingService(area: CalculatorTab): EstimateService[] {
    const state = area.state as unknown;
    if (typeof state !== 'object') {
      return [];
    }
    if (Object.keys(<NonNullable<unknown>>state).length === 0) {
      return [];
    }

    const serviceName = this.getServiceCodeFromAreaState(state as object);
    if (!serviceName) return [];

    const foundServices = [];
    for (const name of serviceName) {
      const services = this.services.find(service => service.code.equals(name));
      if (services) {
        foundServices.push(services);
      }
    }
    return foundServices;
  }

  add(serviceToAdd: EstimateService): void {
    if (this.services.some(service => service.equals(serviceToAdd))) {
      return;
    }
    this.services.push(serviceToAdd);
  }

  filterByAreas(tabs: CalculatorTab[]): EstimateServices {
    const newServices: EstimateServices = new EstimateServices([], this.matchers);
    tabs.forEach(area => {
      const services = this.findMatchingService(area);
      if (services) {
        services.forEach(service => {
          newServices.add(service);
        });
      }
    });
    return newServices;
  }

  getServices(): EstimateService[] {
    return [...this.services];
  }

  getServicesSorted(): EstimateService[] {
    return this.services.sort(
      (a, b) => a.displayName.getValue().localeCompare(b.displayName.getValue())
    );
  }

  isEmpty() {
    return this.services.length === 0;
  }

  findByID(id: EstimateServiceID): EstimateService | undefined {
    return this.services.find(service => service.id.equals(id));
  }

  private getServiceCodeFromAreaState(state: object): EstimateServiceCode[] {
    const serviceCodes: EstimateServiceCode[] = [];
    for (const matcher of this.matchers) {
      const key = matcher.key as keyof typeof state;
      if (state[key] === matcher.value) {
        serviceCodes.push(matcher.code);
      }
    }

    return serviceCodes;
  }
}
