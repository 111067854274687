<ion-list lines="none">
  <app-width-length [form]="form"></app-width-length>
  @if (form.get('sqft')?.disabled) {
    <ion-item>
      <ion-note>Please disable roof pitch mode to enter sqft</ion-note>
    </ion-item>
  }

  @if (displayPitchSelector) {
    <ion-item>
      <ion-toggle
        (ionChange)="roofPitchChanged($event)"
        [(ngModel)]="isPitchFactorEnabled"
      >
        Apply Pitch Factor
      </ion-toggle>
    </ion-item>
  }


  <form [formGroup]="form">
    <ion-item>
      <ion-select
        formControlName="service"
        label="Select Service"
        label-placement="floating"
        placeholder="Select Service"
      >
        @for (service of services; track service) {
          <ion-select-option [value]="service.value">
            {{ service.value }}
          </ion-select-option>
        }
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-select
        formControlName="depth"
        label="Select Depth"
        label-placement="floating"
        placeholder="Select Depth"
      >
        @for (item of availableDepths; track item) {
          <ion-select-option [value]="item.value">
            {{ item.value }}
          </ion-select-option>
        }
      </ion-select>
      <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
    </ion-item>

    <ion-item>
      <ion-select
        formControlName="product"
        label="Select Product"
        label-placement="floating"
        placeholder="Select Product"
      >
        @for (product of filteredProducts; track product.id.toString()) {
          <ion-select-option [value]="product.id.toString()">
            {{ product.name }}
          </ion-select-option>
        }
      </ion-select>
    </ion-item>
    @if (filteredProducts.length === 0) {
      <ion-item>
        <ion-note color="danger">No product exists in the database for this scenario</ion-note>
      </ion-item>
    }

    <app-crew-selector [form]="form"></app-crew-selector>

    <ion-item>
      <ion-input
        appClearZero
        errorText="Project Job Hours is required"
        formControlName="projHours"
        inputmode="numeric"
        label="Project Job Hours"
        label-placement="floating"
        placeholder="Project Job Hours"
        type="number"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-input
        appClearZero
        errorText="Miscellaneous is required"
        formControlName="miscellaneous"
        inputmode="numeric"
        label="Miscellaneous"
        label-placement="floating"
        placeholder="Miscellaneous"
        type="number"
      ></ion-input>
    </ion-item>
  </form>
</ion-list>


