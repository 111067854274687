<div [formGroup]="form">
  <ion-item>
    <ion-select
      formControlName="battingServiceName"
      label="Select Batting Service"
      label-placement="floating"
      placeholder="Select Batting Service"
    >
      @for (item of battingServices; track item) {
        <ion-select-option [value]="item">
          {{ item }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>

  @if (serviceSelected === BattingServiceEnum.BattInsulation) {
    <ion-item>
      <ion-select
        formControlName="constructionType"
        label="Select Construction Type"
        label-placement="floating"
        placeholder="Select Construction Type"
      >
        @for (option of constructionTypes; track option) {
          <ion-select-option [value]="option">
            {{ option }}
          </ion-select-option>
        }
      </ion-select>
    </ion-item>
  }

  <ion-item>
    <ion-select
      formControlName="onCenter"
      label="Select On Center"
      label-placement="floating"
      placeholder="Select On Center"
    >
      @for (item of onCenterItems; track item) {
        <ion-select-option [value]="item.value">
          {{ item.value ?? 'N/A' }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>

  @if (serviceSelected === BattingServiceEnum.BattInsulation) {
    <div>
      <ion-item>
        <ion-select
          formControlName="constructionName"
          label="Select Construction"
          label-placement="floating"
          placeholder="Select construction"
        >
          @for (item of constructions; track item) {
            <ion-select-option [value]="item.value">
              {{ item.value }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-select
          formControlName="facingName"
          label="Select Facing"
          label-placement="floating"
          placeholder="Select Facing"
        >
          @for (item of facings; track item) {
            <ion-select-option [value]="item.value">
              {{ item.value }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
    </div>
  }

  @if (serviceSelected === BattingServiceEnum.MineralWool) {
    <ion-item>
      <ion-segment
        color="primary"
        formControlName="filterType"
      >
        @for (option of filterTypes; track option) {
          <ion-segment-button [value]="option">
            <ion-label>Filter products by {{ option }}</ion-label>
          </ion-segment-button>
        }
      </ion-segment>
    </ion-item>
  }

  @if (displayDepthFilter()) {
    <ion-item>
      <ion-select
        formControlName="depth"
        label="Select Depth"
        label-placement="floating"
        placeholder="Select Depth"
      >
        @for (item of filteredWoolDepths; track item) {
          <ion-select-option [value]="item">
            {{ item }}
          </ion-select-option>
        }
      </ion-select>
    </ion-item>
  } @else {
    <ion-item>
      <ion-select
        formControlName="rValue"
        label="Select R-Value"
        label-placement="floating"
        placeholder="Select R-Value"
      >
        @for (item of filteredRValueItems; track item) {
          <ion-select-option [value]="item.value">
            {{ item.value }}
          </ion-select-option>
        }
      </ion-select>
      <ion-icon (click)="openEnergyRecommendationModal()" class="energy-star-recommendation-icon" slot="end"></ion-icon>
    </ion-item>
  }


  <ion-item>
    <ion-select
      formControlName="productID"
      label="Select Product"
      label-placement="floating"
      placeholder="Select Product"
    >
      @for (product of getFilteredProducts(); track product.id.getValue()) {
        <ion-select-option [value]="product.id.getValue()">
          {{ product.name }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>
  @if (form.get('productID')?.hasError('required')) {
    <ion-item>
      <ion-note color="danger">No product exists in the database for this scenario</ion-note>
    </ion-item>
  }
</div>
