@if (loading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
} @else {
  @if (gmInputs) {
    <div [formGroup]="form">
      <ion-item>
        <ion-select
          [multiple]="true"
          formControlName="laborCrew"
          label="Select Labor Crew"
          label-placement="floating"
          placeholder="Select Labor Crew"
          >
          @for (crew of getCrewsToDisplay(); track crew) {
            <ion-select-option [value]="crew.id.getValue()">
              {{ crew.name }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
      @if (getInactiveCrewInForm() && getInactiveCrewInForm().length) {
        <ion-item class="ion-item-select-help">
          <ion-label>
            <ion-note>
              <small>
                Please note that inactive crews are used in the form:
                @for (crew of getInactiveCrewInForm(); track crew) {
                  <ion-text color="primary">
                    {{ crew.name }}
                  </ion-text>
                }
              </small>
            </ion-note>
          </ion-label>
        </ion-item>
      }
    </div>
  }
}

