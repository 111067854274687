import {MsalService} from "@azure/msal-angular";
import {IMsAlAuthService} from "@modules/microsoft/microsoft-auth/Domain/AuthService/ims-al-auth-service";

export class TestMsAlAuthService extends IMsAlAuthService {
  getMsalPromise(): Promise<MsalService> {
    return new Promise((resolve) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      resolve(null);
    });
  }
}
