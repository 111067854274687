import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {
  CreateMiscItemComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/create-misc-item/create-misc-item.component";
import {
  MiscCalculationComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/misc-calculation-area/misc-calculation.component";
import {
  UpdateMiscItemComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/update-misc-item/update-misc-item.component";

const routes: Routes = [
  {
    path: ':calculationID/:miscAreaID/new',
    component: CreateMiscItemComponent
  },
  {
    path: ':calculationID/:miscAreaID/:miscItemID/update',
    component: UpdateMiscItemComponent
  },
  {
    path: ':calculationID',
    component: MiscCalculationComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalculationAreaRoutingModule {
}
