import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {BlowInArea} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area";
import {
  BlowInAreaFactory
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-factory";
import {
  BlowInAreaOptions
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-options";
import {CrewID} from "@modules/gm-inputs/Domain/Crew/VO/crew-id";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";

import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
    selector: 'app-area',
    templateUrl: './blow-in-area.component.html',
    styleUrls: ['./blow-in-area.component.scss'],
    standalone: false
})
export class BlowInAreaComponent implements OnInit, OnDestroy, CalculatorAreaComponent<BlowInArea> {
  @Input() inputModel: BlowInArea | undefined;
  @Output() inputModelChange: EventEmitter<BlowInArea> = new EventEmitter<BlowInArea>();

  protected areaType: BlowInAreaTypeEnum[] = [
    BlowInAreaTypeEnum.Attic,
    BlowInAreaTypeEnum.SubFloors,
    BlowInAreaTypeEnum.Walls
  ];
  protected selectedAreaType = BlowInAreaTypeEnum.Attic;
  protected selectedRValue?: number;
  protected form: FormGroup;
  protected readonly BlowInAreaTypeEnum = BlowInAreaTypeEnum;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private blowInAreaFactory: BlowInAreaFactory,
  ) {
    this.form = new FormGroup({
      blowInType: new FormControl(),
      sqft: new FormControl(),
      construction: new FormControl(),
      areaType: new FormControl(),
      toggleNetService: new FormControl(),
      rValue: new FormControl(),
      linearFeet: new FormControl(),
      product: new FormControl(),
      laborCrew: new FormControl(),
      projHours: new FormControl(),
      misc: new FormControl(),
      netProduct: new FormControl(),
    });
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  processChanges(values: any) {
    if (!values || !this.inputModel) return;

    this.selectedAreaType = values.areaType;

    const params: BlowInAreaOptions = {
      id: this.inputModel?.id || '',
      name: this.inputModel?.name || '',
      blowInType: values.blowInType,
      sqft: values.sqft,
      construction: values.construction,
      areaType: values.areaType,
      toggleNetService: values.toggleNetService,
      rValue: values.rValue,
      linearFeet: values.linearFeet,
      blowInProduct: values.product,
      laborCrew: values.laborCrew.map((crewID: number) => new CrewID(crewID)),
      projHours: values.projHours,
      miscellaneous: values.misc,
      netProduct: values.netProduct,
      businessUnitID: this.inputModel.businessUnitID,
    };

    const inputModel = this.blowInAreaFactory.execute(params);
    this.inputModelChange.emit(inputModel);
  }

  isBlowInNetVisible(): boolean {
    if (this.selectedAreaType === BlowInAreaTypeEnum.Attic) return false;
    if (this.selectedAreaType === BlowInAreaTypeEnum.Walls) return true;

    return this.form.value.toggleNetService;
  }

  private initForm() {
    if (!this.inputModel) {
      throw new Error('inputModel is required');
    }

    this.form.patchValue({
      blowInType: this.inputModel.blowInType,
      sqft: this.inputModel.sqft,
      construction: this.inputModel.construction,
      areaType: this.inputModel.areaType,
      toggleNetService: this.inputModel.toggleNetService,
      rValue: this.inputModel.rValue,
      linearFeet: this.inputModel.linearFeet,
      product: this.inputModel.blowInProduct,
      laborCrew: this.inputModel.laborCrew.map((crewID) => crewID.getValue()),
      projHours: this.inputModel.projHours,
      misc: this.inputModel.miscellaneous,
      netProduct: this.inputModel.netProduct
    });

    this.selectedAreaType = this.inputModel.areaType;
    this.selectedRValue = this.inputModel.rValue;
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => this.processChanges(values));
  }
}
