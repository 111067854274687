import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";

@Injectable({
  providedIn: 'root'
})
export class CreateCategoryCommand implements UseCase<Category, Promise<Category>> {

  constructor(
    private readonly categoryRepository: CategoryRepository
  ) {
  }

  execute(category: Category): Promise<Category> {
    return this.categoryRepository.create(category);
  }
}
