import {Injectable} from '@angular/core';
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {
  DynamicFormField,
  DynamicFormFieldParams
} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-field";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {
  DynamicFormFieldDropdown
} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-dropdown";
import {DynamicFormFieldNumber} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-number";
import {DynamicFormFieldString} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-string";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {
  RequiredFieldValidatorRule
} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/Rules/required-field-validator-rule";
import {Category} from "@modules/product/product-category/Domain/Category/category";

@Injectable({
  providedIn: 'root'
})
export class CategoryDffFieldsProvider {

  constructor() {
  }

  execute(category: Category): DynamicFormFields {
    const fields: DynamicFormField<ControlTypes>[] = [];

    for (const field of category.fieldsConfig.getFieldConfigs()) {
      fields.push(
        this.createField(field)
      )
    }


    return new DynamicFormFields(fields);
  }

  private createField(field: FieldConfig) {
    const fieldParameters: DynamicFormFieldParams<never> = {
      key: field.name.getValue(),
      value: undefined,
      required: field.validator.isRuleExists(new RequiredFieldValidatorRule()),
      label: field.label,
      placeholder: undefined,
      errorMessage: undefined,
      disabled: false,
      options: field.options
    };

    switch (field.type) {
      case FieldType.String:
        return new DynamicFormFieldString(fieldParameters);
      case FieldType.Number:
        return new DynamicFormFieldNumber(fieldParameters);
      case FieldType.Dropdown:
        if (!field.options) throw new Error('Field must have options');
        return new DynamicFormFieldDropdown(fieldParameters);
      default:
        throw new Error('Unknown field type');
    }
  }
}
