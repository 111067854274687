import {Injectable} from "@angular/core";
import {RequiredFieldsValidator} from "@modules/_shared/Service/Validator/required-fields-validator.service";
import {
  InvalidFieldTypeException
} from "@modules/product/product-category-fields/Domain/Field/Field/Exception/invalid-field-type.exception";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {
  FieldsValidatorSerializer
} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/Factory/fields-validator-serializer.service";

interface FieldConfigInput {
  [key: string]: unknown;
}

@Injectable({providedIn: 'root'})
export class FieldConfigSerializer {

  constructor(
    private readonly requiredFieldsValidator: RequiredFieldsValidator,
    private readonly fieldsValidatorFactory: FieldsValidatorSerializer
  ) {
  }

  deserialize(input: FieldConfigInput): FieldConfig {
    const requiredFields = ['name', 'label', 'description', 'type', 'validator'];
    this.requiredFieldsValidator.validate<FieldConfigInput>(input, requiredFields);

    const name = new FieldConfigName(input['name'] as string);
    const label = input['label'] as string;
    const description = input['description'] as string;
    const type = this.parseType(input['type'] as string);
    const validator = this.fieldsValidatorFactory.deserialize(input['validator'] as string);
    const options = input['options'] !== undefined ? input['options'] as string[] : undefined;

    return new FieldConfig(name, label, description, type, validator, options);
  }

  serialize(fieldConfig: FieldConfig): FieldConfigInput {
    return {
      name: fieldConfig.name.getValue(),
      label: fieldConfig.label,
      description: fieldConfig.description,
      type: fieldConfig.type,
      validator: this.fieldsValidatorFactory.serialize(fieldConfig.validator),
      options: fieldConfig.options
    }
  }

  private parseType(input: string): FieldType {
    switch (input) {
      case 'String':
        return FieldType.String;
      case 'Number':
        return FieldType.Number;
      case 'Dropdown':
        return FieldType.Dropdown;
      default:
        throw new InvalidFieldTypeException(input);
    }
  }
}
