import {Component} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {Platform} from "@ionic/angular";
import {AppVersionUpdateService} from "@modules/_shared/Service/UpdateService/app-version-update.service";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {addIcons} from "ionicons";
import {
  add,
  albumsOutline,
  calculatorOutline,
  chatbubbleEllipses,
  chevronDownOutline,
  chevronExpandOutline,
  documentOutline,
  downloadOutline,
  ellipsisVerticalOutline,
  helpOutline,
  informationCircleOutline,
  listOutline,
  location,
  lockClosedOutline,
  optionsOutline,
  peopleOutline,
  pricetagsOutline,
  readerOutline,
  refreshCircleOutline,
  settingsOutline,
  trash
} from "ionicons/icons";
import {window} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: false
})
export class AppComponent {
  protected readonly enableAnimation: boolean;

  constructor(
    private readonly router: Router,
    updateService: AppVersionUpdateService,
    platform: Platform
  ) {
    if (environment.environment === EnvironmentType.production) {
      updateService.checkForUpdate();
      this.initGtag();
    }

    this.enableAnimation = !platform.is('desktop');

    addIcons({
      settingsOutline,
      optionsOutline,
      peopleOutline,
      pricetagsOutline,
      chatbubbleEllipses,
      helpOutline,
      chevronDownOutline,
      ellipsisVerticalOutline,
      listOutline,
      calculatorOutline,
      add,
      documentOutline,
      downloadOutline,
      chevronExpandOutline,
      informationCircleOutline,
      readerOutline,
      lockClosedOutline,
      trash,
      albumsOutline,
      refreshCircleOutline
    });
  }

  initGtag(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const gtag = window['gtag'];
    if (!gtag) return;

    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('event', 'page_view', {
            page_title: this.router.routerState.snapshot.url,
            page_path: event.urlAfterRedirects,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            page_location: location.origin
          })
        }
      });
  }
}
