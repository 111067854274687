import {Injectable} from "@angular/core";
import {
  InvalidFieldTypeException
} from "@modules/product/product-category-fields/Domain/Field/Field/Exception/invalid-field-type.exception";
import {FieldFactory} from "@modules/product/product-category-fields/Domain/Field/Field/Factory/field-factory";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {Category} from "@modules/product/product-category/Domain/Category/category";

interface JsonFieldData {
  [key: string]: string;
}

@Injectable({providedIn: 'root'})
export class JsonFieldsFactory {
  constructor(
    private readonly fieldFactory: FieldFactory
  ) {
  }

  fromJson(category: Category, json: string): Fields {
    const data = JSON.parse(json) as JsonFieldData;
    if (typeof data !== 'object') {
      throw new InvalidFieldTypeException('Fields JSON is not an object');
    }

    const fields: Field[] = [];
    for (const [name, value] of Object.entries(data)) {
      fields.push(this.createField(category, name, value));
    }
    return new Fields(fields);
  }

  toJson(fields: Fields): string {
    const fieldsMap: JsonFieldData = {}
    for (const field of fields.fields) {
      fieldsMap[field.name.getValue()] = field.getValue().toString();
    }

    return JSON.stringify(fieldsMap);
  }

  private createField(category: Category, name: string, value: string): Field {
    const fieldConfigName = new FieldConfigName(name);
    const fieldConfig = category.fieldsConfig.getFieldConfig(fieldConfigName);
    if (!fieldConfig) {
      throw new InvalidFieldTypeException(`Field config not found for field ${name}`);
    }

    return this.fieldFactory.execute(
      fieldConfig.type,
      value,
      fieldConfig.name.getValue()
    );
  }
}
