import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  GetUserBusinessUnitQuery
} from "@modules/business-unit/Application/UseCase/Query/get-user-business-unit-query.service";
import {
  IsCurrentUserCorporateQuery
} from "@modules/product/product/Application/Service/is-current-user-corporate.service";
import {Product} from "@modules/product/product/Domain/Product/product";

@Injectable({
  providedIn: 'root'
})
export class UserOwnsProductQuery implements UseCase<Product, boolean> {
  constructor(
    private readonly isCurrentUserCorporate: IsCurrentUserCorporateQuery,
    private readonly getUserBusinessUnitQuery: GetUserBusinessUnitQuery,
  ) {
  }

  execute(product: Product): boolean {
    const isUserCorporate = this.isCurrentUserCorporate.execute();
    if (isUserCorporate) return true;

    const userBusinessUnitID = this.getUserBusinessUnitQuery.execute().id;
    const userOwnsProduct = product.ownerBusinessUnitID.equals(userBusinessUnitID);

    return userOwnsProduct && !product.isProductCopy;
  }
}
