import {Injectable} from "@angular/core";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaLinkProvider {
  public static readonly BASE_URL = 'misc';

  public static getBaseUrl(): string[] {
    return ['/', MiscAreaLinkProvider.BASE_URL];
  }

  public static getMiscAreaLink(calculationID: CalculationID): string[] {
    return [
      ...MiscAreaLinkProvider.getBaseUrl(),
      calculationID.toString()
    ];
  }

  public static getCreateNewMiscItemLink(calculationID: CalculationID, areaID: string): string[] {
    return [
      ...MiscAreaLinkProvider.getBaseUrl(),
      calculationID.toString(),
      areaID,
      'new'
    ];
  }

  public static getUpdateMiscItemLink(calculationID: CalculationID, areaID: string, miscItemID: string): string[] {
    return [
      ...MiscAreaLinkProvider.getBaseUrl(),
      calculationID.toString(),
      areaID,
      miscItemID,
      'update'
    ];
  }
}
