import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";
import {BlowInNetProduct} from "@modules/product/products/blow-in-net-product/Domain/Product/blow-in-net-product";
import {BlowInNetProducts} from "@modules/product/products/blow-in-net-product/Domain/Product/blow-in-net-products";


@Component({
  selector: 'app-blow-in-net',
  templateUrl: './blow-in-net.component.html',
  styleUrls: ['./blow-in-net.component.scss'],
  standalone: false
})
export class BlowInNetComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  protected filteredProducts: BlowInNetProduct[] = [];
  private products: BlowInNetProducts | undefined;

  constructor(
    private readonly getProductsByCategoryQuery: GetProductsByCategoryNameQuery
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.products = await this.getProductsByCategoryQuery.execute<BlowInNetProducts>({
      categoryName: new BlowInNetCategoryName()
    });
    this.filteredProducts = this.products?.sortByName().toArray() || [];
  }
}
