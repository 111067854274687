import {Inject, Injectable} from "@angular/core";
import {
  AppInitBusinessUnitsProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-business-units-provider.service";
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";

@Injectable({providedIn: 'root'})
export class GetCorporateBusinessUnitsUseCase {
  constructor(
    private readonly appInitBusinessUnitsProvider: AppInitBusinessUnitsProvider,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
  }

  execute(): BusinessUnits {
    const allBusinessUnits = this.appInitBusinessUnitsProvider.getBusinessUnits();
    return allBusinessUnits.getDirectChildren(new BusinessUnitID(this.rootBusinessUnit));
  }
}
