import {Injectable} from '@angular/core';
import {
  JsonFieldsFactory
} from "@modules/product/product-category-fields/Domain/Field/Field/Factory/json-fields-factory";
import {
  IsCurrentUserCorporateQuery
} from "@modules/product/product/Application/Service/is-current-user-corporate.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  DynamicsCreateProduct
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Model/dynamics-create-product";

@Injectable({
  providedIn: 'root'
})
export class DynamicsCreateProductMapperService {
  constructor(
    private readonly jsonFieldsFactory: JsonFieldsFactory,
    private readonly isCurrentUserCorporateQuery: IsCurrentUserCorporateQuery,
  ) {
  }

  execute(product: Product): Promise<DynamicsCreateProduct> {
    const relationships = this.createRelationships(product);
    const dynamicsProduct: DynamicsCreateProduct = {
      cr9b4_name: product.name.getValue(),
      cr9b4_description: product.description.getValue(),
      cr9b4_manufacturer: product.manufacturer.getValue(),
      cr9b4_price: product.price.getValue(),
      cr9b4_categoryspecificfields: this.jsonFieldsFactory.toJson(product.getAllFields()),
      ...relationships
    }
    return Promise.resolve(dynamicsProduct);
  }


  private createRelationships(product: Product): Partial<DynamicsCreateProduct> {
    const isCorporate = this.isCurrentUserCorporateQuery.execute();
    return {
      // Create a relationship between the product and the business unit
      ["owningbusinessunit@odata.bind"]: isCorporate ? `/businessunits(${product.ownerBusinessUnitID.toString()})` : undefined,
      // Create a relationship between the product and the category
      ["cr9b4_CategoryID@odata.bind"]: `/cr9b4_categories(${product.category.id.getValue()})`,
      // Create a relationshp between the product and the original product
      "cr9b4_OriginalProductID@odata.bind": product.originalProduct
        ? `/cr9b4_products(${product.originalProduct.id.getValue()})`
        : undefined
    }
  }
}
