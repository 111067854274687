import {
  BlowInBlowProductType
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Application/blow-in-blow-product-type";
import {
  BlowInAreaTypeEnum
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/Enum/blow-in-service-type-enum";
import {Product} from "@modules/product/old-products/Domain/Product/product";
import {Price} from "@modules/product/old-products/Domain/Product/VO/price";

export interface BlowInBlowProductOptions {
  id: string;
  blowInType: BlowInServiceTypeEnum;
  blowInProduct: string;
  pricePer: number;
  bagsPerFullStack: number;
  rValue: number;
  areaType: BlowInAreaTypeEnum;
  framing: string;
  minInitialInstalledThicknessIn: number;
  minSettledThicknessIn: number;
  maxNetCoveragePerBagSqFt: number;
  minNetCoverageBagsPer1000SqFt: number;
  minNetCoverageWeightPerSqFtlbsSqFt: number;
  maxGrossCoverageSqFtPerBag: number;
  minGrossCoverageBagsPer1000SqFt: number;
  installedDensity: number;
  maxCoveragePerBagNotAdjustedForFraming: number;
  maxCoveragePerBagAdjustedForFraming16OC: number;
  maxCoveragePerBagAdjustedForFraming24OC: number;
}

export class BlowInBlowProduct extends Product {
  readonly blowInType: BlowInServiceTypeEnum;
  readonly blowInProduct: string;
  readonly bagsPerFullStack: number;
  readonly rValue: number;
  readonly areaType: BlowInAreaTypeEnum;
  readonly framing: string;
  readonly minInitialInstalledThicknessIn: number;
  readonly minSettledThicknessIn: number;
  readonly maxNetCoveragePerBagSqFt: number;
  readonly minNetCoverageBagsPer1000SqFt: number;
  readonly minNetCoverageWeightPerSqFtlbsSqFt: number;
  readonly maxGrossCoverageSqFtPerBag: number;
  readonly minGrossCoverageBagsPer1000SqFt: number;
  readonly installedDensity: number;
  readonly maxCoveragePerBagNotAdjustedForFraming: number;
  readonly maxCoveragePerBagAdjustedForFraming16OC: number;
  readonly maxCoveragePerBagAdjustedForFraming24OC: number;


  constructor(options: BlowInBlowProductOptions) {
    const keys: string[] = Object.keys(options);
    keys.forEach((key: string) => {
      const optionKey = key as keyof BlowInBlowProductOptions;
      if (options[optionKey] === undefined) {
        throw new Error(`Missing required key: ${optionKey}`);
      }
    });

    super({
      id: options.id,
      productInternalID: options.blowInProduct,
      originalPrice: new Price(options.pricePer),
      name: `${options.blowInProduct} - ${options.areaType}`,
      productType: BlowInBlowProductType
    });

    this.blowInType = options.blowInType;
    this.blowInProduct = options.blowInProduct;
    this.bagsPerFullStack = options.bagsPerFullStack;
    this.rValue = options.rValue;
    this.areaType = options.areaType;
    this.framing = options.framing;
    this.minInitialInstalledThicknessIn = options.minInitialInstalledThicknessIn;
    this.minSettledThicknessIn = options.minSettledThicknessIn;
    this.maxNetCoveragePerBagSqFt = options.maxNetCoveragePerBagSqFt;
    this.minNetCoverageBagsPer1000SqFt = options.minNetCoverageBagsPer1000SqFt;
    this.minNetCoverageWeightPerSqFtlbsSqFt = options.minNetCoverageWeightPerSqFtlbsSqFt;
    this.maxGrossCoverageSqFtPerBag = options.maxGrossCoverageSqFtPerBag;
    this.minGrossCoverageBagsPer1000SqFt = options.minGrossCoverageBagsPer1000SqFt;
    this.installedDensity = options.installedDensity;
    this.maxCoveragePerBagNotAdjustedForFraming = options.maxCoveragePerBagNotAdjustedForFraming;
    this.maxCoveragePerBagAdjustedForFraming16OC = options.maxCoveragePerBagAdjustedForFraming16OC;
    this.maxCoveragePerBagAdjustedForFraming24OC = options.maxCoveragePerBagAdjustedForFraming24OC;
  }

  isApplicableForAreaType(areaType: BlowInAreaTypeEnum): boolean {
    return this.areaType === areaType;
  }
}
