import {Injectable} from "@angular/core";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  CategoryProductCreationManager
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {GetProductRequest} from "@modules/product/product/Application/UseCase/Query/GetProduct/get-product-request";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";


@Injectable({providedIn: 'root'})
export class GetProductQuery implements UseCase<GetProductRequest, Promise<Product>> {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly productFactory: CategoryProductCreationManager
  ) {
  }

  async execute<T extends Product>(request: GetProductRequest): Promise<T> {
    const product = await this.productRepository.getOne(request.productID);
    return this.productFactory.createProduct<T>(product);
  }
}
