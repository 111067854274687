import {Injectable} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  CategoriesInitService,
  CategoryInit
} from "@modules/product/product-category/Infrastructure/Migrations/categories-init.service";
import {
  ProvidersFactory
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/providers-factory.service";
import {
  ProductSaverService
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/saver/product-saver.service";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";
import {
  BattingInsulationCategoryFieldsConfig
} from "@modules/product/products/batting-insulation-product/Domain/Field/batting-insulation-category-fields-config";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";
import {
  BattingMineralWoolCategoryFieldsConfig
} from "@modules/product/products/batting-mineral-wool-product/Domain/Field/batting-mineral-wool-category-fields-config";
import {
  BlowInInsulationCategoryName
} from "@modules/product/products/blow-in-blow-product/Domain/Category/blow-in-insulation-category-name";
import {
  BlowInBlowCategoryFieldsConfig
} from "@modules/product/products/blow-in-blow-product/Domain/Field/blow-in-blow-category-fields-config";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";
import {
  BlowInNetCategoryFieldsConfig
} from "@modules/product/products/blow-in-net-product/Domain/Field/blow-in-net-category-fields-config";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {
  FoamCategoryFieldsConfig
} from "@modules/product/products/foam-product/Domain/Field/foam-category-fields-config";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";
import {
  MiscCategoryFieldsConfig
} from "@modules/product/products/misc-product/Domain/Field/misc-category-fields-config";

@Injectable({
  providedIn: 'root'
})
export class ProductsMigrator {
  constructor(
    private readonly providersFactory: ProvidersFactory,
    private readonly productSaver: ProductSaverService,
    private readonly categoriesInitService: CategoriesInitService,
    private readonly notificationService: NotificationService
  ) {
  }

  async execute(): Promise<void> {
    await this.initCategories();
    await this.productSaver.clearAllProducts();


    const providers = this.providersFactory.execute();

    for (const provider of providers) {
      const providerProducts = await provider.execute();
      await this.productSaver.execute(providerProducts);
    }

    this.notificationService.execute('Products migrated').then();
  }

  private async initCategories() {
    const categories: CategoryInit[] = [
      {
        name: new BattingInsulationCategoryName(),
        title: 'Batting Insulation',
        description: 'Batting Insulation',
        fields: new BattingInsulationCategoryFieldsConfig(),
      },
      {
        name: new BattingMineralWoolCategoryName(),
        title: 'Batting Mineral Wool',
        description: 'Batting Mineral Wool',
        fields: new BattingMineralWoolCategoryFieldsConfig(),
      },
      {
        name: new BlowInInsulationCategoryName(),
        title: 'Blow In Insulation',
        description: 'Blow In Insulation',
        fields: new BlowInBlowCategoryFieldsConfig(),
      },
      {
        name: new BlowInNetCategoryName(),
        title: 'Wall Netting',
        description: 'Wall Netting',
        fields: new BlowInNetCategoryFieldsConfig(),
      },
      {
        name: new FoamCategoryName(),
        title: 'Foam',
        description: 'Foam',
        fields: new FoamCategoryFieldsConfig(),
      },
      {
        name: new MiscCategoryName(),
        title: 'Miscellaneous',
        description: 'Miscellaneous',
        fields: new MiscCategoryFieldsConfig(),
      }
    ]

    await this.categoriesInitService.execute(categories);
  }
}
