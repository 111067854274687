import {Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {
  NotificationActionProcessor
} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action-processor";
import {Notification} from "@modules/area-context-notifications/Domain/Notification/notification";
import {
  ModalNotificationActionComponent
} from "@modules/area-context-notifications/Presentation/Component/modal-notification-action.component";

@Injectable({
  providedIn: 'root'
})
export class DisplayModalNotificationActionProcessor extends NotificationActionProcessor {

  constructor(
    private readonly modalCtrl: ModalController,
  ) {
    super();
  }

  async showNotifications(action: Notification[]): Promise<void> {
    action = action.sort((a, b) => a.action.priority - b.action.priority);
    for (const notificationAction of action) {
      await this.displayModalNotification(notificationAction);
    }
  }

  private async displayModalNotification(notification: Notification): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalNotificationActionComponent,
      componentProps: {
        notificationAction: notification.action,
        notificationID: notification.notificationID
      },
      backdropDismiss: false,
      cssClass: 'sheet-modal',
    });
    await modal.present();
    await modal.onDidDismiss();
  }
}
