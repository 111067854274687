import {Injectable} from '@angular/core';
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  DynamicsRetrieveQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-query.service";
import {Categories} from "@modules/product/product-category/Domain/Category/categories";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {
  CategoryNotFoundException
} from "@modules/product/product-category/Domain/Category/Exception/category-not-found-exception";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  DynamicsCategoryMapper
} from "@modules/product/product-category/Infrastructure/Repository/DynamicsCategoryRepository/Mapper/dynamics-category-mapper.service";
import {
  DynamicsCategory
} from "@modules/product/product-category/Infrastructure/Repository/DynamicsCategoryRepository/Model/dynamics-category";
import {CreateRequest, RetrieveMultipleRequest, RetrieveRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class DynamicsCategoryRepository implements CategoryRepository {

  private readonly tableName = 'cr9b4_categories';

  constructor(
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsRetrieveQuery: DynamicsRetrieveQuery,
    private readonly dynamicsCreateRequest: DynamicsCreateCommand,
    private readonly dynamicsCategoryMapper: DynamicsCategoryMapper
  ) {
  }

  async create(category: Category): Promise<Category> {
    const model = this.dynamicsCategoryMapper.mapTo(category);
    const request: CreateRequest = {
      collection: this.tableName,
      data: {
        ...model,
        cr9b4_categoryid: undefined,
      },
      returnRepresentation: true
    }

    const response = await this.dynamicsCreateRequest.execute(request);
    return this.dynamicsCategoryMapper.mapFrom(response);
  }

  async getAll(): Promise<Categories> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsCategory>(request);
    return new Categories(response.value.map(category => this.dynamicsCategoryMapper.mapFrom(category)));
  }

  async getByID(id: CategoryID): Promise<Category> {
    const request: RetrieveRequest = {
      collection: this.tableName,
      key: id.getValue(),
    };

    const response = await this.dynamicsRetrieveQuery.execute<DynamicsCategory>(request);
    return this.dynamicsCategoryMapper.mapFrom(response);
  }


  async getByName(name: CategoryName): Promise<Category> {
    const request: RetrieveMultipleRequest = {
      collection: this.tableName,
      filter: `cr9b4_name eq '${name}'`,
    };

    const response = await this.dynamicsRetrieveMultipleQuery.execute<DynamicsCategory>(request);
    if (response.value.length === 0) {
      throw new CategoryNotFoundException(name);
    }

    return this.dynamicsCategoryMapper.mapFrom(response.value[0]);
  }
}
