import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {
  UpdateProductCommand
} from "@modules/product/product/Application/UseCase/Command/UpdateProduct/update-product-command.service";
import {GetProductQuery} from "@modules/product/product/Application/UseCase/Query/GetProduct/get-product-query.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductLinkProvider} from "@modules/product/product/Infrastructure/Provider/product-link-provider";
import {
  ProductFromDffFactory
} from "@modules/product/product/Presentation/Service/ProductFromDffFactory/product-from-dff-factory.service";
import {
  ProductToDffTransformer
} from "@modules/product/product/Presentation/Service/ProductToDffTransformer/product-to-dff-transformer.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-product-editor',
  templateUrl: './product-editor.component.html',
  styleUrls: ['./product-editor.component.scss'],
  standalone: false
})
export class ProductEditorComponent implements OnInit {
  @Input() productID?: string;
  rightButton: RightButton = {
    title: 'Save',
    callback: () => this.onSaveClicked(),
  }
  title = '...';
  product?: Product;
  formGroup?: FormGroup;
  dynamicFormFields?: DynamicFormFields;
  protected isLoading = false;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly updateProductCommand: UpdateProductCommand,
    private readonly getProductQuery: GetProductQuery,
    private readonly productToDffTransformer: ProductToDffTransformer,
    private readonly productFromDffFactory: ProductFromDffFactory,
    private readonly loadingService: LoadingService,
    private readonly router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.isLoading = loading);

    await this.loadingService.execute({executeCb: this.loadProduct.bind(this)});
  }

  async createProductDynamicFields(): Promise<void> {
    if (!this.product) throw new Error('Product not loaded');
    this.dynamicFormFields = await this.productToDffTransformer.execute(this.product);
  }

  getBackLink() {
    if (!this.product) return [];

    return ProductLinkProvider.getCategoryProductListRoute(this.product.category.id);
  }

  onFieldsChanged(fields: DynamicFormFields) {
    this.dynamicFormFields = fields;
  }

  async onSaveClicked(): Promise<void> {
    return await this.loadingService.execute({executeCb: this.saveProduct.bind(this)});
  }

  private async saveProduct(): Promise<void> {
    if (!this.product) throw new Error('Cannot save product without product');
    if (!this.dynamicFormFields) throw new Error('Dynamic form fields not loaded');

    const newProduct = this.productFromDffFactory.execute(
      this.product.id,
      this.product.category,
      this.dynamicFormFields,
      this.product.ownerBusinessUnitID
    );

    this.product = await this.updateProductCommand.execute(newProduct);
    await this.createProductDynamicFields();
    await this.notificationService.execute('Product updated');
    await this.router.navigate(ProductLinkProvider.getCategoryProductListRoute(this.product.category.id));
  }

  private async loadProduct(): Promise<void> {
    if (!this.productID) throw new Error('Product ID is required');

    this.product = await this.getProductQuery.execute({
      productID: new ProductID(this.productID)
    });
    this.title = this.product.name.getValue();
    await this.createProductDynamicFields();
  }

}
