import {PushedEstimate} from "@modules/service-titan/service-titan-estimate/Domain/PushedEstimate/pushed-estimate";

export class PushedEstimates {
  constructor(
    private readonly estimates: PushedEstimate[]
  ) {
  }

  getCount(): number {
    return this.estimates.length;
  }

  getAmount(): number {
    return this.estimates.reduce((total, estimate) => total + estimate.amount, 0);
  }
}
