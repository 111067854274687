import {Injectable} from '@angular/core';
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {DynamicsWebApi, UpdateRequest} from "dynamics-web-api";

export interface ProductsMigratorAreaSaverRequest {
  id: string;
  state: CalculationAreaOptions;
}

@Injectable({
  providedIn: 'root'
})
export class ProductsMigratorAreaSaver {

  constructor(
    private readonly dynamicsWebApi: DynamicsWebApi,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
  ) {
  }

  async execute(requests: ProductsMigratorAreaSaverRequest[]): Promise<void> {
    this.dynamicsWebApi.startBatch();

    requests.forEach(request => {
      const updateRequest: UpdateRequest = {
        collection: 'cr9b4_ifoamcalculationtabs',
        key: request.id,
        data: {
          cr9b4_areastate: JSON.stringify(request.state)
        }
      };
      this.dynamicsUpdateCommand.execute(updateRequest)
    })

    await this.dynamicsWebApi.executeBatch();

    return Promise.resolve();
  }
}
