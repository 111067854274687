import {Injectable} from "@angular/core";
import {BlowInNetProduct} from "@modules/product/old-products/Configurations/blow-in-net-product/Domain/blow-in-net-product";
import {BlowInNetProducts} from "@modules/product/old-products/Configurations/blow-in-net-product/Domain/blow-in-net-products";
import {
  BlowInNetDynamicsModel
} from "@modules/product/old-products/Configurations/blow-in-net-product/Infrastructure/Model/blow-in-net-dynamics-model";
import {
  DynamicsProductsFactory
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductRepository/Factory/dynamics-products-factory";

@Injectable({'providedIn': 'root'})
export class BlowInNetProductDynamicsProductsFactory extends DynamicsProductsFactory<BlowInNetProducts, BlowInNetProduct> {
  execute(data: BlowInNetDynamicsModel[]): BlowInNetProducts {
    return new BlowInNetProducts(
      data.map((product) => this.createBlowInNetProduct(product))
    );
  }

  private createBlowInNetProduct(data: BlowInNetDynamicsModel): BlowInNetProduct {
    return new BlowInNetProduct({
      id: data.cr9b4_ifoamicalcproductsblowinnetid,
      linearYieldFt: data.cr9b4_linearyieldft,
      netProduct: data.cr9b4_netproduct,
      pricePer: data.cr9b4_priceper,
    });
  }
}
