import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {GeneralManagerAuthGuard} from "@modules/gm-inputs/Infrastructure/Auth/can-activate-gm-inputs";
import {
  DeleteTestProductsComponent
} from "@modules/product/product/Presentation/Component/delete-test-products/delete-test-products.component";
import {
  ProductCreatorComponent
} from "@modules/product/product/Presentation/Component/product-creator/product-creator.component";
import {
  ProductEditorComponent
} from "@modules/product/product/Presentation/Component/product-editor/product-editor.component";
import {
  ProductListComponent
} from "@modules/product/product/Presentation/Component/product-list/product-list.component";
import {
  ProductsMigratorComponent
} from "@modules/product/product/Presentation/Component/products-migrator/products-migrator.component";


const routes: Routes = [
  {
    path: 'category-product-list/:categoryID',
    component: ProductListComponent,
    canActivate: [GeneralManagerAuthGuard]
  },
  {
    path: 'product-edit/:productID',
    component: ProductEditorComponent,
    canActivate: [GeneralManagerAuthGuard]
  },
  {
    path: 'product-create/:categoryID',
    component: ProductCreatorComponent,
    canActivate: [GeneralManagerAuthGuard]
  },
  {
    path: 'migrate',
    component: ProductsMigratorComponent,
    canActivate: [GeneralManagerAuthGuard]
  },
  {
    path: 'delete-23412312-asdasdasdas',
    component: DeleteTestProductsComponent,
    canActivate: [GeneralManagerAuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class ProductRoutingModule {
}
