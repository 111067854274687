import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Categories} from "@modules/product/product-category/Domain/Category/categories";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";

@Injectable({
  providedIn: 'root'
})
export class GetCategoriesQuery implements UseCase<void, Promise<Categories>> {
  constructor(
    private readonly categoryRepository: CategoryRepository
  ) {
  }

  execute(): Promise<Categories> {
    return this.categoryRepository.getAll();
  }
}
