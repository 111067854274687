import {EstimateID} from "@modules/estimate/Domain/Estimate/VO/estimate-id";
import {
  EstimateID as ServiceTitanEstimateID
} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";

export class PushedEstimate {
  constructor(
    public readonly estimateID: EstimateID,
    public readonly serviceTitanID: ServiceTitanEstimateID,
    public readonly amount: number
  ) {
  }
}
