import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  CategoryProductCreationManager
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-manager.service";
import {
  CannotDirectlyCreateCorporateChildProductException
} from "@modules/product/product/Domain/Product/Exception/cannot-directly-create-corporate-child-product-exception";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";

@Injectable({
  providedIn: 'root'
})
export class CreateProductCommand implements UseCase<Product, Promise<Product>> {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly productFactory: CategoryProductCreationManager
  ) {
  }

  async execute<T extends Product>(product: T): Promise<T> {
    if (product.id.getValue() !== '00000000-0000-0000-0000-000000000000') {
      throw new Error('Product ID should be 00000000-0000-0000-0000-000000000000');
    }

    if (product.originalProduct) {
      throw new CannotDirectlyCreateCorporateChildProductException();
    }

    const newProduct = await this.productRepository.create(product);
    return this.productFactory.createProduct<T>(newProduct);
  }
}
