import {Injectable} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {
  CreateProductCommand
} from "@modules/product/product/Application/UseCase/Command/CreateProduct/create-product-command.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  DynamicsUpdateProduct
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Model/dynamics-update-product";
import {DeleteRequest, DynamicsWebApi, RetrieveMultipleRequest} from "dynamics-web-api";

@Injectable({
  providedIn: 'root'
})
export class ProductSaverService {
  private products?: DynamicsUpdateProduct[];

  constructor(
    private readonly createProductCommand: CreateProductCommand,
    private readonly retrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly deleteRecordCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsWebApi: DynamicsWebApi,
    private readonly notificationService: NotificationService
  ) {
  }

  async execute(products: Product[]): Promise<void> {
    this.notificationService.execute(`Saving ${products.length} products for category ${products[0].category.name}`).then();
    await this.getProducts()

    this.dynamicsWebApi.startBatch();

    for (const product of products) {
      const productAlreadyExists = await this.productAlreadyExists(product);
      if (productAlreadyExists) {
        continue;
      }
      this.createProductCommand.execute(product).then(
        () => {
        },
        () => {
        }
      );
    }

    await this.dynamicsWebApi.executeBatch();
  }

  public async clearAllProducts(): Promise<void> {
    const request: RetrieveMultipleRequest = {
      collection: 'cr9b4_products',
      select: ['cr9b4_productid']
    }

    const response = await this.retrieveMultipleQuery.execute<DynamicsUpdateProduct>(request);
    const ids = response.value.map(product => product.cr9b4_productid);

    this.notificationService.execute(`Deleting ${ids.length} products`).then();

    if (ids.length === 0) return;

    this.dynamicsWebApi.startBatch();

    ids.forEach(id => {

      const request: DeleteRequest = {
        collection: 'cr9b4_products',
        key: id
      }
      this.deleteRecordCommand.execute(request);
    });

    await this.dynamicsWebApi.executeBatch();

    this.notificationService.execute(`Deleted ${ids.length} products`).then();
  }

  private async getProducts(): Promise<DynamicsUpdateProduct[]> {
    if (this.products) return Promise.resolve(this.products);

    const request: RetrieveMultipleRequest = {
      collection: 'cr9b4_products',
    }

    const response = await this.retrieveMultipleQuery.execute<DynamicsUpdateProduct>(request);
    this.products = response.value;
    return this.products
  }

  private async productAlreadyExists(product: Product): Promise<boolean> {
    const products = await this.getProducts();
    return products.some(p => p.cr9b4_name === product.name.getValue())
  }
}
