<app-header
  [explicitBackUrl]="getBackUrl()"
  [rightButton]="rightButton"
  [title]="title"
></app-header>


<ion-content>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }
  @if (form) {
    <app-misc-item-form [form]="form"></app-misc-item-form>
  }
</ion-content>
