import {Injectable} from '@angular/core';
import {
  BlowInAreaOptions
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationArea/blow-in-area-options";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";

@Injectable({
  providedIn: 'root'
})
export class BlowInNetProductTransformer extends AbstractTransformer {

  async execute(area: BlowInAreaOptions): Promise<BlowInAreaOptions> {
    const productID = area.netProduct as unknown;
    if (undefined === productID) return Promise.resolve(area);

    if (!productID) {
      area.netProduct = undefined;
      return Promise.resolve(area);
    }

    const newProducts = await this.getNewProducts(new BlowInNetCategoryName());
    const newProduct = newProducts.toArray().find(
      product => product.name.getValue().startsWith(productID.toString())
    );

    if (!newProduct) throw new Error(`Cannot find new product for old ID ${productID}`);

    area.netProduct = newProduct.id.getValue();

    return Promise.resolve(area);
  }

}
