import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {
  CategoryNotFoundException
} from "@modules/product/product-category/Domain/Category/Exception/category-not-found-exception";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";

@Injectable({
  providedIn: 'root'
})
export class GetCategoryByIDQuery implements UseCase<CategoryID, Promise<Category>> {
  constructor(
    private readonly categoryRepository: CategoryRepository
  ) {
  }

  /**
   * @throws CategoryNotFoundException
   * @param id
   */
  execute(id: CategoryID): Promise<Category> {
    try {
      return this.categoryRepository.getByID(id);
    } catch (e) {
      throw new CategoryNotFoundException(id, e);
    }
  }
}
