<ion-content>
  <app-header [explicitBackUrl]="getBackLink()" [rightButton]="rightButton" [title]="title"></app-header>

  @if (isLoading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  @if (dynamicFormFields) {
    <app-dynamic-form-fields-renderer
      [fields]="dynamicFormFields"
      (fieldsChanged)="onFieldsChanged($event)"
    ></app-dynamic-form-fields-renderer>
  }
</ion-content>
