import {Inject, Injectable} from "@angular/core";
import {LOADING_DEBOUNCE} from "@modules/_shared/Service/LoadingService/loading-debounce-provider";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BehaviorSubject, debounceTime, Observable, OperatorFunction} from "rxjs";


export interface LoadingServiceParams<T> {
  executeCb: () => Promise<T>;
  additionalLoadingCb?: () => void;
  finallyCb?: () => void;
  errorCb?: (error: unknown) => void;
}

@UntilDestroy()
@Injectable({providedIn: "root"})
export class LoadingService {
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly loading$: Observable<boolean>;
  private readonly devMode: boolean = false;

  constructor(
    private readonly notificationService: NotificationService,
    @Inject(LOADING_DEBOUNCE) debounce: number = 100
  ) {
    const pipes = [untilDestroyed(this)];

    if (debounce > 0) {
      pipes.push(debounceTime(debounce));
    }

    this.loading$ = this.loadingSubject.asObservable().pipe(
      ...(pipes as [OperatorFunction<boolean, boolean>])
    );

    this.devMode = environment.environment === EnvironmentType.local;
  }

  getLoading(): Observable<boolean> {
    return this.loading$;
  }

  setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  async execute<T = unknown>(config: LoadingServiceParams<T>): Promise<T | undefined> {
    try {
      this.setLoading(true);
      if (config.additionalLoadingCb) {
        config.additionalLoadingCb();
      }
      return await config.executeCb();
    } catch (error) {
      if (config.errorCb) {
        config.errorCb(error);
      }

      await this.errorNotification(error);
      return undefined;
    } finally {
      this.setLoading(false);
      config.finallyCb?.();
    }
  }

  private async errorNotification(error: unknown): Promise<void> {
    await this.notificationService.execute(error);
    if (this.devMode) {
      throw error;
    }
  }
}
