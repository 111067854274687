import {Injectable} from "@angular/core";
import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {FoamProduct} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-product";
import {FoamProducts} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-products";
import {
  FoamDynamicsModel
} from "@modules/product/old-products/Configurations/foam-product/Infrastructure/Model/foam-dynamics-model";
import {
  DynamicsProductsFactory
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductRepository/Factory/dynamics-products-factory";

@Injectable({'providedIn': 'root'})
export class FoamProductDynamicsProductsFactory extends DynamicsProductsFactory<FoamProducts, FoamProduct> {
  execute(data: FoamDynamicsModel[]): FoamProducts {
    return new FoamProducts(
      data.map((product) => this.createBlowInNetProduct(product))
    );
  }

  private createBlowInNetProduct(data: FoamDynamicsModel): FoamProduct {
    const service = new FoamService(data.cr9b4_type);

    return new FoamProduct({
      id: data.cr9b4_ifoamicalcproductsfoamid,
      manufacturer: data.cr9b4_manufacturer,
      service: service,
      name: data.cr9b4_name,
      productYield: data.cr9b4_productyield,
      costPerSetOrGallon: data.cr9b4_costpersetor5gallonbucket,
    });
  }
}
