import {Injectable} from '@angular/core';
import {MISC_CALCULATION_ID} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module";
import {GetCalculationUseCase} from "@modules/calculation/Application/UseCase/Query/get-calculation-use-case.service";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class DefaultTabNameProviderService {

  constructor(
    private readonly getCalculationUseCase: GetCalculationUseCase,
  ) {
  }

  async execute(calculationID: CalculationID): Promise<string> {
    const calculation = await this.getCalculationUseCase.execute(calculationID);
    const isMiscCalculation = calculation.strategyID.equals(MISC_CALCULATION_ID);
    return isMiscCalculation ? 'Miscellaneous' : 'Unnamed Area';
  }
}
