import {Inject, Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  DynamicsCreateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-create-command.service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsUpdateCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-update-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {PRODUCT_PRICES_TABLE} from "@modules/product/old-products/Application/DI/product-prices-table-token";
import {
  ProductOverriddenPrice
} from "@modules/product/old-products/Domain/ProductOverriddenPrice/product-overridden-price";
import {
  ProductOverriddenPrices
} from "@modules/product/old-products/Domain/ProductOverriddenPrice/product-overridden-prices";
import {
  ProductOverriddenPriceRepository
} from "@modules/product/old-products/Domain/ProductOverriddenPrice/Repository/product-overridden-price-repository";
import {
  DynamicsProductOverriddenPriceFactory
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductOverriddenPriceRepository/Factory/dynamics-product-overridden-price-factory.service";
import {
  DynamicsPriceModel
} from "@modules/product/old-products/Infrastructure/Repository/DynamicsProductOverriddenPriceRepository/Model/dynamics-price-model";
import {CreateRequest, DeleteRequest, RetrieveMultipleRequest, RetrieveRequest, UpdateRequest} from "dynamics-web-api";


@Injectable({providedIn: 'root'})
export class DynamicsProductOverriddenPriceRepository extends ProductOverriddenPriceRepository {
  constructor(
    private readonly dynamicsRetrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly dynamicsCreateCommand: DynamicsCreateCommand,
    private readonly dynamicsUpdateCommand: DynamicsUpdateCommand,
    private readonly dynamicsDeleteRecordCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsProductOverriddenPriceFactory: DynamicsProductOverriddenPriceFactory,
    @Inject(PRODUCT_PRICES_TABLE) private readonly pricesTable: string
  ) {
    if (!pricesTable) throw new Error('PRODUCT_PRICES_TABLE is not defined');

    super();
  }

  async getAll(productType: ProductType, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrices> {
    const request: RetrieveMultipleRequest = {
      collection: this.pricesTable,
      queryParams: [
        `$filter=cr9b4_type eq '${productType.id}' and _owningbusinessunit_value eq '${businessUnitID}'`
      ]
    };


    const response = await this.dynamicsRetrieveMultipleQuery
      .execute<DynamicsPriceModel>(request);
    const models = response.value.map(model => this.dynamicsProductOverriddenPriceFactory.execute(model));
    return new ProductOverriddenPrices(models);
  }

  async getOne(productID: string, productType: ProductType, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrice> {
    const request: RetrieveRequest = {
      collection: this.pricesTable,
      queryParams: [
        `
        $filter=cr9b4_productid eq '${productID}'
        and cr9b4_type eq '${productType.id}'
        and _owningbusinessunit_value eq '${businessUnitID}'
        `.trim()
      ]
    };

    const response = await this.dynamicsRetrieveMultipleQuery
      .execute<DynamicsPriceModel>(request);

    const model = response.value[0];
    return this.dynamicsProductOverriddenPriceFactory.execute(model);
  }

  async create(overriddenPrice: ProductOverriddenPrice, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrice> {
    if (overriddenPrice.id) throw new Error('Product overridden price already exists');

    const model: Partial<DynamicsPriceModel> = {
      cr9b4_productid: overriddenPrice.productID,
      cr9b4_price: overriddenPrice.price.value,
      cr9b4_type: overriddenPrice.type.id,
      "owningbusinessunit@odata.bind": `/businessunits(${businessUnitID})`
    }

    const request: CreateRequest = {
      collection: this.pricesTable,
      data: model,
      returnRepresentation: true,
    }

    const response = await this.dynamicsCreateCommand.execute<DynamicsPriceModel>(request);
    return this.dynamicsProductOverriddenPriceFactory.execute(response);
  }

  async update(overriddenPrice: ProductOverriddenPrice, businessUnitID: BusinessUnitID): Promise<ProductOverriddenPrice> {
    if (!overriddenPrice.id) throw new Error('Product overridden price does not exist');

    const model: Partial<DynamicsPriceModel> = {
      cr9b4_productid: overriddenPrice.productID,
      cr9b4_price: overriddenPrice.price.value,
      cr9b4_type: overriddenPrice.type.id
    }

    const request: UpdateRequest = {
      collection: this.pricesTable,
      key: overriddenPrice.id,
      data: model,
      returnRepresentation: true,
      queryParams: [
        `$filter=_owningbusinessunit_value eq '${businessUnitID}'`
      ]
    }

    const response = await this.dynamicsUpdateCommand.execute<DynamicsPriceModel>(request);
    return this.dynamicsProductOverriddenPriceFactory.execute(response);
  }

  delete(overriddenPrice: ProductOverriddenPrice): Promise<void> {
    if (!overriddenPrice.id) throw new Error('Product overridden price does not exist');

    const request: DeleteRequest = {
      collection: this.pricesTable,
      key: overriddenPrice.id
    }

    return this.dynamicsDeleteRecordCommand.execute(request);
  }
}
