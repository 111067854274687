import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  CreateMiscItemComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/create-misc-item/create-misc-item.component";

@Component({
  selector: 'app-update-misc-item',
  templateUrl: './update-misc-item.component.html',
  styleUrls: ['./update-misc-item.component.scss'],
  standalone: false
})
export class UpdateMiscItemComponent extends CreateMiscItemComponent {
  @Input({required: true}) miscItemID?: string;
  override title = 'Update Miscellaneous Item';

  override async createForm() {
    if (!this.miscArea) throw new Error('Area is required');
    if (this.miscItemID === undefined) throw new Error('Item ID is required');

    const item = this.miscArea.getItem(Number(this.miscItemID));
    if (!item) throw new Error('Item not found');

    this.form = this.miscItemService.createFormGroup(item);
  }

  override async process(form: FormGroup, miscArea: MiscArea): Promise<void> {
    if (this.miscItemID === undefined) throw new Error('Item ID is required');

    const areaItem = await this.miscItemService.mapToDomain(form);
    const area = miscArea.updateItem(Number(this.miscItemID), areaItem);

    return this.miscItemService.updateCalculationArea(area);
  }
}
