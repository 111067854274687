import {ValueObject} from "@modules/_shared/Domain/value-object";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldValue} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-value";

export abstract class Field<T extends FieldValue = FieldValue> extends ValueObject<T> {
  protected _type!: void;

  protected constructor(
    value: T,
    public readonly name: FieldName,
    public readonly type: FieldType
  ) {
    super(value);
  }
}
