import {
  BlowInBlowProduct
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/blow-in-blow-product";
import {
  BlowInBlowProducts
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Domain/blow-in-blow-products";
import {
  BlowInBlowDynamicsProductsFactory
} from "@modules/product/old-products/Configurations/blow-in-blow-product/Infrastructure/Factory/blow-in-blow-dynamics-products-factory.service";
import {
  DynamicsProductConfiguration
} from "@modules/product/old-products/Infrastructure/dynamics-product-configuration";

export const BlowInBlowDynamicsProductConfiguration: DynamicsProductConfiguration<BlowInBlowProducts, BlowInBlowProduct> = {
  productsTable: 'cr9b4_ifoamicalcproductsblowinblows',
  columns: [
    'cr9b4_ifoamicalcproductsblowinblowid',
    'cr9b4_blowintype',
    'cr9b4_product',
    'cr9b4_priceper',
    'cr9b4_bagsperfullstack',
    'cr9b4_rvalue',
    'cr9b4_areatype',
    'cr9b4_framing',
    'cr9b4_mininitialinstalledthicknessin',
    'cr9b4_minsettledthicknessin',
    'cr9b4_maxnetcoverageperbagsqft',
    'cr9b4_minnetcoveragebagsper1000sqft',
    'cr9b4_minnetcoverageweightpersqftlbssqft',
    'cr9b4_maxgrosscoveragesqftperbag',
    'cr9b4_mingrosscoveragebagsper1000sqft',
    'cr9b4_installeddensity',
    'cr9b4_maxcoverageperbagnotadjustedforframing',
    'cr9b4_maxcoverageperbagadjustedforframing16oc',
    'cr9b4_maxcoverageperbagadjustedforframing24oc'
  ],
  productsFactory: new BlowInBlowDynamicsProductsFactory()
}
