import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";
import {
  RecordUserInteractionCommand
} from "@modules/user-interactions/Application/UseCase/Command/RecordInteraction/record-user-interaction-command.service";
import {
  AreaContextNotificationsInteractionCategory
} from "@modules/area-context-notifications/Application/Category/area-context-notifications-interaction-category";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({
  providedIn: 'root'
})
export class RecordShownContextNotificationCommand implements UseCase<NotificationID, Promise<void>> {

  constructor(
    private readonly recordUserInteractionCommand: RecordUserInteractionCommand,
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileUseCase,
  ) {
  }


  execute(notificationID: NotificationID): Promise<void> {
    const profile = this.getCurrentUserProfileUseCase.execute();

    const request = {
      userID: profile.profileID,
      category: AreaContextNotificationsInteractionCategory,
      notificationID: notificationID
    };

    return this.recordUserInteractionCommand.execute(request);
  }
}

