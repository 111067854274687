import {Injectable} from '@angular/core';
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {
  BlowInNetProductType
} from "@modules/product/old-products/Configurations/blow-in-net-product/Application/blow-in-net-product-type";
import {
  BlowInNetProduct
} from "@modules/product/old-products/Configurations/blow-in-net-product/Domain/blow-in-net-product";
import {
  BlowInNetProducts
} from "@modules/product/old-products/Configurations/blow-in-net-product/Domain/blow-in-net-products";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  ProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/product-provider.service";
import {
  BlowInNetCategoryName
} from "@modules/product/products/blow-in-net-product/Domain/Category/blow-in-net-category-name";

@Injectable({
  providedIn: 'root'
})
export class BlowInNetProductProvider extends ProductProvider<BlowInNetProducts, BlowInNetProduct> {
  protected getFields(oldProduct: BlowInNetProduct): Fields {
    return new Fields([
      new FieldNumber(oldProduct.linearYieldFt, new FieldName('linearYieldFt')),
    ]);
  }

  protected getManufacturer(oldProduct: BlowInNetProduct): string {
    const firstWord = oldProduct.name.split(' ')[0];
    if (firstWord === 'Insulweb') {
      return 'Insulweb';
    }

    return 'Owens Corning';
  }

  protected getProductType(): ProductType {
    return BlowInNetProductType;
  }

  protected override getCategoryName(): CategoryName {
    return new BlowInNetCategoryName();
  }
}
