@if (formGroup && field) {
  <ion-item [formGroup]="formGroup">
    <ion-select
      [formControlName]="field.key"
      [label]="field.label"
      [placeholder]="field.placeholder"
      label-placement="floating"
    >
      @for (option of field.options; track option) {
        <ion-select-option [value]="option">
          {{ option }}
        </ion-select-option>
      }
    </ion-select>
  </ion-item>

  @if (formGroup.get(field.key)?.touched && formGroup.get(field.key)?.invalid) {
    <ion-item>
      <ion-note color="danger">{{ field.errorMessage }}</ion-note>
    </ion-item>
  }
}
