import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  MiscAreaItem,
  ProductMiscAreaItem
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaItemFormGroupFactory {
  createFormGroup(item: MiscAreaItem | ProductMiscAreaItem): FormGroup {
    return new FormGroup({
      name: new FormControl(item.name, [Validators.required]),
      count: new FormControl(item.count, [Validators.required]),
      value: new FormControl(item.getRawValue(), [Validators.required]),
      productID: new FormControl(
        item instanceof ProductMiscAreaItem ? item.product.id.getValue() : null
      )
    });
  }

  createEmptyFormGroup(): FormGroup {
    return new FormGroup({
      name: new FormControl('', [Validators.required]),
      count: new FormControl(1, [Validators.required]),
      value: new FormControl(0, [Validators.required]),
      productID: new FormControl(null)
    });
  }
}
