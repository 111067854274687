import {Injectable} from '@angular/core';
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  availableStrategies
} from "@modules/product/product/Application/Factory/ProductCreationManager/available-strategies";
import {
  ProductCreationStrategy
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-strategy";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";

export interface ProductFactoryStrategy {
  category: CategoryName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  strategy: ProductCreationStrategy<any, any>;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryProductCreationManager {
  private readonly strategies: ProductFactoryStrategy[] = availableStrategies;

  createProduct<T extends Product>(product: Product): T {
    const strategy = this.getStrategy<Products, T>(product.category.name);
    return strategy.createProduct(product);
  }

  createProducts<T extends Products>(products: Products, categoryName: CategoryName): T {
    const strategy = this.getStrategy<T, Product>(categoryName);
    return strategy.createProducts(products);
  }

  private getStrategy<T extends Products, U extends Product>(category: CategoryName): ProductCreationStrategy<T, U> {
    const strategy = this.strategies.find(
      s => s.category.equals(category)
    );

    if (!strategy) throw new Error(`Cannot find strategy for category ${category}`);
    return strategy.strategy;
  }
}
