@if (loading) {
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
}

@if (form) {
  <form [formGroup]="form">
    <ion-list lines="none">
      <ion-item>
        <ion-input
          errorText="Product name is required"
          formControlName="name"
          label="Product Name"
          label-placement="floating"
          placeholder="Product Name"
          [debounce]="200"
          (ionInput)="handleInput($event)"
          [clearInput]="true"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          appClearZero
          formControlName="count"
          inputmode="numeric"
          label="Quantity"
          label-placement="floating"
          placeholder="Quantity"
          type="number"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-input
          appClearZero
          formControlName="value"
          inputmode="numeric"
          label="Price per unit"
          label-placement="floating"
          placeholder="Price per unit"
          type="number"
        ></ion-input>
      </ion-item>
    </ion-list>
  </form>

  @if (filteredProducts.length) {
    <br/>
    <ion-list>
      <ion-item>
        <ion-label color="medium">Click to select existing product</ion-label>
        @if (form.get('productID')?.value) {
          <ion-note slot="end" class="link" (click)="clearSelection()">
            Clear selection
            <ion-icon name="close-outline"></ion-icon>
          </ion-note>
        }
      </ion-item>
      @for (filteredProduct of filteredProducts; track filteredProduct.productID) {
        <ion-item [button]="true" (click)="selectProduct(filteredProduct)">
          <ion-icon slot="start" name="checkmark-outline"
                    *ngIf="isProductIDSelected(filteredProduct.productID)"
          ></ion-icon>
          <ion-label>{{ filteredProduct.name.toString() }}</ion-label>
          <ion-note slot="end">{{ filteredProduct.price.getValue() | currency }} each</ion-note>
        </ion-item>
      }
    </ion-list>

  }
}

