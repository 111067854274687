import {Injectable} from '@angular/core';
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {
  BattingMineralWoolProductType
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Application/batting-mineral-wool-product-type";
import {
  BattingMineralWoolProduct
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-product";
import {
  BattingMineralWoolProducts
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-products";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  ProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/product-provider.service";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";


@Injectable({
  providedIn: 'root'
})
export class BattingMineralWoolProductProvider extends ProductProvider<BattingMineralWoolProducts, BattingMineralWoolProduct> {
  protected getFields(oldProduct: BattingMineralWoolProduct): Fields {
    return new Fields([
      new FieldDropdown(oldProduct.rValue.value, new FieldName('rValue')),
      new FieldDropdown(oldProduct.onCenter.value?.toString() ?? ' ', new FieldName('onCenter')),
      new FieldNumber(Number(oldProduct.depth), new FieldName('depth')),
    ]);
  }

  protected getManufacturer(): string {
    return "Termafiber";
  }

  protected getProductType(): ProductType {
    return BattingMineralWoolProductType;
  }

  protected override getCategoryName(): CategoryName {
    return new BattingMineralWoolCategoryName();
  }
}
