import {Injectable} from '@angular/core';
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  BattingAreaOptions
} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/CalculationArea/batting-area-options";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";
import {
  BattingMineralWoolProducts
} from "@modules/product/products/batting-mineral-wool-product/Domain/Product/batting-mineral-wool-products";

@Injectable({
  providedIn: 'root'
})
export class BattingWoolProductTransformer extends AbstractTransformer {
  async execute(area: BattingAreaOptions): Promise<BattingAreaOptions> {
    if (area.battingServiceName !== BattingServiceEnum.MineralWool) return Promise.resolve(area);

    const productID = area.productID as unknown;
    if (undefined === productID) return Promise.resolve(area);

    if (productID === '1004335' || 0 === Number(productID) || !productID) {
      area.productID = undefined;
      return Promise.resolve(area);
    }

    const newProducts = await this.getNewProducts<BattingMineralWoolProducts>(new BattingMineralWoolCategoryName());
    const newProduct = newProducts.toArray().find(
      product => product.name.getValue().startsWith(productID.toString())
    )

    if (!newProduct) throw new Error(`Cannot find new product for old ID ${productID}`);

    area.productID = newProduct.id.getValue();

    return Promise.resolve(area);
  }

}
