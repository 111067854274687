import {
  BattingMineralWoolProduct
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-product";
import {
  BattingMineralWoolProducts
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Domain/batting-mineral-wool-products";
import {
  BattingMineralWoolDynamicsProductsFactory
} from "@modules/product/old-products/Configurations/batting-mineral-wool-product/Infrastructure/Factory/dynamics-batting-mineral-wool-products-factory.service";
import {
  DynamicsProductConfiguration
} from "@modules/product/old-products/Infrastructure/dynamics-product-configuration";

export const BattingMineralWoolDynamicsProductConfiguration: DynamicsProductConfiguration<BattingMineralWoolProducts, BattingMineralWoolProduct> = {
  productsTable: 'cr9b4_ifoamicalcproductsbattingmineralwools',
  columns: [
    'cr9b4_ifoamicalcproductsbattingmineralwoolid',
    'cr9b4_product',
    'cr9b4_rvalue',
    'cr9b4_oncenter',
    'cr9b4_widthinches',
    'cr9b4_tallinches',
    'cr9b4_orderpricingpersqft',
    'cr9b4_sqftperpkg',
    'cr9b4_costper1000sqft',
    'cr9b4_depth',
  ],
  productsFactory: new BattingMineralWoolDynamicsProductsFactory()
}
