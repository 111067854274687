import {Injectable} from "@angular/core";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {
  GetCalculatorTabUseCase
} from "@modules/calculation-tabs/Application/UseCase/get-calculator-tab/get-calculator-tab-use-case";
import {GetCalculationUseCase} from "@modules/calculation/Application/UseCase/Query/get-calculation-use-case.service";

@Injectable({
  providedIn: 'root'
})
export class GetCalculationAreaUseCase {
  constructor(
    private readonly getCalculationTabUseCase: GetCalculatorTabUseCase,
    private readonly getCalculationUseCase: GetCalculationUseCase
  ) {
  }

  async execute<T extends CalculationArea>(areaID: string): Promise<T> {
    const tab = await this.getCalculationTabUseCase.execute(areaID);
    const calculation = await this.getCalculationUseCase.execute(tab.calculationID);
    const state = tab.state as CalculationAreaOptions;
    state.id = tab.id;
    return calculation.calculationStrategy.areaFactory.execute(tab.state as CalculationAreaOptions) as T;
  }
}
