import {Injectable} from '@angular/core';
import {AccountInfo, AuthenticationResult} from '@azure/msal-browser';
import {IMsAlAccountService} from "@modules/microsoft/microsoft-auth/Domain/AuthService/ims-al-account-service";
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TestMsAlAccountService extends IMsAlAccountService {
  private readonly isLoggedIn$ = new ReplaySubject<boolean>(1);
  private readonly _currentAccount$ = new ReplaySubject<AccountInfo>(1);
  private readonly currentAccount$ = this._currentAccount$.asObservable();

  constructor() {
    super();
    this.isLoggedIn$.next(false);
  }

  getCurrentAccountPromise(): Promise<AccountInfo | null> {
    return new Promise((resolve) => {
      this.currentAccount$.subscribe((account) => {
        resolve(account);
      });
    });
  }

  getIsLoggedIn$(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  setAccountPayload(payload: AuthenticationResult): void {
    if (payload.account) {
      this.setAccount(payload.account);
    }
  }

  checkAndSetActiveAccount(): void {
    // Simulate checking accounts
    const fakeAccount: AccountInfo = {
      homeAccountId: '123',
      environment: 'test',
      tenantId: 'tenant',
      username: 'test@example.com',
      localAccountId: 'local123',
    };

    this.setAccount(fakeAccount);
  }

  getCurrentAccount(): Observable<AccountInfo> {
    return this.currentAccount$;
  }

  private setAccount(account: AccountInfo): void {
    this._currentAccount$.next(account);
    this.isLoggedIn$.next(true);
  }
}
