<ion-content>
  <app-header
    [explicitBackUrl]="getBackLink()"
    [rightButton]="rightButton"
    [title]="title"
  ></app-header>

  <div style="display: flex; align-items: center;">
    <ion-searchbar
      (ngModelChange)="searchProducts($event)"
      [(ngModel)]="searchTerm"
      [debounce]="100"
      placeholder="Search"
    ></ion-searchbar>

    @if (isBatchModeAvailable) {
      @if (batchMode) {
        <ion-button (click)="toggleBatchSelect(false)" color="tertiary" data-cy="product-batch-cancel-button">
          <ion-icon name="refresh-circle-outline" slot="start"></ion-icon>
          Cancel
        </ion-button>
      } @else {
        <ion-button (click)="toggleBatchSelect(true)" color="tertiary" data-cy="product-batch-button">
          <ion-icon name="albums-outline" slot="start"></ion-icon>
          Batch
        </ion-button>
      }
    }

  </div>

  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
    <p class="ion-text-center">
      Loading...
    </p>
  }
  @if (filteredProducts) {
    @if (batchMode) {
      <app-products-batch-mode-editor
        (pricesUpdated)="onBatchProductsUpdated($event)"
        [products]="filteredProducts"
      ></app-products-batch-mode-editor>
    } @else {
      <app-product-list-display [products]="filteredProducts"></app-product-list-display>
    }
  }
</ion-content>


