import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {IonViewDidEnter} from "@modules/_shared/Interface/ion-view-did-enter";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {
  GetCategoryByIDQuery
} from "@modules/product/product-category/Application/UseCase/Query/GetCategoryByID/get-category-by-i-d-query.service";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryLinkProvider} from "@modules/product/product-category/Infrastructure/Provider/category-link-provider";
import {
  GetProductsByCategoryIDQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryID/get-products-by-category-id-query.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {ProductLinkProvider} from "@modules/product/product/Infrastructure/Provider/product-link-provider";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-product-list-editor',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  standalone: false
})
export class ProductListComponent implements IonViewDidEnter {
  @Input() categoryID?: string;
  protected category?: Category;
  protected title: string = 'Product Editor';
  protected loading = false;
  protected products?: Products;
  protected filteredProducts?: Product[];
  protected searchTerm: string = '';
  protected batchMode = false;
  protected rightButton?: RightButton;
  protected isBatchModeAvailable: boolean = false;
  private businessUnitID?: BusinessUnitID;
  private readonly batchActiveRightButton: RightButton = {
    title: 'Cancel',
    callback: () => this.toggleBatchSelect(false),
  };

  private readonly addNewProductRightButton: RightButton = {
    title: 'Create',
    callback: () => this.addNewProduct(),
  };

  constructor(
    private readonly notificationService: NotificationService,
    private readonly getCategoryByIDQuery: GetCategoryByIDQuery,
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit,
    private readonly getProductsByCategoryIDQuery: GetProductsByCategoryIDQuery,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
  ) {
  }

  getBackLink(): string[] {
    return CategoryLinkProvider.getBaseRoute();
  }

  searchProducts(searchTerm: string) {
    if (!this.products) {
      this.filteredProducts = undefined;
      return;
    }
    this.filteredProducts = this.products
      .filterBySearchTerm(searchTerm)
      .sortByName()
      .toArray();
  }

  async onBatchProductsUpdated(products: Product[]) {
    this.toggleBatchSelect(false);
    if (!products || !this.products) return;

    await this.notificationService.execute("Prices updated");
    this.products = this.products.updateProducts(products);
    this.searchProducts(this.searchTerm);
  }

  async ionViewDidEnter(): Promise<void> {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);

    this.userSelectedBusinessUnit.selectedBusinessUnit$.pipe(untilDestroyed(this))
      .subscribe(async businessUnit => {
        if (!businessUnit) return;

        this.businessUnitID = businessUnit.id;
        await this.loadingService.execute({executeCb: () => this.loadProducts()});
      });

    await this.loadingService.execute({executeCb: () => this.loadCategory()});
    this.updateMenuItems();
  }

  protected toggleBatchSelect(enable: boolean): Promise<void> {
    this.batchMode = enable;
    this.updateMenuItems();
    return Promise.resolve();
  }

  private async loadCategory(): Promise<void> {
    if (!this.categoryID) throw new Error('Category ID is required');

    const categoryID = new CategoryID(this.categoryID);
    this.category = await this.getCategoryByIDQuery.execute(categoryID);
    this.title = this.category.title;
    this.updateMenuItems();
  }

  private async loadProducts(): Promise<void> {
    if (!this.categoryID) throw new Error('Category name is required');

    this.products = await this.getProductsByCategoryIDQuery.execute({
      categoryID: new CategoryID(this.categoryID),
      businessUnitID: this.businessUnitID
    });
    this.searchProducts(this.searchTerm);
  }

  private updateMenuItems() {
    if (!this.category) {
      this.rightButton = undefined;
      return;
    }

    if (this.batchMode) {
      this.rightButton = this.batchActiveRightButton;
      return;
    }

    this.isBatchModeAvailable = this.category.isBatchUpdateAvailable();
    this.rightButton = this.category.isCategoryProductsEditable() ? this.addNewProductRightButton : undefined;
  }

  private async addNewProduct(): Promise<void> {
    if (!this.categoryID) throw new Error('Category ID is required');
    await this.router.navigate(ProductLinkProvider.getCreateProductRoute(this.categoryID));
    return Promise.resolve();
  }
}
