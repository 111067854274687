import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {FieldValidator} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validator";

export class FoamCategoryFieldsConfig extends FieldsConfig {
  constructor() {
    super(FoamCategoryFieldsConfig.getFields());
  }

  private static getFields(): FieldConfig[] {
    return [
      new FieldConfig(
        new FieldConfigName('service'),
        'Service',
        'Service',
        FieldType.Dropdown,
        new FieldValidator([]),
        [FoamService.openCellFoam, FoamService.closedCellFoam, FoamService.intumescentCoating]
      ),
      new FieldConfig(new FieldConfigName('productYield'), 'Product Yield', 'Product Yield', FieldType.Number, new FieldValidator([])),
    ];
  }
}
