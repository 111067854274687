import {NotificationEvent} from "../NotificationEvent/notification-event";
import {Notification} from "./notification";

export class Notifications {
  constructor(private readonly notifications: Notification[]) {
  }

  getNotificationsByEvent(triggerEvent: NotificationEvent): Notification[] {
    return this.notifications.filter(notification => notification.isApplicable(triggerEvent));
  }
}
