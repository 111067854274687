import {Product} from "@modules/product/product/Domain/Product/product";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-service-type-enum";

/**
 * The old product fields:
 * - blowInProduct -> name
 * - pricePer -> price
 */
export class BlowInBlowProduct extends Product {
  readonly blowInType: BlowInServiceTypeEnum;
  readonly bagsPerFullStack: number;
  readonly rValue: number;
  readonly areaType: BlowInAreaTypeEnum;
  readonly maxNetCoveragePerBagSqFt: number;

  constructor(...params: ConstructorParameters<typeof Product>) {
    super(...params);

    this.rValue = this.getNumberField('rValue');
    this.bagsPerFullStack = this.getNumberField('bagsPerFullStack');
    this.areaType = this.createAreaType(this.getDropDownField('areaType'));
    this.blowInType = this.createBlowInType(this.getDropDownField('blowInType'));
    this.maxNetCoveragePerBagSqFt = this.getNumberField('maxNetCoveragePerBagSqFt');
  }

  static fromProduct(product: Product): BlowInBlowProduct {
    return new BlowInBlowProduct(
      product.id,
      product.name,
      product.description,
      product.manufacturer,
      product.price,
      product.ownerBusinessUnitID,
      product.category,
      product.getAllFields(),
      product.rootBusinessUnitID,
      product.originalProduct
    )
  }

  isApplicableForAreaType(areaType: BlowInAreaTypeEnum): boolean {
    return this.areaType === areaType;
  }

  private createBlowInType(stringField: string): BlowInServiceTypeEnum {
    switch (stringField) {
      case BlowInServiceTypeEnum.BlowInFiberglass:
        return BlowInServiceTypeEnum.BlowInFiberglass;
      case BlowInServiceTypeEnum.BlowInCellulose:
        return BlowInServiceTypeEnum.BlowInCellulose;
      default:
        throw new Error(`BlowInServiceType ${stringField} is not supported`);
    }
  }

  private createAreaType(stringField: string): BlowInAreaTypeEnum {
    switch (stringField) {
      case BlowInAreaTypeEnum.Attic:
        return BlowInAreaTypeEnum.Attic;
      case BlowInAreaTypeEnum.Walls:
        return BlowInAreaTypeEnum.Walls;
      case BlowInAreaTypeEnum.SubFloors:
        return BlowInAreaTypeEnum.SubFloors;
      default:
        throw new Error(`BlowInAreaType ${stringField} is not supported`);
    }
  }
}
