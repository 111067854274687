@if (formGroup && fields) {
  <form data-cy="product-form">
    <ion-list lines="none" [formGroup]="formGroup">
      @for (field of fields.getFields(); track field.key) {
        @switch (field.controlType) {
          @case (ControlTypes.String) {
            <app-dynamic-form-fields-string [formGroup]="formGroup" [field]="field"></app-dynamic-form-fields-string>
          }
          @case (ControlTypes.Number) {
            <app-dynamic-from-fields-number [formGroup]="formGroup" [field]="field"></app-dynamic-from-fields-number>
          }
          @case (ControlTypes.Dropdown) {
            <app-dynamic-form-fields-dropdown [formGroup]="formGroup"
                                              [field]="field"></app-dynamic-form-fields-dropdown>
          }
        }
      }
    </ion-list>
  </form>
}
