import {Injectable} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  FormGroupToTransformDynamicFormFieldsRequest
} from "@modules/dynamic-form/Application/UseCase/Command/TransformFormToDFF/form-group-to-transform-dynamic-form-fields-request";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {
  DynamicFormFieldsToFormTransformer
} from "@modules/dynamic-form/Domain/DynamicFormField/Service/dynamic-form-fields-to-form-transformer";

@Injectable({
  providedIn: 'root'
})
export class FormGroupToTransformDynamicFormFieldsCommand implements UseCase<
  FormGroupToTransformDynamicFormFieldsRequest, DynamicFormFields
> {

  constructor(
    private readonly dynamicFormFieldsToFormTransformer: DynamicFormFieldsToFormTransformer<FormGroup>
  ) {
  }

  execute(request: FormGroupToTransformDynamicFormFieldsRequest): DynamicFormFields {
    return this.dynamicFormFieldsToFormTransformer.transformBack(request.formGroup, request.fields);
  }
}
