<app-header
  [explicitBackUrl]="getBackUrl()"
  [rightButton]="rightButton"
  [titleInfo]="titleInfo"
  [title]="title"
></app-header>


<ion-content>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  @if (miscArea) {
    <ion-list>
      @for (item of miscArea.items; track i; let i = $index) {
        <ion-item-sliding #slidingItem class="deleting-sliding">
          <ion-item-options side="end">
            <ion-item-option (click)="deleteItem(i, slidingItem)" color="danger">
              Delete
            </ion-item-option>
          </ion-item-options>
          <ion-item [routerLink]="getUpdateMiscItemLink(i)" class="link">
            <ion-label>
              {{ item.name }}
              <ion-note color="medium">
                x{{ item.count || 1 }}
              </ion-note>
            </ion-label>
            <ion-label color="primary" slot="end">
              {{ item.getTotal() | currency }}
              <ion-icon name="settings-outline"></ion-icon>
            </ion-label>
          </ion-item>
        </ion-item-sliding>
      }
    </ion-list>
  }
  @if (miscArea && miscArea.items.length === 0) {
    <ion-item>
      <ion-label>No misc items added</ion-label>
    </ion-item>
  }
</ion-content>

