import {Injectable} from "@angular/core";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {DynamicsProfileValue} from "@modules/profile/Infrastructure/Repository/Type/dynamics-profile";

@Injectable({providedIn: 'root'})
export class ProfileFactory {
  public async fromDynamicsProfile(dynamicsProfileValue: DynamicsProfileValue): Promise<Profile> {
    return new Profile({
      profileID: dynamicsProfileValue.systemuserid,
      fullName: dynamicsProfileValue.fullname,
      title: dynamicsProfileValue.title,
      email: dynamicsProfileValue.internalemailaddress,
      businessUnitId: dynamicsProfileValue.businessunitid.businessunitid,
      businessUnitName: dynamicsProfileValue.businessunitid.name
    });
  }

}

