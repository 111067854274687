import {Injectable} from '@angular/core';
import {
  UpdateCalculationAreaUseCase
} from "@modules/calculation-area/Application/UseCase/Command/UpdateCalculationArea/update-calculation-area-use-case";
import {
  GetCalculationAreaUseCase
} from "@modules/calculation-area/Application/UseCase/Query/GetCalculationArea/get-calculation-area-use-case";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  GetCalculatorTabsUseCase
} from "@modules/calculation-tabs/Application/UseCase/get-calculator-tabs/get-calculator-tabes-use-case.service";
import {GetCalculationUseCase} from "@modules/calculation/Application/UseCase/Query/get-calculation-use-case.service";
import {Calculation} from "@modules/calculation/Domain/Calculation/calculation";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";

@Injectable({
  providedIn: 'root'
})
export class MiscCalculationService {
  constructor(
    private readonly getCalculationUseCase: GetCalculationUseCase,
    private readonly getCalculatorTabs: GetCalculatorTabsUseCase,
    private readonly getCalculationAreaUseCase: GetCalculationAreaUseCase,
    private readonly updateCalculationAreaUseCase: UpdateCalculationAreaUseCase
  ) {
  }

  async loadCalculation(calculationID: CalculationID): Promise<Calculation> {
    const calculation = await this.getCalculationUseCase.execute(calculationID);
    if (!calculation) throw new Error('Calculation not found');
    return calculation;
  }

  async loadArea(calculationID: CalculationID): Promise<MiscArea> {
    const tabs = await this.getCalculatorTabs.execute(calculationID);
    if (!tabs) throw new Error('Tabs not found');
    const firstTab = tabs.getFirstTab();
    return this.getCalculationAreaUseCase.execute(firstTab.id);
  }

  async updateArea(area: MiscArea): Promise<void> {
    await this.updateCalculationAreaUseCase.execute(area.id, area);
  }
}
