import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {
  AreaContextNotificationsInteractionCategory
} from "@modules/area-context-notifications/Application/Category/area-context-notifications-interaction-category";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";
import {
  GetUserInteractionsCountQuery
} from "@modules/user-interactions/Application/UseCase/Query/GetInteractions/get-user-interactions-count-query.service";
import {Request as UserInteractionRequest} from "@modules/user-interactions/Application/UseCase/request";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

@Injectable({
  providedIn: 'root'
})
export class IsOptOutButtonShouldBeShownQuery implements UseCase<NotificationID, Promise<boolean>> {
  private readonly maxCount = 50;

  constructor(
    private readonly getUserInteractionsQuery: GetUserInteractionsCountQuery,
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileUseCase
  ) {
  }

  async execute(notificationID: NotificationID): Promise<boolean> {
    const profile = this.getCurrentUserProfileUseCase.execute();

    const request: UserInteractionRequest = {
      category: AreaContextNotificationsInteractionCategory,
      notificationID: notificationID,
      userID: profile.profileID,
    }

    const response = await this.getUserInteractionsQuery.execute(request);
    return response > this.maxCount;
  }
}
