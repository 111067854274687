import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {BattingServiceEnum} from "@modules/calculation-impl/batting/_calculator/Domain/Calculator/batting-service-enum";
import {
  FILTER_DEPTH,
  FILTER_R_VALUE
} from "@modules/calculation-impl/batting/_calculator/Presentation/batting-area/batting-area.component";
import {ConstructionType} from "@modules/calculation-impl/batting/construction-type/construction-type";
import {
  GetBattingConstructionsUseCase
} from "@modules/calculation-impl/batting/construction/Application/UseCase/get-batting-constructions-use-case";
import {BattingConstruction} from "@modules/calculation-impl/batting/construction/Domain/batting-construction";
import {
  GetFacingsUseCase
} from "@modules/calculation-impl/batting/facing/Application/UseCase/get-facings-use-case.service";
import {Facing} from "@modules/calculation-impl/batting/facing/Domain/facing";
import {
  GetOnCenterItemsUseCase
} from "@modules/calculation-impl/batting/on-center/Application/UseCase/get-wall-center-items.service";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {
  RValueItemsProvider
} from "@modules/calculation-impl/batting/r-value/Application/UseCase/r-value-items-provider";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {
  EnergyStarRecommendationService
} from "@modules/energy-star-recommendation/Presentation/Service/energy-star-recommendation.service";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";
import {Product} from "@modules/product/product/Domain/Product/product";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";
import {
  BattingInsulationProducts
} from "@modules/product/products/batting-insulation-product/Domain/Product/batting-insulation-products";
import {
  BattingMineralWoolCategoryName
} from "@modules/product/products/batting-mineral-wool-product/Domain/Category/batting-mineral-wool-category-name";
import {
  BattingMineralWoolProducts
} from "@modules/product/products/batting-mineral-wool-product/Domain/Product/batting-mineral-wool-products";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
  selector: 'app-batting-area-product-selector',
  templateUrl: './batting-area-product-selector.component.html',
  styleUrls: ['./batting-area-product-selector.component.scss'],
  standalone: false
})
export class BattingAreaProductSelectorComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup = new FormGroup({});
  filterTypes: string[] = [FILTER_R_VALUE, FILTER_DEPTH];
  protected readonly battingServices: BattingServiceEnum[] = [
    BattingServiceEnum.BattInsulation,
    BattingServiceEnum.MineralWool
  ];
  protected readonly constructionTypes: ConstructionType[] = [
    ConstructionType.MetalFrame,
    ConstructionType.WoodFrame
  ]
  /**
   * External data
   */
  protected serviceSelected: BattingServiceEnum = BattingServiceEnum.BattInsulation;
  protected selectedWoolFilterType: string = FILTER_R_VALUE;
  protected onCenterItems: OnCenter[] = [];
  protected availableRValueItems: RValue[] = [];
  protected constructions: BattingConstruction[] = [];
  protected facings: Facing[] = [];
  /**
   * Data to filter
   */
  protected filteredRValueItems: RValue[] = [];
  protected filteredProducts: BattingMineralWoolProducts | BattingInsulationProducts | undefined;
  protected availableMineralWoolProducts: BattingMineralWoolProducts | undefined;
  protected readonly BattingServiceEnum = BattingServiceEnum;
  protected filteredWoolDepths: number[] = [];
  private availableInsulationProducts: BattingInsulationProducts | undefined;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly onCenterItemsProvider: GetOnCenterItemsUseCase,
    private readonly rValueItemsProvider: RValueItemsProvider,
    private readonly getBattingConstructionsUseCase: GetBattingConstructionsUseCase,
    private readonly getBattingFacingsUseCase: GetFacingsUseCase,
    private readonly energyStarRecommendationService: EnergyStarRecommendationService,
    private readonly getProductsByCategoryQuery: GetProductsByCategoryNameQuery,
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async loadData(): Promise<void> {
    const [
      availableInsulationProducts,
      availableMineralWoolProducts,
      availableRValueItems,
      constructions,
      onCenterItems,
      facings
    ] = await Promise.all([
      this.getProductsByCategoryQuery.execute<BattingInsulationProducts>({
        categoryName: new BattingInsulationCategoryName()
      }),
      this.getProductsByCategoryQuery.execute<BattingMineralWoolProducts>({
        categoryName: new BattingMineralWoolCategoryName()
      }),
      this.rValueItemsProvider.execute(),
      this.getBattingConstructionsUseCase.execute(),
      this.onCenterItemsProvider.execute(),
      this.getBattingFacingsUseCase.execute()
    ]);

    this.availableInsulationProducts = availableInsulationProducts;
    this.availableMineralWoolProducts = availableMineralWoolProducts;
    this.availableRValueItems = availableRValueItems;
    this.constructions = constructions;
    this.onCenterItems = onCenterItems;
    this.facings = facings;
  }

  async ngOnInit(): Promise<void> {
    await this.loadData();

    const updateFunction = () => {
      this.serviceSelected = this.form.get('battingServiceName')?.value ?? BattingServiceEnum.BattInsulation;
      this.selectedWoolFilterType = this.form.get('filterType')?.value ?? FILTER_R_VALUE;

      this.displayAvailableProducts();
      this.displayAvailableRValueItems();
      this.displayAvailableOnCenterItems();
      this.displayAvailableDepths();

      if (!this.filteredProducts || this.filteredProducts.isEmpty()) {
        this.form.get('productID')?.disable({emitEvent: false});
        this.form.get('productID')?.setErrors({required: true}, {emitEvent: false});
      } else {
        this.form.get('productID')?.enable({emitEvent: false});
        this.form.get('productID')?.setErrors(null, {emitEvent: false});
      }
    }

    updateFunction();
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(updateFunction);
  }

  async openEnergyRecommendationModal() {
    await this.energyStarRecommendationService.openModal();
  }

  getFilteredProducts(): Product[] {
    return this.filteredProducts
      ? this.filteredProducts.sortByName().toArray() as Product[]
      : [];
  }

  displayDepthFilter(): boolean {
    return this.serviceSelected === BattingServiceEnum.MineralWool
      && this.selectedWoolFilterType === FILTER_DEPTH;
  }

  private displayAvailableProducts() {
    this.filteredProducts = this.serviceSelected === BattingServiceEnum.MineralWool
      ? this.getMineralWoolProducts()
      : this.getInsulationProducts();
  }

  private getMineralWoolProducts(): BattingMineralWoolProducts | undefined {
    if (!this.availableMineralWoolProducts) throw new Error('MineralWoolProducts is empty');

    const onCenterVal = this.form.get('onCenter')?.value;
    const rValueVal = this.form.get('rValue')?.value;
    const depthVal = this.form.get('depth')?.value;

    if (!onCenterVal || !rValueVal) return undefined;

    const onCenter = new OnCenter(onCenterVal);
    const rValue = new RValue(rValueVal);

    const products = this.availableMineralWoolProducts.filterProductsByOnCenter(onCenter);

    if (depthVal) {
      return products.filterProductsByDepth(depthVal);
    }

    return products.filterProductsByRValue(rValue);
  }

  private getInsulationProducts(): BattingInsulationProducts | undefined {
    if (!this.availableInsulationProducts) throw new Error('InsulationProducts is empty');

    const onCenterVal = this.form.get('onCenter')?.value;
    const facingVal = this.form.get('facingName')?.value;
    const rValueVal = this.form.get('rValue')?.value;
    const constructionType = this.form.get('constructionType')?.value;

    if (!facingVal || !rValueVal) return undefined;

    const onCenter = new OnCenter(onCenterVal);
    const facing = new Facing(facingVal);
    const rValue = new RValue(rValueVal);

    return this.availableInsulationProducts
      .filterProductsByFacing(facing)
      .filterProductsByOnCenter(onCenter)
      .filterProductsByRValue(rValue)
      .filterProductsByConstructionType(constructionType);
  }

  private getCurrentProductRange(): BattingInsulationProducts | BattingMineralWoolProducts | undefined {
    return BattingServiceEnum.MineralWool === this.serviceSelected
      ? this.availableMineralWoolProducts
      : this.availableInsulationProducts;
  }

  private displayAvailableRValueItems(): void {
    const productRange = this.getCurrentProductRange();

    const constructionName = this.form.get('constructionName')?.value;
    if (!constructionName) {
      this.filteredRValueItems = productRange?.getRValueItems() ?? [];
      return;
    }

    const construction = new BattingConstruction(constructionName);
    this.filteredRValueItems = productRange?.getRValueItems(construction) ?? [];
  }

  private displayAvailableOnCenterItems(): void {
    const productRange = this.getCurrentProductRange();
    this.onCenterItems = productRange?.getOnCenterItems() ?? [];
  }

  private displayAvailableDepths() {
    if (this.selectedWoolFilterType !== FILTER_DEPTH) {
      this.filteredWoolDepths = [];
      return;
    }

    this.filteredWoolDepths = this.availableMineralWoolProducts?.getDepths() ?? [];
  }
}
