@if (products.length > 0) {
  <ion-list data-cy="products-list">
    @for (product of products; track product.productID; let i = $index) {

      <ion-item-sliding #slidingItem class="deleting-sliding">
        <ion-item [routerLink]="getProductEditLink(product)">
          <ion-note slot="start">{{ i + 1 }}</ion-note>
          <ion-label>
            <ion-text color="primary">
              <h2>{{ product.name }}</h2>
            </ion-text>
            <ion-text>
              <p>{{ product.getPrice() | currency: 'USD': 'symbol' : '1.0-10' }}</p>
            </ion-text>
          </ion-label>
          @if (product.isCorporateProductOrCopy) {
            <ion-badge color="primary">Corp</ion-badge>
          } @else {
            <ion-badge color="success">Zee</ion-badge>
          }
        </ion-item>

        @if (userCanDeleteProduct(product)) {
          <ion-item-options side="end">
            <ion-item-option (click)="deleteItem(product)" color="danger">
              <ion-icon slot="end" name="trash"></ion-icon>
              Delete
            </ion-item-option>
          </ion-item-options>
        }
      </ion-item-sliding>
    }
  </ion-list>
} @else {
  <div class="ion-text-center">
    <ion-note>No Products found</ion-note>
  </div>
}
