import {
  FieldNotFoundException
} from "@modules/product/product-category-fields/Domain/Field/Field/Exception/field-not-found.exception";
import {
  MismatchFieldTypeException
} from "@modules/product/product-category-fields/Domain/Field/Field/Exception/mismatch-field-type-exception";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";

export class Fields {
  constructor(
    public readonly fields: Field[]
  ) {
  }

  getByName<T extends Field>(name: FieldName, expectedType: FieldType): T {
    const field = this.fields.find(
      (field) => field.name.equals(name)
    );

    if (!field) {
      throw new FieldNotFoundException(name);
    }

    if (field.type !== expectedType) {
      throw new MismatchFieldTypeException(name, field.type, expectedType);
    }

    return field as T;
  }
}
