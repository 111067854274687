import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Notification} from "@modules/area-context-notifications/Domain/Notification/notification";
import {
  UserNotificationStatusRepository
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/Repository/user-notification-status-repository";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({
  providedIn: 'root'
})
export class UserNotificationEnabledQuery implements UseCase<Notification, Promise<boolean>> {

  constructor(
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileUseCase,
    private readonly userNotificationStatusRepository: UserNotificationStatusRepository
  ) {
  }

  async execute(notification: Notification): Promise<boolean> {
    const profile = this.getCurrentUserProfileUseCase.execute();
    const result = await this.userNotificationStatusRepository.getStatus(
      profile.profileID.toString(),
      notification.notificationID.toString()
    );

    return result.status.equalsValue(true);
  }
}
