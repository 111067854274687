<ion-content>
  <app-header [explicitBackUrl]="getBackLink()" [rightButton]="rightButton" [title]="title"></app-header>

  @if (isLoading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  @if (dynamicFormFields) {
    @if (dynamicFormFields.isAnyFieldDisabled()) {
      <ion-item>
        <ion-text class="ion-text-center">Some fields may be disabled because the product is for corporate use.
        </ion-text>
      </ion-item>
    }

    <app-dynamic-form-fields-renderer
      [fields]="dynamicFormFields"
      (fieldsChanged)="onFieldsChanged($event)"
    ></app-dynamic-form-fields-renderer>
  }
</ion-content>
