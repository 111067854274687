import {Injectable} from '@angular/core';
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {
  MigratorArea
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Extract/migrator-area";
import {
  ProductsMigratorAreaSaverRequest
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Load/products-migrator-area-saver.service";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {
  TransformerFactory
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/migrators-factory.service";

@Injectable({
  providedIn: 'root'
})
export class ProductsInAreasMigrator {
  constructor(
    private readonly transformerFactory: TransformerFactory
  ) {
  }

  public async execute(area: MigratorArea): Promise<ProductsMigratorAreaSaverRequest | undefined> {
    let state = JSON.parse(area.state);

    if (typeof state !== 'object' || state === null) {
      state = {
        id: '',
        name: 'Unknown',
        laborCrew: [],
      };
    }

    if (state.businessUnitID) {
      if (typeof state.businessUnitID === 'object') {
        state.businessUnitID = state.businessUnitID.value;
      }
    } else {
      state.businessUnitID = area.rowBusinessUnitID;
    }

    const transformers = this.transformerFactory.execute(area.calculationStrategyID);
    const newState = await this.transform(transformers, state);

    if (newState === state) {
      return;
    }

    return {
      id: area.id,
      state: newState
    }
  }

  private async transform(
    transformers: AbstractTransformer[],
    state: CalculationAreaOptions
  ): Promise<CalculationAreaOptions> {
    let modifiedState = {...state}

    for (const transformer of transformers) {
      modifiedState = {...await transformer.execute(modifiedState)}
    }

    return modifiedState;
  }
}
