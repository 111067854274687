import {Injectable} from '@angular/core';
import {Mapper} from "@modules/_shared/Domain/mapper";
import {Material} from "@modules/service-titan/service-titan-estimate/Domain/Material/material";
import {MaterialCode} from "@modules/service-titan/service-titan-estimate/Domain/Material/VO/material-code";
import {MaterialID} from "@modules/service-titan/service-titan-estimate/Domain/Material/VO/material-id";
import {
  MaterialModel
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Material/Model/material-model";

@Injectable({
  providedIn: 'root'
})
export class MaterialMapper implements Mapper<MaterialModel, Material> {
  mapFrom(param: MaterialModel): Material {
    return new Material(
      new MaterialID(param.id),
      MaterialCode.fromString(param.code),
      param.displayName
    );
  }

  mapTo(): MaterialModel {
    throw new Error('Not implemented');
  }
}
