import {Injectable} from '@angular/core';
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {FoamProductType} from "@modules/product/old-products/Configurations/foam-product/Application/foam-product-type";
import {FoamProduct} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-product";
import {FoamProducts} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-products";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  ProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/product-provider.service";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";

@Injectable({
  providedIn: 'root'
})
export class FoamProductProvider extends ProductProvider<FoamProducts, FoamProduct> {
  protected getFields(oldProduct: FoamProduct): Fields {
    return new Fields([
      new FieldDropdown(oldProduct.service.value, new FieldName('service')),
      new FieldNumber(oldProduct.productYield, new FieldName('productYield')),
    ]);
  }

  protected getManufacturer(oldProduct: FoamProduct): string {
    return oldProduct.manufacturer;
  }

  protected getProductType(): ProductType {
    return FoamProductType;
  }

  protected getCategoryName(): CategoryName {
    return new FoamCategoryName();
  }
}
