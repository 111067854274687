import {Injectable} from '@angular/core';
import {Mapper} from "@modules/_shared/Domain/mapper";
import {Estimate, EstimateOptions} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/estimate";
import {EstimateID} from "@modules/service-titan/service-titan-estimate/Domain/Estimate/VO/estimate-id";
import {
  ServiceTitanEstimateModel
} from "@modules/service-titan/service-titan-estimate/Infrastructure/Repository/Estimate/Model/service-titan-estimate-model";

@Injectable({
  providedIn: 'root'
})
export class EstimateFactory implements Mapper<ServiceTitanEstimateModel, Estimate> {
  constructor() {
  }

  mapFrom(param: ServiceTitanEstimateModel): Estimate {
    const estimateOptions: EstimateOptions = {
      ...param,
      id: new EstimateID(param.id),
    }
    return new Estimate(estimateOptions)
  }

  mapTo(): ServiceTitanEstimateModel {
    throw new Error('Not implemented');
  }
}
