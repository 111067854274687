import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {IonicModule, ModalController} from "@ionic/angular";
import {FoamArea} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area";
import {
  GetRoofPitchCalculationMethodsQuery
} from "@modules/calculation-impl/foam/roofPitch/Application/UseCase/Query/get-roof-pitch-calculation-methods-query.service";
import {
  GetRoofPitchesUseCase
} from "@modules/calculation-impl/foam/roofPitch/Application/UseCase/Query/get-roof-pitches-use-case.service";
import {
  RoofPitchCalculationMethods
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/roof-pitch-calculation-methods";
import {
  RoofPitchCalculationMethodName
} from "@modules/calculation-impl/foam/roofPitch/Domain/CalculationMethod/VO/roof-pitch-calculation-method-name";
import {RoofPitches} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/roof-pitches";
import {RoofPitchName} from "@modules/calculation-impl/foam/roofPitch/Domain/RoofPitch/ValueObject/roof-pitch-name";


@Component({
  selector: 'app-roof-pitch-modal',
  templateUrl: './roof-pitch-modal.component.html',
  styleUrls: ['./roof-pitch-modal.component.scss'],
  imports: [
    IonicModule,
    ReactiveFormsModule,
    CommonModule
  ],
  standalone: true
})
export class RoofPitchModalComponent implements OnInit {
  @Input() inputModel?: FoamArea;

  protected roofPitches?: RoofPitches;
  protected calculationMethods?: RoofPitchCalculationMethods;

  protected form: FormGroup;

  protected selectedRoofPitchFactor: number | undefined;
  protected finalResult: number | undefined;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly getRoofPitchesUseCase: GetRoofPitchesUseCase,
    private readonly getRoofPitchCalculationMethods: GetRoofPitchCalculationMethodsQuery
  ) {
    this.form = new FormGroup({
      calculationMethod: new FormControl(null, [Validators.required]),
      roofPitch: new FormControl(null, [Validators.required])
    });
  }

  async cancel() {
    await this.modalCtrl.dismiss(undefined, 'cancel');
  }

  async apply() {
    if (!this.form.valid) return;
    await this.modalCtrl.dismiss(this.inputModel, 'confirm');
  }

  async ngOnInit(): Promise<void> {
    this.roofPitches = await this.getRoofPitchesUseCase.execute();
    this.calculationMethods = await this.getRoofPitchCalculationMethods.execute();

    this.initForm();
  }

  private initForm() {
    if (!this.inputModel) throw new Error('Input model is required');

    const roofPitch = this.inputModel.getRoofPitch();
    if (roofPitch) {
      this.form.patchValue({
        calculationMethod: roofPitch.method,
        roofPitch: roofPitch.pitch.name.getValue()
      });
    }

    this.form.valueChanges.subscribe((values) => {
      if (this.form.invalid) return;
      this.calculateFinalResult(values);
    });
  }

  private calculateFinalResult(values: undefined) {
    if (!values || !this.inputModel) return;

    const selectedPitch = this.roofPitches?.findByName(
      new RoofPitchName(values['roofPitch'])
    )

    if (!selectedPitch) throw new Error('Selected pitch not found');
    const selectedCalculationMethod = this.calculationMethods?.findByName(
      new RoofPitchCalculationMethodName(values['calculationMethod'])
    );
    if (!selectedCalculationMethod) throw new Error('Selected calculation method not found');

    this.inputModel.setRoofPitch(selectedPitch, selectedCalculationMethod);

    this.selectedRoofPitchFactor = selectedCalculationMethod.name.equals(RoofPitchCalculationMethods.PitchFactor.name)
      ? selectedPitch.roofPitchFactor
      : selectedPitch.valleyHipFactor;


    this.finalResult = selectedCalculationMethod.name.equals(RoofPitchCalculationMethods.PitchFactor.name)
      ? selectedPitch.calcByRoofPitchFactor(this.inputModel.getRawSqft())
      : selectedPitch.calcByValleyFactor(this.inputModel.getRawSqft());
  }

}
