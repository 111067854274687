import {Inject, Injectable} from '@angular/core';
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  JsonFieldsFactory
} from "@modules/product/product-category-fields/Domain/Field/Field/Factory/json-fields-factory";
import {
  GetCategoriesQuery
} from "@modules/product/product-category/Application/UseCase/Query/GetCategories/get-categories-query.service";
import {Categories} from "@modules/product/product-category/Domain/Category/categories";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {
  ProductHasInvalidCategoryException
} from "@modules/product/product/Domain/Product/Exception/product-has-invalid-category-exception";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductBusinessOwnerID} from "@modules/product/product/Domain/Product/VO/product-business-owner-id";
import {ProductDescription} from "@modules/product/product/Domain/Product/VO/product-description";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductManufacturer} from "@modules/product/product/Domain/Product/VO/product-manufacturer";
import {ProductName} from "@modules/product/product/Domain/Product/VO/product-name";
import {ProductPrice} from "@modules/product/product/Domain/Product/VO/product-price";
import {
  DynamicsRetrieveProduct
} from "@modules/product/product/Infrastructure/Repository/DynamicsProductRepository/Model/dynamics-retrieve-product";

@Injectable({
  providedIn: 'root'
})
export class ProductFactory {
  private cachedCategories?: Categories;

  constructor(
    private readonly getCategoriesQuery: GetCategoriesQuery,
    private readonly jsonFieldsFactory: JsonFieldsFactory,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
  }

  async mapFrom<T extends Product>(product: DynamicsRetrieveProduct): Promise<T> {
    if (!product._owningbusinessunit_value) throw new Error('Dynamics Products should have a business owner');
    if (!product._cr9b4_categoryid_value) throw new Error('Dynamics Products should have a category');

    const category = await this.getCategory(product._cr9b4_categoryid_value);
    const fields = this.jsonFieldsFactory.fromJson(category, product.cr9b4_categoryspecificfields);
    const originalProduct = product.cr9b4_OriginalProductID
      ? await this.mapFrom(product.cr9b4_OriginalProductID)
      : undefined;

    return new Product(
      new ProductID(product.cr9b4_productid),
      new ProductName(product.cr9b4_name),
      new ProductDescription(product.cr9b4_description),
      new ProductManufacturer(product.cr9b4_manufacturer),
      new ProductPrice(product.cr9b4_price ?? 0),
      new ProductBusinessOwnerID(product._owningbusinessunit_value),
      category,
      fields,
      new BusinessUnitID(this.rootBusinessUnit),
      originalProduct
    ) as T;
  }


  private async getCategories(): Promise<Categories> {
    if (!this.cachedCategories) {
      this.cachedCategories = await this.getCategoriesQuery.execute();
    }
    return this.cachedCategories;
  }

  private async getCategory(categoryID: string): Promise<Category> {
    const categories = await this.getCategories();
    const category = categories.findByID(new CategoryID(categoryID));

    if (!category) throw new ProductHasInvalidCategoryException(categoryID);

    return category;
  }
}
