import {Injectable} from "@angular/core";
import {
  InvalidFieldTypeException
} from "@modules/product/product-category-fields/Domain/Field/Field/Exception/invalid-field-type.exception";
import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {FieldString} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-string";

@Injectable({providedIn: 'root'})
export class FieldFactory {
  execute(type: string, value: string, name: string): Field {
    switch (type) {
      case FieldType.Number:
        return new FieldNumber(Number(value), new FieldName(name));
      case FieldType.String:
        return new FieldString(value as string, new FieldName(name));
      case FieldType.Dropdown:
        return new FieldDropdown(value as string, new FieldName(name));
      default:
        throw new InvalidFieldTypeException(type);
    }
  }
}
