import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {FieldValidator} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validator";
import {
  BlowInAreaTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-area-type-enum";
import {
  BlowInServiceTypeEnum
} from "@modules/product/products/blow-in-blow-product/Domain/Product/Enum/blow-in-service-type-enum";

export class BlowInBlowCategoryFieldsConfig extends FieldsConfig {
  constructor() {
    super(BlowInBlowCategoryFieldsConfig.getFields());
  }

  private static getFields(): FieldConfig[] {
    return [
      new FieldConfig(
        new FieldConfigName('blowInType'),
        'blowInType',
        'Blow In Type',
        FieldType.Dropdown,
        new FieldValidator([]),
        [BlowInServiceTypeEnum.BlowInCellulose, BlowInServiceTypeEnum.BlowInFiberglass,]
      ),
      new FieldConfig(
        new FieldConfigName('areaType'),
        'areaType',
        'Area Type',
        FieldType.Dropdown,
        new FieldValidator([]),
        [BlowInAreaTypeEnum.Attic, BlowInAreaTypeEnum.SubFloors, BlowInAreaTypeEnum.Walls]
      ),
      new FieldConfig(new FieldConfigName('bagsPerFullStack'), 'bagsPerFullStack', 'Bags Per Full Stack', FieldType.Number, new FieldValidator([])),
      new FieldConfig(new FieldConfigName('rValue'), 'rValue', 'R Value', FieldType.Number, new FieldValidator([])),
      new FieldConfig(new FieldConfigName('maxNetCoveragePerBagSqFt'), 'maxNetCoveragePerBagSqFt', 'Max Net Coverage Per Bag Sq Ft', FieldType.Number, new FieldValidator([])),
    ];
  }
}
