import {Injectable} from "@angular/core";
import {
  InvalidFieldsConfigJson
} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/Exception/invalid-fields-config-json";
import {
  FieldConfigSerializer
} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/Factory/field-config-serializer.service";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";

@Injectable({providedIn: 'root'})
export class FieldsConfigSerializer {
  constructor(
    private readonly fieldConfigSerializer: FieldConfigSerializer
  ) {
  }

  deserialize(json: string): FieldsConfig {
    const decoded: unknown = JSON.parse(json);
    if (!Array.isArray(decoded)) {
      throw new InvalidFieldsConfigJson(`Fields config must be an array`);
    }
    return new FieldsConfig(
      decoded.map((field) => this.fieldConfigSerializer.deserialize(field))
    );
  }

  serialize(fieldsConfig: FieldsConfig): string {
    return JSON.stringify(fieldsConfig.getFieldConfigs().map(
      (item) => this.fieldConfigSerializer.serialize(item))
    );
  }
}
