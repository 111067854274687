import {Component, Input} from '@angular/core';
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {RightToggle} from "@modules/header/Presentation/header/Model/right-toggle";
import {MenuEntry} from "../../Domain/menu-entry";

export interface TitleSegment {
  title: string;
  callback: () => void;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent {
  @Input() title: string | undefined;
  @Input() titleInfo: string | undefined;
  
  @Input() contextMenuItems: MenuEntry[] | undefined;
  @Input() rightButton: RightButton | undefined;
  @Input() rightToggle: RightToggle | undefined;
  @Input() explicitBackUrl: string[] | undefined;

  @Input() titleIcon: string | undefined;
  @Input() titleClickCallback: (() => void) | undefined;

  @Input() titleSegments: TitleSegment[] | undefined;
  @Input() segmentActive: number = 0;

  constructor() {
  }

  titleClickTrack(): void {
    if (this.titleClickCallback) {
      this.titleClickCallback();
    }
  }

  segmentChange($event: CustomEvent) {
    const segmentID = $event.detail.value;
    const segment = this.titleSegments?.find((segment, index) => index === segmentID);
    if (segment) {
      segment.callback();
    }
  }
}
