import {Component, forwardRef, Input} from '@angular/core';
import {FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {CalculationAreaSQFT} from "@modules/_shared/Component/width-length/service/sqrt-value.service";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";

@Component({
  selector: 'app-width-length',
  templateUrl: './width-length.component.html',
  styleUrls: ['./width-length.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidthLengthComponent),
      multi: true
    }
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    ClearZeroDirective
  ],
  standalone: true
})
export class WidthLengthComponent {
  @Input() public form: FormGroup = new FormGroup({});
  widthLengthForm: FormGroup;
  showWidthLength: boolean = false;

  constructor(private calculationSqrtService: CalculationAreaSQFT) {
    this.widthLengthForm = new FormGroup({
      width: new FormControl(null, [Validators.required, Validators.min(1)]),
      length: new FormControl(null, [Validators.required, Validators.min(1)]),
    });

    this.widthLengthForm.valueChanges.subscribe((values) => {
      if (values.width && values.length) {
        const sqft = this.calculationSqrtService.execute(values.width, values.length);
        this.form.patchValue({sqft}, {emitEvent: true});
      }
    });
  }

  toggleWidthLength() {
    this.showWidthLength = !this.showWidthLength;
  }
}
