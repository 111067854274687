import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {BusinessUnitLinkProvider} from "@modules/business-unit/Infrastructure/Provider/business-unit-link-provider";
import {
  CalculatorAreaLinkProvider
} from "@modules/calculation-area/Infrastructure/Provider/calculator-area-link-provider";
import {
  MiscAreaLinkProvider
} from "@modules/calculation-impl/miscellaneous-calculator/Infrastructure/Provider/misc-area-link-provider.service";
import {
  CalculatorTabLinkProvider
} from "@modules/calculation-tabs/Infrastructure/Provider/calculator-tab-link-provider";
import {
  CalculatorTotalLinkProvider
} from "@modules/calculation-total/Infrastructure/Provider/calculator-total-link-provider";
import {CalculationLinkProvider} from "@modules/calculation/Infrastructure/Provider/calculation-link-provider.service";
import {
  EstimateSummaryLinkProvider
} from "@modules/estimate-summary/Infrastructure/Provider/estimate-summary-link-provider";
import {EstimateLinkProvider} from "@modules/estimate/Infrastructure/Provider/estimate-o-link-provider.service";
import {GmInputsLinkProvider} from "@modules/gm-inputs/Infrastructure/Provider/gm-inputs-link-provider.service";
import {
  MsAuthLinkProvider
} from "@modules/microsoft/microsoft-auth/Infrastructure/Provider/msauth-link-provider.service";
import {CategoryLinkProvider} from "@modules/product/product-category/Infrastructure/Provider/category-link-provider";
import {ProductLinkProvider} from "@modules/product/product/Infrastructure/Provider/product-link-provider";
import {ProfileLinkProvider} from "@modules/profile/Infrastructure/Provider/profile-link-provider";
import {ReportingRoutesProvider} from "@modules/reporting/Application/Routes/reporting-routes-provider";
import {
  ServiceTitanEstimateLinkProvider
} from "@modules/service-titan/service-titan-estimate/Presentation/Provider/service-titan-estimate-link-provider";
import {
  JobLinkProvider
} from "@modules/service-titan/service-titan-job/Presentation/Provider/service-titan-job-link-provider.service";
import {SupportTicketLinkProvider} from "@modules/support-ticket/Infrastructure/Provider/support-ticket-link-provider";
import {
  EstimateSummaryPdfLinkProvider
} from "@modules/work-order-pdf/Infrastructure/Provider/estimate-summary-pdf-link-provider";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
    canActivate: [MsalGuard]
  },
  {
    path: MsAuthLinkProvider.BASE_URL,
    loadChildren: () => import('@modules/microsoft/microsoft-auth/microsoft-auth.module').then(m => m.MicrosoftAuthModule)
  },
  {
    path: GmInputsLinkProvider.BASE_URL,
    loadChildren: () => import('../gm-inputs/gm-inputs.module').then(m => m.GmInputsModule),
    canActivate: [MsalGuard]
  },
  {
    path: EstimateLinkProvider.BASE_URL,
    loadChildren: () => import('../estimate/estimate.module').then(m => m.EstimateModule),
    canActivate: [MsalGuard]
  },
  {
    path: CalculationLinkProvider.BASE_URL,
    loadChildren: () => import('../calculation/calculation.module').then(m => m.CalculationModule),
    canActivate: [MsalGuard]
  },
  {
    path: CalculatorTabLinkProvider.BASE_URL,
    loadChildren: () => import('../calculation-tabs/calculator-tabs.module').then(m => m.CalculatorTabsModule),
    canActivate: [MsalGuard]
  },
  {
    path: CalculatorTotalLinkProvider.BASE_URL,
    loadChildren: () => import('../calculation-total/calculation-total.module').then(m => m.CalculationTotalModule),
    canActivate: [MsalGuard]
  },
  {
    path: EstimateSummaryLinkProvider.BASE_URL,
    loadChildren: () => import('../estimate-summary/estimate-summary.module').then(m => m.EstimateSummaryModule),
    canActivate: [MsalGuard]
  },
  {
    path: EstimateSummaryPdfLinkProvider.BASE_URL,
    loadChildren: () => import('@modules/work-order-pdf/work-order-pdf.module').then(m => m.WorkOrderPdfModule),
    canActivate: [MsalGuard]
  },
  {
    path: SupportTicketLinkProvider.BASE_URL,
    loadChildren: () => import('../support-ticket/support-ticket.module').then(m => m.SupportTicketModule),
    canActivate: [MsalGuard]
  },
  {
    path: JobLinkProvider.BASE_URL,
    loadChildren: () => import('@modules/service-titan/service-titan-job/service-titan-job.module').then(m => m.ServiceTitanJobModule),
    canActivate: [MsalGuard]
  },
  {
    path: CategoryLinkProvider.BASE_URL,
    loadChildren: () => import('@modules/product/product-category/product-category.module').then(m => m.ProductCategoryModule),
    canActivate: [MsalGuard]
  },
  {
    path: ProductLinkProvider.BASE_URL,
    loadChildren: () => import('../product/product/product.module').then(m => m.ProductModule),
    canActivate: [MsalGuard]
  },
  {
    path: ProfileLinkProvider.BASE_URL,
    loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
    canActivate: [MsalGuard]
  },
  {
    path: ServiceTitanEstimateLinkProvider.BASE_URL,
    loadChildren: () => import('@modules/service-titan/service-titan-estimate/service-titan-estimate.module').then(m => m.ServiceTitanEstimateModule),
    canActivate: [MsalGuard]
  },
  {
    path: BusinessUnitLinkProvider.BASE_URL,
    loadChildren: () => import('../business-unit/business-unit.module').then(m => m.BusinessUnitModule),
    canActivate: [MsalGuard]
  },
  {
    path: CalculatorAreaLinkProvider.BASE_URL,
    loadChildren: () => import('../calculation-area/calculation-area.module').then(m => m.CalculationAreaModule),
    canActivate: [MsalGuard]
  },
  {
    path: MiscAreaLinkProvider.BASE_URL,
    loadChildren: () => import('@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator.module').then(m => m.MiscellaneousCalculatorModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'test-init-12adsujhasdkjh',
    loadChildren: () => import('../test/test.module').then(m => m.TestModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'user-interactions',
    loadChildren: () => import('@modules/user-interactions/user-interactions.module').then(m => m.UserInteractionsModule),
    canActivate: [MsalGuard]
  },
  {
    path: ReportingRoutesProvider.BASE_URL,
    loadChildren: () => import('@modules/reporting/Application/Routes/reporting.routes'),
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
