import {Injectable} from '@angular/core';
import {FoamAreaOptions} from "@modules/calculation-impl/foam/_calculator/Domain/Calculator/CalculationArea/foam-area";
import {
  AbstractTransformer
} from "@modules/product/product/Infrastructure/Migrator/MigrateExistingProductsInAreas/Transform/abstract-transformer.service";
import {FoamCategoryName} from "@modules/product/products/foam-product/Domain/Category/foam-category-name";
import {FoamProducts} from "@modules/product/products/foam-product/Domain/Product/foam-products";

@Injectable({
  providedIn: 'root'
})
export class FoamProductTransformer extends AbstractTransformer {
  async execute(area: FoamAreaOptions): Promise<FoamAreaOptions> {
    const productID = area.product as unknown;
    if (productID === undefined) return Promise.resolve(area);

    if (!productID) {
      area.product = undefined;
      return Promise.resolve(area);
    }

    const products = await this.getNewProducts<FoamProducts>(new FoamCategoryName());
    const product = products.toArray().find(
      product => product.name.getValue().startsWith(productID.toString())
    );

    if (!product) throw new Error(`Cannot find new product for old ID ${productID}`);

    area.product = product.id.getValue();
    
    return Promise.resolve(area);
  }

}
