import {Injectable} from "@angular/core";
import {CalculationAreaOptions} from "@modules/calculation-area/Domain/CalculationArea/calculation-area-options";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";
import {Products} from "@modules/product/product/Domain/Product/products";

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractTransformer {
  private newProducts: Map<string, Products> = new Map();

  constructor(
    protected readonly getProductsByCategoryNameQuery: GetProductsByCategoryNameQuery
  ) {
  }

  abstract execute(area: CalculationAreaOptions): Promise<CalculationAreaOptions>;

  protected async getNewProducts<P extends Products>(category: CategoryName): Promise<P> {
    if (this.newProducts.has(category.getValue())) {
      return this.newProducts.get(category.getValue()) as P;
    }

    const newProducts = await this.getProductsByCategoryNameQuery.execute<P>({
      categoryName: category
    });

    this.newProducts.set(category.getValue(), newProducts);
    return newProducts as P;
  }
}
