import {Injectable} from "@angular/core";
import {CalculatorTabFactory} from "@modules/calculation-tabs/Application/Factory/CalculatorTab/calculator-tab-factory";
import {
  DefaultTabNameProviderService
} from "@modules/calculation-tabs/Application/Service/default-tab-name-provider.service";
import {CalculationID} from "@modules/calculation/Domain/Calculation/VO/calculation-id";
import {CalculatorTabs} from "../../../Domain/CalculatorTab/Aggreagate/calculator-tabs";
import {CalculatorTabRepository} from "../../../Domain/CalculatorTab/Repository/calculator-tab-repository";

@Injectable({
  providedIn: 'root'
})
export class GetCalculatorTabsUseCase {
  constructor(
    private readonly calculatorTabRepository: CalculatorTabRepository,
    private readonly calculatorTabFactory: CalculatorTabFactory,
    private readonly defaultTabNameProviderService: DefaultTabNameProviderService,
  ) {
  }

  async execute(calculationID: CalculationID): Promise<CalculatorTabs> {
    const tabs = await this.calculatorTabRepository.getCalculatorTabs(calculationID);
    if (tabs.getTabs().length > 0) return tabs;

    await this.createDefaultTab(calculationID);
    return this.calculatorTabRepository.getCalculatorTabs(calculationID);
  }

  private async createDefaultTab(calculationID: CalculationID): Promise<void> {
    const defaultTabName = await this.defaultTabNameProviderService.execute(calculationID);
    const options = this.calculatorTabFactory.createNewPartial(calculationID, defaultTabName);
    await this.calculatorTabRepository.createCalculatorTab(options);
  }
}
