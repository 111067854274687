import {Injectable} from '@angular/core';
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {BusinessUnitRepository} from "@modules/business-unit/Domain/BusinessUnit/Repository/business-unit-repository";

@Injectable({
  providedIn: 'root'
})
export class AppInitBusinessUnitsProvider {
  private businessUnits?: BusinessUnits;

  constructor(
    private readonly businessUnitRepository: BusinessUnitRepository
  ) {
  }

  async init(): Promise<void> {
    this.businessUnits = await this.businessUnitRepository.getAll();
  }

  getBusinessUnits(): BusinessUnits {
    if (!this.businessUnits) throw new Error('Business units not found');
    return this.businessUnits;
  }
}
