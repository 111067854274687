import {ConstructionType} from "@modules/calculation-impl/batting/construction-type/construction-type";
import {Facing} from "@modules/calculation-impl/batting/facing/Domain/facing";
import {OnCenter} from "@modules/calculation-impl/batting/on-center/Domain/on-center";
import {RValue} from "@modules/calculation-impl/batting/r-value/Domain/r-value";
import {
  BattingInsulationProductType
} from "@modules/product/old-products/Configurations/batting-insulation-product/Application/batting-insulation-product-type";
import {Product} from "@modules/product/old-products/Domain/Product/product";
import {Price} from "@modules/product/old-products/Domain/Product/VO/price";

export interface InsulationProductOptions {
  id: string;
  mfgItem: string;
  idiSapItemNumber: string;
  rValue: RValue;
  facing: Facing;
  onCenter: OnCenter;
  widthInches: number;
  lengthInches: number;
  bagsPerPkg: number;
  bagsPerFullStack: number;
  lf26: number;
  orderPricingPerSqft: number;
  sqftPerPkg: number;
  maxWallHeight: number;
}

export class BattingInsulationProduct extends Product {
  public readonly mfgItem: string;
  public readonly idiSapItemNumber: string;
  public readonly rValue: RValue;
  public readonly facing: Facing;
  public readonly onCenter: OnCenter;
  public readonly widthInches: number;
  public readonly lengthInches: number;
  public readonly bagsPerPkg: number;
  public readonly bagsPerFullStack: number;
  public readonly lf26: number;
  public readonly sqftPerPkg: number;
  public readonly maxWallHeight: number;


  constructor(options: InsulationProductOptions) {
    if (!options.idiSapItemNumber) {
      throw new Error('idiSapItemNumber is required for BattingInsulationProduct');
    }

    if (!options.rValue) {
      throw new Error('rValue is required for BattingInsulationProduct');
    }

    if (!options.facing) {
      throw new Error('facing is required for BattingInsulationProduct');
    }

    if (!options.widthInches) {
      throw new Error('widthInches is required for BattingInsulationProduct');
    }

    if (!options.lengthInches) {
      throw new Error('lengthInches is required for BattingInsulationProduct');
    }

    const name = `${options.idiSapItemNumber}-R${options.rValue.value}-${options.facing.value}-${options.widthInches}x${options.lengthInches}`;

    super({
      id: options.id,
      productInternalID: options.idiSapItemNumber,
      originalPrice: new Price(options.orderPricingPerSqft),
      name: name,
      productType: BattingInsulationProductType
    });

    this.mfgItem = options.mfgItem;
    this.idiSapItemNumber = options.idiSapItemNumber;
    this.rValue = options.rValue;
    this.facing = options.facing;
    this.onCenter = options.onCenter;
    this.widthInches = options.widthInches;
    this.lengthInches = options.lengthInches;
    this.bagsPerPkg = options.bagsPerPkg;
    this.bagsPerFullStack = options.bagsPerFullStack;
    this.lf26 = options.lf26;
    this.sqftPerPkg = options.sqftPerPkg;
    this.maxWallHeight = options.maxWallHeight;
  }

  isApplicableForConstructionType(constructionType: ConstructionType): boolean {
    if (this.rValue.equals(new RValue('38'))) return true;

    const isEven = this.widthInches % 2 === 0;

    if (constructionType === ConstructionType.MetalFrame) {
      return isEven;
    }

    if (constructionType === ConstructionType.WoodFrame) {
      return !isEven;
    }

    return false;
  }
}
