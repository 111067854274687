import {EmptyGuidFormatError} from "@modules/_shared/Domain/GUID/empty-guid-error";
import {InvalidGuidFormatError} from "@modules/_shared/Domain/GUID/invalid-guid-error";
import {ValueObject} from "@modules/_shared/Domain/value-object";

export class Guid extends ValueObject<string> {
  protected _type!: void;

  constructor(value: string) {
    if (!value) {
      throw new EmptyGuidFormatError();
    }

    if (!value.startsWith('00000000-0000-0000-0000-') && !Guid.isValid(value)) {
      throw new InvalidGuidFormatError(value);
    }
    super(value);
  }

  static isValid(value: string): boolean {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(value);
  }
}
