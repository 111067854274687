import {Injectable} from '@angular/core';
import {UseCase} from "@modules/_shared/Domain/use-case";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {
  CategoryNotFoundException
} from "@modules/product/product-category/Domain/Category/Exception/category-not-found-exception";
import {CategoryRepository} from "@modules/product/product-category/Domain/Category/Repository/category-repository";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";

@Injectable({
  providedIn: 'root'
})
export class GetCategoryByNameQuery implements UseCase<CategoryName, Promise<Category>> {
  constructor(
    private readonly categoryRepository: CategoryRepository
  ) {
  }

  /**
   * @throws CategoryNotFoundException
   * @param name
   */
  execute(name: CategoryName): Promise<Category> {
    try {
      return this.categoryRepository.getByName(name);
    } catch (e) {
      throw new CategoryNotFoundException(name, e);
    }
  }
}
