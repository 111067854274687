import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ClearZeroDirective} from "@modules/_shared/Directive/clear-zero.directive";
import {
  MiscCalculationStrategyFactory
} from "@modules/calculation-impl/miscellaneous-calculator/Application/Factory/misc-calculation-strategy-factory.service";
import {
  CalculationAreaRoutingModule
} from "@modules/calculation-impl/miscellaneous-calculator/miscellaneous-calculator-routing.module";
import {
  CreateMiscItemComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/create-misc-item/create-misc-item.component";
import {
  MiscItemFormComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/misc-item-form/misc-item-form.component";
import {
  MiscTotalComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/misc-total/misc-total.component";
import {
  UpdateMiscItemComponent
} from "@modules/calculation-impl/miscellaneous-calculator/Presentation/Component/update-misc-item/update-misc-item.component";
import {
  AddCalculationStrategyCommand
} from "@modules/calculation-strategy/Application/UseCase/Command/add-calculation-strategy.service";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {HeaderModule} from "@modules/header/header.module";

export const MISC_CALCULATION_ID = new CalculationStrategyID('miscellaneous');

@NgModule({
  declarations: [
    MiscTotalComponent,
    CreateMiscItemComponent,
    UpdateMiscItemComponent,
    MiscItemFormComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    ClearZeroDirective,
    HeaderModule,
    CalculationAreaRoutingModule
  ]
})
export class MiscellaneousCalculatorModule {
  constructor(
    addCalculationStrategyCommand: AddCalculationStrategyCommand,
    strategyFactory: MiscCalculationStrategyFactory
  ) {
    const strategy = strategyFactory.execute();
    addCalculationStrategyCommand.execute(strategy).then();
  }
}
