import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {DynamicFormField} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-field";
import {
  DynamicFormFieldDropdown
} from "@modules/dynamic-form/Domain/DynamicFormField/Fields/dynamic-form-field-dropdown";

@Component({
  selector: 'app-dynamic-form-fields-dropdown',
  templateUrl: './dynamic-form-fields-dropdown.component.html',
  styleUrls: ['./dynamic-form-fields-dropdown.component.scss'],
  imports: [
    ReactiveFormsModule,
    IonicModule
  ],
  standalone: true
})
export class DynamicFormFieldsDropdownComponent {
  @Input({required: true}) formGroup?: FormGroup;
  @Input({
    required: true,
    transform: (value: DynamicFormField<ControlTypes>): DynamicFormFieldDropdown | undefined => {
      return value ? value as DynamicFormFieldDropdown : undefined;
    }
  }) field?: DynamicFormFieldDropdown;
}
