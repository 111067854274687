import {Injectable} from '@angular/core';
import {
  ProductCreationStrategy
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-strategy";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {FoamProduct} from "@modules/product/products/foam-product/Domain/Product/foam-product";
import {FoamProducts} from "@modules/product/products/foam-product/Domain/Product/foam-products";

@Injectable({
  providedIn: 'root'
})
export class FoamProductCreationStrategy extends ProductCreationStrategy<FoamProducts, FoamProduct> {
  createProduct(product: Product): FoamProduct {
    return FoamProduct.fromProduct(product);
  }

  createProducts(products: Products): FoamProducts {
    return new FoamProducts(
      products.toArray().map(product => this.createProduct(product))
    );
  }
}
