import {Injectable} from "@angular/core";
import {CalculatorTab} from "@modules/calculation-tabs/Domain/CalculatorTab/calculator-tab";
import {CalculatorTabRepository} from "../../../Domain/CalculatorTab/Repository/calculator-tab-repository";

@Injectable({
  providedIn: 'root'
})
export class GetCalculatorTabUseCase {
  constructor(
    private readonly calculatorTabRepository: CalculatorTabRepository
  ) {
  }

  execute(tabID: string): Promise<CalculatorTab> {
    return this.calculatorTabRepository.getCalculatorTab(tabID);
  }

}
