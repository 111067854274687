import {Injectable} from '@angular/core';
import {NotificationAction} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action";
import {Notification} from "@modules/area-context-notifications/Domain/Notification/notification";
import {Notifications} from "@modules/area-context-notifications/Domain/Notification/notifications";
import {
  NotificationRepository
} from "@modules/area-context-notifications/Domain/Notification/Repository/notification-repository";
import {FOAM_CALCULATION_ID} from "@modules/calculation-impl/foam/foam-calculation.module";
import {NotificationID} from "@modules/user-interactions/Domain/UserInteractionEvent/VO/notification-id";

@Injectable({
  providedIn: 'root'
})
export class StaticNotificationRepository extends NotificationRepository {
  getNotifications(): Promise<Notifications> {
    return Promise.resolve(
      new Notifications([
        new Notification(
          new NotificationID('attic-roofline-combustion-appliances'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /Attic Roofline/i,
          },
          new NotificationAction(
            'Are there any open combustion appliances?',
            'Not recommended to spray roofline.',
            2
          ),
        ),
        new Notification(
          new NotificationID('attic-roofline-hot-pipes'),
          {
            calculation: FOAM_CALCULATION_ID,
            area: /attic/i
          },
          new NotificationAction(
            'Are there hot pipes or chimneys present?',
            'Ensure to include mineral wool wrap.',
            1
          )
        )
      ])
    );
  }
}
