@if (totalModel) {
  <ion-list>
    <ion-item>
      <ion-label>Removal Labor Hours</ion-label>
      <ion-note color="primary" slot="end">{{ totalModel.laborHours | number }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Removal Labor Cost</ion-label>
      <ion-note color="primary" slot="end">{{ totalModel.laborCost | currency }}</ion-note>
    </ion-item>
    <ion-item class="ion-padding-top">
      <ion-label><b>Removal Total Cost</b></ion-label>
      <ion-note color="primary" slot="end"><b>{{ totalModel.totalCost | currency }}</b></ion-note>
    </ion-item>
  </ion-list>
}
