import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  UserInteractionTestComponentComponent
} from "@modules/user-interactions/Presenation/Component/user-interaction-test-component/user-interaction-test-component.component";

const routes: Routes = [
  {
    path: '',
    component: UserInteractionTestComponentComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class UserInteractionsRoutingModule {
}
