@if (totalModel) {
  <ion-list>
    <ion-item>
      <ion-label>Material Cost</ion-label>
      <ion-note color="primary" slot="end">{{ totalModel.materialCost | currency }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Labor Hours</ion-label>
      <ion-note color="primary" slot="end">{{ totalModel.laborHours | number :'1.0-2' }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Labor Cost</ion-label>
      <ion-note color="primary" slot="end">{{ totalModel.laborCost | currency }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Miscellaneous</ion-label>
      <ion-note color="primary" slot="end">{{ totalModel.misc | currency }}</ion-note>
    </ion-item>
    <ion-item class="ion-padding-top">
      <ion-label><b>Total Cost</b></ion-label>
      <ion-note color="primary" slot="end"><b>{{ totalModel.totalCost | currency }}</b></ion-note>
    </ion-item>
  </ion-list>
}
