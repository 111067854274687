@if (formGroup && field) {
  <ion-item [formGroup]="formGroup">
    <ion-input
      appClearZero
      [formControlName]="field.key"
      label-placement="floating"
      type="number"
      step="any"
      inputMode="decimal"
      [errorText]="field.errorMessage"
      [label]="field.label"
      [placeholder]="field.placeholder"
    ></ion-input>
  </ion-item>
}
