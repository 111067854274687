import {ValidationError} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/validation-result";

export class ProductCustomFieldsValidationException extends Error {
  public readonly errors: ValidationError[];

  constructor(errors: ValidationError[]) {
    super(`Product custom fields validation failed: ${errors.map(error => error.message).join(', ')}`);

    this.errors = errors;
    this.name = 'ProductCustomFieldsValidationException';
  }
}
