import {Injectable} from '@angular/core';
import {
  ProductCreationStrategy
} from "@modules/product/product/Application/Factory/ProductCreationManager/product-creation-strategy";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {
  BattingMineralWoolProduct
} from "@modules/product/products/batting-mineral-wool-product/Domain/Product/batting-mineral-wool-product";
import {
  BattingMineralWoolProducts
} from "@modules/product/products/batting-mineral-wool-product/Domain/Product/batting-mineral-wool-products";

@Injectable({
  providedIn: 'root'
})
export class BattingMineralWoolProductCreationStrategy extends ProductCreationStrategy<BattingMineralWoolProducts, BattingMineralWoolProduct> {
  createProduct(product: Product): BattingMineralWoolProduct {
    return BattingMineralWoolProduct.fromProduct(product);
  }

  createProducts(products: Products): BattingMineralWoolProducts {
    return new BattingMineralWoolProducts(
      products.toArray().map(product => this.createProduct(product))
    );
  }
}
