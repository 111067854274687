import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {Mapper} from "@modules/_shared/Domain/mapper";
import {Request} from "@modules/service-titan/core/Domain/Request/request";
import {Response} from "@modules/service-titan/core/Domain/Response/response";
import {ServiceTitanClient} from "@modules/service-titan/core/Domain/service-titan-client";
import {
  AuthHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/auth-headers-provider.service";
import {
  BusinessUnitHeadersProvider
} from "@modules/service-titan/core/Infrastructure/ProxyClient/Provider/business-unit-headers-provider.service";
import {HttpService} from "@modules/service-titan/core/Infrastructure/ProxyClient/Service/http.service";

export class ServiceTitanProxyClient extends ServiceTitanClient {
  constructor(
    private readonly proxyBaseURL: string,
    private readonly authHeadersProvider: AuthHeadersProvider,
    private readonly businessUnitHeadersProvider: BusinessUnitHeadersProvider,
    private readonly httpService: HttpService
  ) {
    super();
  }

  async callMultiple<I, O>(request: Request, factory: Mapper<I, O>): Promise<O[]> {
    if (request.queryParameters['ids'] && request.queryParameters['ids'].split(',').length > 50) {
      return this.processBatches<I, O>(request, factory);
    }

    return this.processPaginated<I, O>(request, factory);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  async callSingle<I = any, O = any>(request: Request, factory?: Mapper<I, O>): Promise<O> {
    const response = await this.call<O>(request);
    if (!factory) {
      return response as O;
    }
    return factory.mapFrom(response as I);
  }

  private splitIntoChunks(array: string[], chunkSize: number): string[][] {
    const chunks: string[][] = [];
    while (array.length > 0) {
      chunks.push(array.splice(0, chunkSize));
    }
    return chunks;
  }

  private async processBatches<I, O>(request: Request, factory: Mapper<I, O>): Promise<O[]> {
    const IDs = request.queryParameters['ids'].split(',');
    const spliced = this.splitIntoChunks(IDs, 50);

    const requests = [];

    for (const batch of spliced) {
      const batchRequest: Request = new Request(
        request.endpoint,
        request.method,
        request.parameters,
        {
          ...request.queryParameters,
          ids: batch.join(',')
        }
      );

      requests.push(
        this.call<Response<I>>(batchRequest)
      );
    }

    const responses = await Promise.all(requests);
    const items = responses.map(response => response.data).flat();
    return items.map(factory.mapFrom);
  }

  private async processPaginated<I, O>(request: Request, factory: Mapper<I, O>): Promise<O[]> {
    let data: O[] = [];
    let currentParameters = request;

    let responseHasMore = true;
    while (responseHasMore) {
      const response = await this.call<Response<I>>(currentParameters);
      data = data.concat(response.data.map(factory.mapFrom));

      responseHasMore = response.hasMore;
      currentParameters = currentParameters.nextPage();
    }

    return data;
  }

  private async call<I>(request: Request): Promise<I> {
    const [tokenHeaders, businessUnitHeaders] = await Promise.all([
      this.authHeadersProvider.execute(),
      this.businessUnitHeadersProvider.execute(),
    ]);

    const headers = new HttpHeaders({
      ...tokenHeaders,
      ...businessUnitHeaders,
      ...{
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    const url = this.constructUrl(request.queryParameters);
    try {
      return await this.httpService.post<I>(url, request, headers);
    } catch (error) {
      this.handleError(error);
    }
  }

  private constructUrl(queryParameters?: Record<string, string>): string {
    const url = new URL(this.proxyBaseURL);
    if (queryParameters) {
      const params = new URLSearchParams(queryParameters);
      url.search = params.toString();
    }
    return url.toString();
  }

  private handleError(error: unknown): never {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0:
        case 404:
          throw new Error(
            error.error.title ? `Service Titan: ${error.error.title}` : 'Service Titan Proxy not found'
          );
        default:
          throw error
      }
    }
    throw new Error('An unexpected error occurred');
  }
}
