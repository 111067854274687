<ion-header>
  @if (actionable) {
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="cancel()">No</ion-button>
      </ion-buttons>
      <ion-title></ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="confirm()">Yes</ion-button>
      </ion-buttons>
    </ion-toolbar>
  } @else {
    <ion-toolbar>
      <ion-buttons slot="start"></ion-buttons>
      <ion-title></ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="confirm()">Confirm</ion-button>
      </ion-buttons>
    </ion-toolbar>
  }
</ion-header>


<ion-content class="ion-padding ion-text-center">
  @if (message) {
    <ion-text>
      <h3>{{ message }}</h3>
    </ion-text>
  }
  @if (showOptOut) {
    <ion-button (click)="optOut()" color="warning">
      <ion-icon slot="end" name="person-remove-outline"></ion-icon>
      Don't show again
    </ion-button>
  }
</ion-content>
