import {Component, Input} from '@angular/core';
import {TotalComponent} from "@modules/calculation-total/Domain/CalculatorTotalTabsComponent/total-component";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";


@Component({
    selector: 'app-foam-total-tabs',
    templateUrl: './foam-total.component.html',
    styleUrls: ['./foam-total.component.scss'],
    standalone: false
})
export class FoamTotalComponent implements TotalComponent {
  @Input() totalModel: CalculationTotal | undefined;
  title: string = 'Foam Total';
}
