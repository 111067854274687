import {Injectable} from '@angular/core';
import {
  GetActiveAccountQuery
} from "@modules/microsoft/microsoft-auth/Application/UseCase/Query/get-active-account-query.service";
import {Profile} from "@modules/profile/Domain/Profile/profile";
import {ProfileRepository} from "@modules/profile/Domain/Profile/Repository/profile-repository";

@Injectable({
  providedIn: 'root'
})
export class AppInitUserProfileProvider {
  private userProfile?: Profile;

  constructor(
    private readonly profileRepository: ProfileRepository,
    private readonly getActiveAccountQueryService: GetActiveAccountQuery,
  ) {
  }

  async init(): Promise<void> {
    this.userProfile = await this.getProfile();
  }

  getUserProfile(): Profile {
    if (!this.userProfile) throw new Error('Profile not found');
    return this.userProfile;
  }

  private async getProfile(): Promise<Profile> {
    const account = await this.getActiveAccountQueryService.execute();
    if (!account) throw new Error('Account not found');

    const userID = account.idTokenClaims?.oid;
    if (!userID) throw new Error('User ID not found');
    return await this.profileRepository.getFromAzureUserID(userID);
  }
}
