import {Injectable} from '@angular/core';
import {
  EstimateServiceCode
} from "@modules/service-titan/service-titan-estimate/Domain/EstimateService/VO/estimate-service-code";
import {MaterialMatcher} from "@modules/service-titan/service-titan-estimate/Domain/Material/material-matcher";

@Injectable({
  providedIn: 'root'
})
export class EstimateMaterialMatchersProvider {
  getMiscMatcher(): Promise<MaterialMatcher> {
    return Promise.resolve({
      code: new EstimateServiceCode('Misc. Cost')
    });
  }

  getMaterialMatcher(): Promise<MaterialMatcher> {
    return Promise.resolve({
      code: new EstimateServiceCode('Materials Cost')
    });
  }

  getLaborMatcher(): Promise<MaterialMatcher> {
    return Promise.resolve({
      code: new EstimateServiceCode('Labor Cost')
    });
  }
}
