import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {ControlTypeMap, DynamicFormField} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-field";

export class DynamicFormFields {
  constructor(
    private readonly fields: DynamicFormField<ControlTypes>[]
  ) {
  }

  getFields(): DynamicFormField<ControlTypes>[] {
    return [...this.fields];
  }

  setValues(rawValue: Record<string, unknown>): DynamicFormFields {
    if (!rawValue) return this;

    const newFields = this.fields.map(field => {
      const newValue: ControlTypes = rawValue[field.key] as ControlTypes;
      return field.setValue(newValue);
    });

    return new DynamicFormFields(newFields);
  }

  getValue<T extends ControlTypes>(key: string): ControlTypeMap[T] {
    const field = this.fields.find(field => field.key === key);
    if (!field) throw new Error(`Field ${key} not found`);
    return field.getValue<T>();
  }

  disableAllExcept(enableFieldKey: string): DynamicFormFields {
    const newFields = this.fields
      .map(field => {
        if (field.key === enableFieldKey) return field;
        return field.disable();
      });

    return new DynamicFormFields(newFields);

  }

  isAnyFieldDisabled(): boolean {
    return this.fields.some(field => field.disabled);
  }
}
