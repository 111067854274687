import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {Price} from "@modules/product/old-products/Domain/Product/VO/price";

interface ProductOverriddenPriceOptions {
  id?: string;
  productID: string;
  type: ProductType;
  price: Price;
}

export class ProductOverriddenPrice {
  public readonly id?: string;
  public readonly productID: string;
  public readonly type: ProductType;
  public readonly price: Price;

  constructor(options: ProductOverriddenPriceOptions) {
    if (!options.productID) {
      throw new Error('ProductPrice must have a productID');
    }

    if (!options.type) {
      throw new Error('ProductPrice must have a type');
    }

    this.id = options.id;
    this.type = options.type;
    this.price = options.price;
    this.productID = options.productID;
  }

  public setPrice(price: Price): ProductOverriddenPrice {
    return new ProductOverriddenPrice({
      id: this.id,
      productID: this.productID,
      type: this.type,
      price: price
    });
  }
}
