import {Inject, Injectable} from '@angular/core';
import {RootBusinessUnit} from "@modules/business-unit/Application/DI/root-business-unit";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {ProductNotFoundException} from "@modules/product/product/Domain/Product/Exception/product-not-found-exception";
import {Product} from "@modules/product/product/Domain/Product/product";
import {ProductRepository} from "@modules/product/product/Domain/Product/Repository/product-repository";
import {GetCurrentUserProfileUseCase} from "@modules/profile/Application/UseCase/get-current-user-profile-use-case";

@Injectable({
  providedIn: 'root'
})
export class ZeeUpdateProductStrategy {
  constructor(
    private readonly productRepository: ProductRepository,
    private readonly getCurrentUserProfileUseCase: GetCurrentUserProfileUseCase,
    @Inject(RootBusinessUnit) private readonly rootBusinessUnit: string
  ) {
  }


  async execute(product: Product, userBusinessUnitID: BusinessUnitID): Promise<Product> {
    const profile = await this.getCurrentUserProfileUseCase.execute();
    const userIsCorporate = profile.businessUnitID.equalsValue(this.rootBusinessUnit);

    // We allow corporate users to update any product
    if (userIsCorporate) {
      return this.productRepository.update(product);
    }

    // If the user is a Zee, we need to check if the product is a corporate product
    if (product.isCorporateProductOrCopy) {
      return this.updateCorporateProduct(product, userBusinessUnitID);
    }

    // If the user is a Zee and the product is not a corporate product, we can update it directly
    return this.productRepository.update(product);
  }


  private async getExistingZeeProduct(product: Product, userBusinessUnitID: BusinessUnitID): Promise<Product> {
    try {
      return await this.productRepository.findZeeProduct(product.id, userBusinessUnitID);
    } catch (error) {
      if (!(error instanceof ProductNotFoundException)) {
        throw error;
      }
    }

    const newZeeProduct = product.createZeeCopy(userBusinessUnitID);
    return this.productRepository.create(newZeeProduct);
  }

  private async updateCorporateProduct(product: Product, userBusinessUnitID: BusinessUnitID): Promise<Product> {
    const existingProduct = await this.getExistingZeeProduct(product, userBusinessUnitID);
    const updatedExistingProduct = existingProduct.setPrice(product.price.getValue());

    return this.productRepository.updatePrice(updatedExistingProduct);
  }
}
