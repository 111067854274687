import {Injectable} from '@angular/core';
import {ProductType} from "@modules/product/old-products/Application/Configuration/product-type";
import {
  BattingInsulationProductType
} from "@modules/product/old-products/Configurations/batting-insulation-product/Application/batting-insulation-product-type";
import {
  BattingInsulationProduct
} from "@modules/product/old-products/Configurations/batting-insulation-product/Domain/batting-insulation-product";
import {
  BattingInsulationProducts
} from "@modules/product/old-products/Configurations/batting-insulation-product/Domain/batting-insulation-products";
import {Fields} from "@modules/product/product-category-fields/Domain/Field/Field/fields";
import {FieldDropdown} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-dropdown";
import {FieldName} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-name";
import {FieldNumber} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field-number";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {
  ProductProvider
} from "@modules/product/product/Infrastructure/Migrator/MigrateProducts/provider/product-provider.service";
import {
  BattingInsulationCategoryName
} from "@modules/product/products/batting-insulation-product/Domain/Category/batting-insulation-category-name";

@Injectable({
  providedIn: 'root'
})
export class BattingInsulationProvider extends ProductProvider<BattingInsulationProducts, BattingInsulationProduct> {
  protected getFields(oldProduct: BattingInsulationProduct): Fields {
    return new Fields([
      new FieldDropdown(oldProduct.rValue.value, new FieldName('rValue')),
      new FieldDropdown(oldProduct.facing.value, new FieldName('facing')),
      new FieldDropdown(oldProduct.onCenter.value?.toString() ?? ' ', new FieldName('onCenter')),
      new FieldNumber(Number(oldProduct.widthInches), new FieldName('widthInches')),
      new FieldNumber(Number(oldProduct.bagsPerPkg), new FieldName('bagsPerPkg')),
      new FieldNumber(Number(oldProduct.bagsPerFullStack), new FieldName('bagsPerFullStack')),
      new FieldNumber(Number(oldProduct.sqftPerPkg), new FieldName('sqftPerPkg')),
    ]);
  }

  protected getManufacturer(): string {
    return 'Owens Corning';
  }

  protected getProductType(): ProductType {
    return BattingInsulationProductType;
  }

  protected override getCategoryName(): CategoryName {
    return new BattingInsulationCategoryName();
  }
}
