import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {ControlTypes} from "@modules/dynamic-form/Domain/DynamicFormField/control-types";
import {DynamicFormFields} from "@modules/dynamic-form/Domain/DynamicFormField/dynamic-form-fields";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {
  GetCategoryByIDQuery
} from "@modules/product/product-category/Application/UseCase/Query/GetCategoryByID/get-category-by-i-d-query.service";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {
  CreateProductCommand
} from "@modules/product/product/Application/UseCase/Command/CreateProduct/create-product-command.service";
import {ProductBusinessOwnerID} from "@modules/product/product/Domain/Product/VO/product-business-owner-id";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {ProductLinkProvider} from "@modules/product/product/Infrastructure/Provider/product-link-provider";
import {
  ProductFromDffFactory
} from "@modules/product/product/Presentation/Service/ProductFromDffFactory/product-from-dff-factory.service";
import {
  CategoryToProductDFFTransformer
} from "@modules/product/product/Presentation/Service/ProductToDffTransformer/category-to-product-d-f-f-transformer.service";
import {
  UserBusinessUnitProvider
} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/user-business-unit-provider";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-product-creator',
  templateUrl: './product-creator.component.html',
  styleUrls: ['./product-creator.component.scss'],
  standalone: false
})
export class ProductCreatorComponent implements OnInit {
  @Input({required: true}) categoryID?: string;
  protected formGroup?: FormGroup;
  protected title = 'Create Product';
  protected rightButton: RightButton = {
    title: 'Create',
    callback: () => this.onCreateProductClick(),
  }
  protected isLoading = false;
  protected dynamicFormFields?: DynamicFormFields;
  private category?: Category;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly getCategoryByIDQuery: GetCategoryByIDQuery,
    private readonly createProductCommand: CreateProductCommand,
    private readonly productFromDffFactory: ProductFromDffFactory,
    private readonly categoryToProductDFFTransformer: CategoryToProductDFFTransformer,
    private readonly loadingService: LoadingService,
    private readonly businessUnitProvider: UserBusinessUnitProvider,
    private readonly router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.isLoading = loading);

    await this.loadingService.execute({executeCb: () => this.loadCategory()});
  }

  getBackLink() {
    if (!this.category) return [];

    return ProductLinkProvider.getCategoryProductListRoute(this.category.id);
  }

  onFieldsChanged(fields: DynamicFormFields) {
    this.dynamicFormFields = fields;
    const name = fields.getValue<ControlTypes.String>('name');
    if (name) this.title = name;
  }

  private async onCreateProductClick() {
    return await this.loadingService.execute({executeCb: this.saveProduct.bind(this)});
  }

  private async loadCategory() {
    if (!this.categoryID) throw new Error('Category ID is required');

    const categoryID = new CategoryID(this.categoryID);
    this.category = await this.getCategoryByIDQuery.execute(categoryID);
    this.title = `Create ${this.category.title} Product`;
    this.createDynamicFields();
  }

  private createDynamicFields(): void {
    if (!this.category) throw new Error('Category is required');
    this.dynamicFormFields = this.categoryToProductDFFTransformer.execute(this.category);
  }

  private async saveProduct(): Promise<void> {
    if (!this.category) throw new Error('Category not loaded');
    if (!this.dynamicFormFields) throw new Error('Dynamic form fields not loaded');

    const userBusinessUnit = await this.businessUnitProvider.getUserBusinessUnit();

    const newProduct = this.productFromDffFactory.execute(new ProductID('00000000-0000-0000-0000-000000000000'), this.category, this.dynamicFormFields, new ProductBusinessOwnerID(userBusinessUnit.id.getValue()));

    await this.createProductCommand.execute(newProduct);
    await this.notificationService.execute('Product created');
    await this.router.navigate(ProductLinkProvider.getCategoryProductListRoute(this.category.id));
  }
}
