import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {
  FieldValidationRule
} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validation-rule";
import {ValidationResult} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/validation-result";

export class FieldValidator {

  constructor(public readonly rules: FieldValidationRule[]) {
  }

  public validate(field: Field): ValidationResult {
    return ValidationResult.combine(
      ...this.rules.map(
        rule => rule.validate(field)
      )
    );
  }

  isRuleExists(rule: FieldValidationRule): boolean {
    return this.rules.some(r => (r as unknown) instanceof rule.constructor);
  }
}
