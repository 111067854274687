import {Injectable} from '@angular/core';
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  DynamicsDeleteRecordCommand
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Command/dynamics-delete-record-command.service";
import {
  DynamicsRetrieveMultipleQuery
} from "@modules/microsoft/microsoft-dynamics/Application/UseCase/Query/dynamics-retrieve-multiple-query.service";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {
  CreateCategoryCommand
} from "@modules/product/product-category/Application/UseCase/Command/CreateCategory/create-category-command.service";
import {
  GetCategoryByNameQuery
} from "@modules/product/product-category/Application/UseCase/Query/GetCategoryByName/get-category-by-name-query.service";
import {Category} from "@modules/product/product-category/Domain/Category/category";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {DeleteRequest, DynamicsWebApi, RetrieveMultipleRequest} from "dynamics-web-api";

export interface CategoryInit {
  name: CategoryName
  title: string
  description: string
  fields: FieldsConfig
}

@Injectable({
  providedIn: 'root'
})
export class CategoriesInitService {
  constructor(
    private readonly createCategoryCommand: CreateCategoryCommand,
    private readonly getCategoryByNameQueryService: GetCategoryByNameQuery,
    private readonly retrieveMultipleQuery: DynamicsRetrieveMultipleQuery,
    private readonly deleteRecordCommand: DynamicsDeleteRecordCommand,
    private readonly dynamicsWebApi: DynamicsWebApi,
    private readonly notificationService: NotificationService
  ) {
  }

  async execute(categories: CategoryInit[]) {
    await this.clearCategories();
    await Promise.all(categories.map(
      categoryToInit => this.initCategory(categoryToInit)
    ));
    this.notificationService.execute('Categories initialized').then();
  }

  private async initCategory(categoryToInit: CategoryInit): Promise<void> {
    try {
      await this.getCategoryByNameQueryService.execute(categoryToInit.name);
      this.notificationService.execute(`Category ${categoryToInit.name} already exists`).then();
      return;
    } catch {
      this.notificationService.execute(`Creating category ${categoryToInit.name}`).then();
    }

    const newCategory = this.buildCategory(categoryToInit);
    await this.createCategoryCommand.execute(newCategory);
  }

  private buildCategory(categoryToInit: CategoryInit) {
    return new Category(
      new CategoryID('00000000-0000-0000-0000-000000000000'),
      categoryToInit.name,
      categoryToInit.title,
      categoryToInit.description,
      categoryToInit.fields
    );
  }

  private async clearCategories() {
    const request: RetrieveMultipleRequest = {
      collection: 'cr9b4_categories',
      select: ['cr9b4_categoryid']
    }

    const response = await this.retrieveMultipleQuery.execute(request, undefined, false);
    const ids = response.value.map(category => category.cr9b4_categoryid);

    this.notificationService.execute(`Deleting ${ids.length} categories`).then();
    if (ids.length === 0) return;

    this.dynamicsWebApi.startBatch();

    ids.forEach(id => {

      const request: DeleteRequest = {
        collection: 'cr9b4_categories',
        key: id
      }
      this.deleteRecordCommand.execute(request);
    });

    await this.dynamicsWebApi.executeBatch();

    this.notificationService.execute(`Deleted ${ids.length} categories`).then();

  }
}
