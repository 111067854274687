import {Injectable} from '@angular/core';
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {
  UserBusinessUnitProvider
} from "@modules/service-titan/core/Infrastructure/BusinessUnitProvider/user-business-unit-provider";

@Injectable({
  providedIn: 'root'
})
export class AdminSelectorBusinessUnitProvider extends UserBusinessUnitProvider {
  constructor(
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit
  ) {
    super();
  }

  async getUserBusinessUnit(): Promise<BusinessUnit> {
    const businessUnit = await this.userSelectedBusinessUnit.getSelectedBusinessUnitPromise();
    if (businessUnit === undefined) {
      throw new Error('Business Unit not selected');
    }

    return businessUnit;
  }
}
