<ion-content>
  <app-header [explicitBackUrl]="['/']"></app-header>
  @if (loading) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }

  @if (countInteractions) {
    <p>
      Interactions Count: {{ countInteractions }}
    </p>
  }


  <ion-button (click)="addInteraction()" [disabled]="loading">Add one more</ion-button>
  <ion-button (click)="loadInteractionsCount()" [disabled]="loading">Load Count</ion-button>

</ion-content>
