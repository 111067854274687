import {Injectable} from "@angular/core";
import {FieldValidator} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validator";
import {
  RequiredFieldValidatorRule
} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/Rules/required-field-validator-rule";

@Injectable({providedIn: 'root'})
export class FieldsValidatorSerializer {
  // eslint-disable-next-line
  deserialize(input: string): FieldValidator {
    return new FieldValidator([
      new RequiredFieldValidatorRule()
    ]);
  }

  // eslint-disable-next-line
  serialize(validator: FieldValidator): string {
    return '{}';
  }
}
