import {BlowInNetProduct} from "@modules/product/old-products/Configurations/blow-in-net-product/Domain/blow-in-net-product";
import {BlowInNetProducts} from "@modules/product/old-products/Configurations/blow-in-net-product/Domain/blow-in-net-products";
import {
  BlowInNetProductDynamicsProductsFactory
} from "@modules/product/old-products/Configurations/blow-in-net-product/Infrastructure/Factory/dynamics-insulation-dynamics-products-factory.service";
import {DynamicsProductConfiguration} from "@modules/product/old-products/Infrastructure/dynamics-product-configuration";

export const BlowInNetDynamicsProductConfiguration: DynamicsProductConfiguration<BlowInNetProducts, BlowInNetProduct> = {
  productsTable: 'cr9b4_ifoamicalcproductsblowinnets',
  columns: [
    'cr9b4_ifoamicalcproductsblowinnetid',
    'cr9b4_netproduct',
    'cr9b4_linearyieldft',
    'cr9b4_priceper',
  ],
  productsFactory: new BlowInNetProductDynamicsProductsFactory()
}
