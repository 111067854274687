import {NgModule} from '@angular/core';
import {
  NotificationActionProcessor
} from "@modules/area-context-notifications/Domain/Notification/Action/notification-action-processor";
import {
  NotificationRepository
} from "@modules/area-context-notifications/Domain/Notification/Repository/notification-repository";
import {
  UserNotificationStatusRepository
} from "@modules/area-context-notifications/Domain/UserNotificationStatus/Repository/user-notification-status-repository";
import {
  StaticNotificationRepository
} from "@modules/area-context-notifications/Infrasturcutre/Repository/static-notification-config-provider.service";
import {
  DynamicsUserNotificationStatusRepository
} from "@modules/area-context-notifications/Infrasturcutre/Repository/UserNotificationStatus/dynamics-user-notification-status-repository";

import {
  DisplayModalNotificationActionProcessor
} from "@modules/area-context-notifications/Presentation/Service/display-modal-notification-action-processor.service";


@NgModule({
  declarations: [],
  imports: [],
  providers: [
    // Provides the implementation for processing notifications
    {provide: NotificationActionProcessor, useExisting: DisplayModalNotificationActionProcessor},
    // Provides the static configuration for notifications
    {provide: NotificationRepository, useExisting: StaticNotificationRepository},
    {provide: UserNotificationStatusRepository, useClass: DynamicsUserNotificationStatusRepository}
  ],
  exports: []
})
export class AreaContextNotificationsModule {
}
