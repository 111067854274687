import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {FieldConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/field-config";
import {FieldsConfig} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/fields-config";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {FieldValidator} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validator";

export class BlowInNetCategoryFieldsConfig extends FieldsConfig {
  constructor() {
    super(BlowInNetCategoryFieldsConfig.getFields());
  }

  private static getFields(): FieldConfig[] {
    return [
      new FieldConfig(new FieldConfigName('linearYieldFt'), 'Linear Yield Ft', 'Linear Yield Ft', FieldType.Number, new FieldValidator([]))
    ];
  }
}
