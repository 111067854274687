import {ValueObject} from "@modules/_shared/Domain/value-object";
import {ProductFieldEmpty} from "@modules/product/product/Domain/Product/Exception/product-field-invalid-exception";

export class ProductName extends ValueObject<string> {
  protected _type!: void;

  constructor(value: string) {
    if (!value || value.trim().length === 0) throw new ProductFieldEmpty('Name');
    super(value);
  }
}
