import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {LoadingService} from "@modules/_shared/Service/LoadingService/loading.service";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";
import {MiscProduct} from "@modules/product/products/misc-product/Domain/Product/misc-product";
import {MiscProducts} from "@modules/product/products/misc-product/Domain/Product/misc-products";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-misc-item-form',
  templateUrl: './misc-item-form.component.html',
  styleUrls: ['./misc-item-form.component.scss'],
  standalone: false
})
export class MiscItemFormComponent implements OnInit {
  @Input({required: true}) form: FormGroup = new FormGroup({});
  miscProducts?: MiscProducts;
  filteredProducts: MiscProduct[] = [];
  protected loading: boolean = false;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly getProductsByCategoryNameQuery: GetProductsByCategoryNameQuery
  ) {
  }

  async ngOnInit() {
    this.loadingService.getLoading().pipe(untilDestroyed(this)).subscribe(loading => this.loading = loading);

    return this.loadingService.execute({
      executeCb: () => this.loadProducts(),
      finallyCb: () => this.handleProductSelection()
    })
  }

  handleInput(event: Event) {
    if (!this.miscProducts) {
      this.filteredProducts = [];
      return;
    }

    this.deselectProduct();

    if (!event) return;
    const target = event.target as HTMLInputElement;
    const query = target.value;
    if (!query) {
      this.filteredProducts = this.miscProducts.toArray();
      return;
    }

    this.filteredProducts = this.miscProducts.filterBySearchTerm(query).toArray();
  }

  selectProduct(product: MiscProduct) {
    this.form.get('productID')?.setValue(product.id.getValue());

    const name = this.form.get('name');
    name?.setValue(product.name.getValue());
    name?.disable();

    const value = this.form.get('value')
    value?.setValue(product.price.getValue());
    value?.disable();


  }

  deselectProduct() {
    this.form.get('productID')?.setValue(undefined);
    this.form.get('value')?.enable();
    this.form.get('name')?.enable();
  }

  isProductIDSelected(productID: string): boolean {
    const productIDValue = this.form.get('productID')?.value;
    if (!productIDValue) return false;

    return productIDValue === productID;
  }

  clearSelection() {
    this.deselectProduct();
  }

  private async loadProducts() {
    this.miscProducts = await this.getProductsByCategoryNameQuery.execute<MiscProducts>({
      categoryName: new MiscCategoryName()
    })
    this.filteredProducts = this.miscProducts.toArray();
  }

  private handleProductSelection() {
    if (!this.form || !this.miscProducts) return;
    const productID = this.form.get('productID')?.value;
    if (!productID) return;

    const product = this.miscProducts.getByID(new ProductID(productID));
    if (!product) throw new Error(`Product with ID ${productID} not found`);

    this.selectProduct(product);
  }
}
