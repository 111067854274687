import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {IonicModule} from "@ionic/angular";
import {HeaderModule} from "@modules/header/header.module";
import {
  RecordUserInteractionCommand
} from "@modules/user-interactions/Application/UseCase/Command/RecordInteraction/record-user-interaction-command.service";
import {
  GetUserInteractionsCountQuery
} from "@modules/user-interactions/Application/UseCase/Query/GetInteractions/get-user-interactions-count-query.service";
import {
  UserInteractionEventRepository
} from "@modules/user-interactions/Domain/UserInteractionEvent/Repository/user-interaction-event-repository";
import {
  DynamicsUserInteractionEventRepositoryService
} from "@modules/user-interactions/Infrastructure/Repository/dynamics-user-interaction-event-repository.service";
import {
  UserInteractionTestComponentComponent
} from "@modules/user-interactions/Presenation/Component/user-interaction-test-component/user-interaction-test-component.component";
import {UserInteractionsRoutingModule} from "@modules/user-interactions/user-interactions-routing.module";


@NgModule({
  declarations: [
    UserInteractionTestComponentComponent
  ],
  providers: [
    {provide: UserInteractionEventRepository, useExisting: DynamicsUserInteractionEventRepositoryService},
    RecordUserInteractionCommand,
    GetUserInteractionsCountQuery
  ],
  imports: [
    CommonModule,
    UserInteractionsRoutingModule,
    IonicModule,
    HeaderModule
  ]
})
export class UserInteractionsModule {
}
