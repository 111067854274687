import {Type} from "@angular/core";
import {CalculationArea} from "@modules/calculation-area/Domain/CalculationArea/calculation-area";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {
  CalculatorAreaComponent
} from "@modules/calculation-area/Domain/CalculatorAreaComponent/calculator-area-component";
import {
  CalculationStrategyID
} from "@modules/calculation-strategy/Domain/CalculationStrategy/VO/calculation-strategy-id";
import {TotalComponent} from "@modules/calculation-total/Domain/CalculatorTotalTabsComponent/total-component";
import {CalculationAreaTotal} from "@modules/calculation/Domain/CalculationAreaTotal/calculation-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";
import {CalculationTotal} from "@modules/calculation/Domain/CalculationTotal/calculation-total";
import {CalculationTotalFactory} from "@modules/calculation/Domain/CalculationTotal/calculation-total-factory";
import {GmInputs} from "@modules/gm-inputs/Domain/GmInputs/gm-inputs";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";

export abstract class CalculationStrategy<
  TInputModel extends CalculationArea = CalculationArea,
  TAreaResult extends CalculationAreaTotal = CalculationAreaTotal,
  TAreaFactory extends CalculationAreaFactory = CalculationAreaFactory,
  TTotalAreaFactory extends CalculationAreaTotalFactory = CalculationAreaTotalFactory
> {
  constructor(
    readonly id: CalculationStrategyID,
    readonly name: string,
    readonly getProductsByCategoryQuery: GetProductsByCategoryNameQuery,
    readonly areaTemplate: Type<CalculatorAreaComponent<CalculationArea>>,
    readonly totalTemplate: Type<TotalComponent>,
    readonly totalFactory: CalculationTotalFactory,
    readonly areaFactory: TAreaFactory,
    readonly areaTotalFactory: TTotalAreaFactory,
  ) {
  }

  /**
   * Calculate the total based on the input models and GM inputs.
   * @param input - The input models for the calculation.
   * @param gmInputs - The GM inputs for the calculation.
   * @returns A promise that resolves to the calculation total.
   */
  abstract calculateTotal(input: TInputModel[], gmInputs: GmInputs): Promise<CalculationTotal>;

  /**
   * Calculate the area result based on the input model and GM inputs.
   * @param input - The input model for the calculation.
   * @param gmInputs - The GM inputs for the calculation.
   * @returns A promise that resolves to the area result.
   */
  abstract calculateArea(input: TInputModel, gmInputs: GmInputs): Promise<TAreaResult>;
}
