<ion-content>
  <app-header
    [title]="title"
  ></app-header>
  @if (loading || migrating) {
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  }
  <ion-list>
    <ion-item *ngIf="!migrating && calculationsCount">
      <ion-label>Areas count</ion-label>
      <ion-label color="primary" slot="end">
        <span>{{ calculationsCount }}</span>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="processedCalculationsCount">
      <ion-label>Processed</ion-label>
      <ion-label color="primary" slot="end">
        <span>{{ processedCalculationsCount }}</span>
      </ion-label>
    </ion-item>


    <ion-item *ngIf="calculationsCount - processedCalculationsCount !== calculationsCount">
      <ion-label>Left</ion-label>
      <ion-label color="primary" slot="end">
        <span>{{ calculationsCount - processedCalculationsCount }}</span>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="getReadyPercent()">
      <ion-progress-bar [value]="getReadyPercent()" class="bar"></ion-progress-bar>
    </ion-item>
  </ion-list>

  @if (migrating) {
    <ion-grid>
      <ion-row>
        <ion-col>

          <ion-card>
            <ion-card-header>
              <ion-card-title color="primary" class="ion-text-center">Hang tight, we're migrating, please do not close
                the app.
              </ion-card-title>
            </ion-card-header>
          </ion-card>

        </ion-col>
      </ion-row>
    </ion-grid>
  }

  @if (!loading && !migrating) {
    <ion-grid>
      <ion-row>
        <ion-col>

          <ion-card>
            <ion-card-header>
              <ion-card-title>Products migrator</ion-card-title>
              <ion-card-subtitle>This will migrate all categories and products</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-button (click)="migrateProducts()" expand="block">Migrate products</ion-button>
            </ion-card-content>
          </ion-card>
        </ion-col>

        <ion-col>
          <ion-card>
            <ion-card-header>
              <ion-card-title>Areas migrator</ion-card-title>
              <ion-card-subtitle>This will try to find old products in the existing estimates and migrate them to new
                products
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-button (click)="migrateAreas()" expand="block">Migrate areas</ion-button>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  }
</ion-content>


