import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {
  FieldValidationRule
} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validation-rule";
import {ValidationResult} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/validation-result";

export class RequiredFieldValidatorRule implements FieldValidationRule {
  validate(field: Field): ValidationResult {
    const errors: string[] = [];

    const value = field.getValue();
    if (value === null || value === undefined || value === '') {
      errors.push('Field is required');
    }

    return ValidationResult.fromStrings(field.name.getValue(), errors);
  }
}

