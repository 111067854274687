import {ValueObject} from "@modules/_shared/Domain/value-object";
import {ProductFieldEmpty} from "@modules/product/product/Domain/Product/Exception/product-field-invalid-exception";

export class ProductPrice extends ValueObject<number> {
  protected override _type!: void;

  constructor(value: number) {
    if (value === undefined) throw new ProductFieldEmpty('Price');
  
    if (value <= 0) {
      throw new Error(`The product price must be greater than 0`);
    }
    super(Number(value));
  }
}
