import {Injectable} from "@angular/core";
import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {
  CalculationAreaFactory
} from "@modules/calculation-area/Domain/CalculationArea/Factory/calculation-area-factory";
import {MiscArea} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area";
import {
  MiscAreaItem,
  ProductMiscAreaItem
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-item";
import {
  MiscAreaItemOptions,
  MiscAreaOptions
} from "@modules/calculation-impl/miscellaneous-calculator/Domain/CalculationArea/misc-area-options";
import {
  GetProductsByCategoryNameQuery
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-query.service";
import {
  GetProductsByCategoryNameRequest
} from "@modules/product/product/Application/UseCase/Query/GetProductsByCategoryName/get-products-by-category-name-request";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";
import {MiscCategoryName} from "@modules/product/products/misc-product/Domain/Category/misc-category-name";
import {MiscProducts} from "@modules/product/products/misc-product/Domain/Product/misc-products";

@Injectable({
  providedIn: 'root'
})
export class MiscAreaFactory extends CalculationAreaFactory<MiscArea, MiscAreaOptions> {

  private readonly defaults: MiscAreaOptions = {
    id: '',
    name: 'Miscellaneous',
    laborCrew: [],
    items: [],
    businessUnitID: new BusinessUnitID('')
  }

  constructor(
    private readonly getProductsByCategoryNameQuery: GetProductsByCategoryNameQuery
  ) {
    super();
  }

  async execute(data: MiscAreaOptions): Promise<MiscArea> {
    const dataItems = await this.createItems(data.items ?? []);
    return new MiscArea(
      data.id || this.defaults.id,
      data.name || this.defaults.name,
      dataItems,
      data.businessUnitID
    );
  }

  createState(area: MiscArea): Promise<MiscAreaOptions> {
    const items = area.items.map((item): MiscAreaItemOptions => {
      if (item instanceof ProductMiscAreaItem) {
        return {
          productID: item.product.id.toString(),
          count: item.count
        };
      }
      return {
        name: item.name,
        value: item.getRawValue(),
        count: item.count
      }
    })

    const areaOptions = {
      id: area.id,
      name: area.name,
      items,
      businessUnitID: area.businessUnitID,
      laborCrew: area.laborCrew
    };

    return Promise.resolve(areaOptions);
  }

  public async createItems(items: MiscAreaItemOptions[]): Promise<MiscAreaItem[]> {
    return await Promise.all(items.map(item => this.createItem(item)));
  }

  private createItem(item: MiscAreaItemOptions): Promise<MiscAreaItem> {
    if (item.productID && item.count) {
      return this.createProductItem(item.productID, item.count);
    }

    if (item.name && item.value !== undefined) {
      return Promise.resolve(new MiscAreaItem(item.name, item.value, item.count ?? 1));
    }

    throw new Error(`Invalid item: ${JSON.stringify(item)}`);
  }


  private async createProductItem(productID: string, count: number): Promise<ProductMiscAreaItem> {
    const request: GetProductsByCategoryNameRequest = {categoryName: new MiscCategoryName()};
    const products = await this.getProductsByCategoryNameQuery.execute<MiscProducts>(request);
    const product = products.getByID(new ProductID(productID));
    if (!product) {
      throw new Error(`Product with ID ${productID} not found`);
    }
    return new ProductMiscAreaItem(product, count);
  }
}
