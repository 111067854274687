import {Injectable} from "@angular/core";
import {
  AppInitBusinessUnitsProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-business-units-provider.service";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";

@Injectable({providedIn: 'root'})
export class GetBusinessUnitsUseCase {
  constructor(
    private readonly appInitBusinessUnitsProvider: AppInitBusinessUnitsProvider
  ) {
  }

  execute(): BusinessUnits {
    return this.appInitBusinessUnitsProvider.getBusinessUnits();
  }
}
