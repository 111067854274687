import {FoamService} from "@modules/calculation-impl/foam/service/Domain/foam-service";
import {Products} from "@modules/product/product/Domain/Product/products";
import {FoamProduct} from "@modules/product/products/foam-product/Domain/Product/foam-product";

export class FoamProducts extends Products<FoamProduct> {
  filterProductsByService(service: FoamService): FoamProducts {
    return new FoamProducts(
      this.products.filter(product => product.isMatchingService(service))
    );
  }
}
