import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {Product} from "@modules/product/product/Domain/Product/product";

export class ProductLinkProvider {
  static BASE_URL: string = 'products';


  static getBaseRoute(): string[] {
    return ['/', ProductLinkProvider.BASE_URL];
  }

  static getProductEditRoute(product: Product): string[] {
    return [
      ...ProductLinkProvider.getBaseRoute(),
      'product-edit',
      product.productID
    ];
  }

  static getCategoryProductListRoute(id: CategoryID) {
    return [
      ...ProductLinkProvider.getBaseRoute(),
      'category-product-list',
      id.toString()
    ];
  }

  static getCreateProductRoute(categoryID: string): string[] {
    return [
      ...ProductLinkProvider.getBaseRoute(),
      'product-create',
      categoryID
    ];
  }
}
