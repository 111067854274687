import {BusinessUnitID} from "@modules/business-unit/Domain/BusinessUnit/VO/business-unit-i-d";
import {CategoryID} from "@modules/product/product-category/Domain/Category/VO/category-id";
import {CategoryName} from "@modules/product/product-category/Domain/Category/VO/category-name";
import {Product} from "@modules/product/product/Domain/Product/product";
import {Products} from "@modules/product/product/Domain/Product/products";
import {ProductID} from "@modules/product/product/Domain/Product/VO/product-id";

export abstract class ProductRepository {
  abstract getAllByCategoryName<T extends Products>(category: CategoryName, businessUnitID: BusinessUnitID): Promise<T>

  abstract getAllByCategoryID<T extends Products>(categoryID: CategoryID, businessUnitID: BusinessUnitID): Promise<T>

  abstract getOne<T extends Product>(productID: ProductID): Promise<T>

  abstract create<T extends Product>(product: T): Promise<T>

  abstract update<T extends Product>(product: T): Promise<T>

  abstract updatePrice<T extends Product>(product: T): Promise<T>

  abstract findZeeProduct<T extends Product>(corporateProductID: ProductID, businessUnitID: BusinessUnitID): Promise<T>;

  abstract clearBusinessUnitProducts(businessUnitID: BusinessUnitID): Promise<void>;

  abstract delete(id: ProductID): Promise<void>;
}
