import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotificationService} from "@modules/_shared/Service/Notification/notification-service";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {
  GetUserAvailableBusinessUnitsQuery
} from "@modules/business-unit/Application/UseCase/Query/get-user-available-b-u-query.service";
import {BusinessUnit} from "@modules/business-unit/Domain/BusinessUnit/business-unit";
import {BusinessUnits} from "@modules/business-unit/Domain/BusinessUnit/business-units";
import {RightButton} from "@modules/header/Presentation/header/Model/right-button";
import {
  JobLinkProvider
} from "@modules/service-titan/service-titan-job/Presentation/Provider/service-titan-job-link-provider.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
    selector: 'app-business-unit-list-selector',
    templateUrl: './business-unit-list-selector.component.html',
    styleUrls: ['./business-unit-list-selector.component.scss'],
    standalone: false
})
export class BusinessUnitListSelectorComponent implements OnInit {
  backLink: string[] = ['/'];
  rightButton: RightButton = {
    title: 'Jobs',
    callback: () => this.router.navigate(JobLinkProvider.getJobsListLink()),
    icon: 'chevron-forward-outline'
  };
  protected selectedBusinessUnit: BusinessUnit | null = null;
  protected searchTerm: string = '';
  protected businessUnits?: BusinessUnits;
  protected filteredBusinessUnits?: BusinessUnits;
  protected loading = false;
  protected title: string = 'Select Business Unit';


  constructor(
    private readonly getUserAvailableBUQuery: GetUserAvailableBusinessUnitsQuery,
    private readonly notificationService: NotificationService,
    private readonly userSelectedBusinessUnit: UserSelectedBusinessUnit,
    private readonly router: Router
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.setupSelectedBusinessUnitSubscription();
    await this.loadProfileAndBusinessUnits();
  }

  setupSelectedBusinessUnitSubscription(): void {
    this.userSelectedBusinessUnit.selectedBusinessUnit$
      .pipe(untilDestroyed(this))
      .subscribe((businessUnit) => {
        this.selectedBusinessUnit = businessUnit;
      });
  }

  searchBusinessUnits(searchTerm: string): void {
    if (!this.businessUnits) {
      this.filteredBusinessUnits = undefined;
      return;
    }

    this.filteredBusinessUnits = this.businessUnits.filter(searchTerm);
  }

  async selectBusinessUnit(businessUnit: BusinessUnit): Promise<void> {
    this.userSelectedBusinessUnit.setSelectedBusinessUnit(businessUnit);
    await this.notificationService.execute('Business Unit changed to ' + businessUnit.name);
  }

  private async loadProfileAndBusinessUnits(): Promise<void> {
    this.loading = true;

    try {
      this.filteredBusinessUnits = this.businessUnits = await this.getUserAvailableBUQuery.execute();
      this.searchTerm = '';
    } catch (e) {
      await this.notificationService.execute(e);
    } finally {
      this.loading = false;
    }
  }
}
