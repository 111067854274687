import {Injectable} from "@angular/core";
import {
  BlowInAreaTotal
} from "@modules/calculation-impl/blow-in-calculator/_calculator/Domain/CalculationAreaTotal/blow-in-area-total";
import {
  CalculationAreaTotalFactory
} from "@modules/calculation/Domain/CalculationAreaTotal/Factory/calculation-area-total-factory";

@Injectable({
  providedIn: 'root'
})
export class BlowInAreaTotalFactory implements CalculationAreaTotalFactory<BlowInAreaTotal> {
  private readonly defaults: BlowInAreaTotal = {
    laborHours: 0,
    laborCost: 0,
    netProduct: null,
    blowInProduct: '',
    sqft: 0,
    bags: 0,
    materialCost: 0,
    materialSalesTax: 0,
    totalCost: 0,
    misc: 0,
    blowStacksNeeded: 0,
    netRollsUsed: 0,
    netMaterialCost: 0,
    blowInMaterialCost: 0,
    fields: []
  };

  execute(data: Partial<BlowInAreaTotal> = {}): BlowInAreaTotal {
    const mergedData = {...this.defaults, ...data};
    return new BlowInAreaTotal(
      mergedData.laborHours,
      mergedData.laborCost,
      mergedData.netProduct,
      mergedData.blowInProduct,
      mergedData.sqft,
      mergedData.bags,
      mergedData.materialCost,
      mergedData.materialSalesTax,
      mergedData.totalCost,
      mergedData.misc,
      mergedData.blowStacksNeeded,
      mergedData.netRollsUsed,
      mergedData.netMaterialCost,
      mergedData.blowInMaterialCost
    );
  }
}
