import {HashLocationStrategy, LocationStrategy} from "@angular/common";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {APP_INITIALIZER, ErrorHandler, inject, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouteReuseStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {
  AppInitBusinessUnitsProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-business-units-provider.service";
import {
  AppInitUserProfileProvider
} from "@modules/_shared/Service/AppInitLoaders/app-init-user-profile-provider.service";
import {ICalcErrorHandler} from "@modules/_shared/Service/ErrorHandler/icalc-error-handler";
import {
  UserSelectedBusinessUnit
} from "@modules/_shared/Service/SelectedBusinessUnit/user-selected-business-unit.service";
import {SharedModule} from "@modules/_shared/shared.module";
import {environment} from "@modules/environments/environment";
import {EnvironmentType} from "@modules/environments/environment-type";
import {MicrosoftAuthModule} from "@modules/microsoft/microsoft-auth/microsoft-auth.module";
import {UserInteractionsLogInTracer} from "@modules/reporting/Infrastructure/LoginTrace/user-log-in-tracer.service";
import * as Sentry from "@sentry/angular";
import {TraceService} from "@sentry/angular";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

const appInit = () => {
  inject(TraceService);
  const appInitUserProfileProvider = inject(AppInitUserProfileProvider);
  const appInitBusinessUnitsProvider = inject(AppInitBusinessUnitsProvider);
  const userLogInTracer = inject(UserInteractionsLogInTracer);
  const userSelectedBusinessUnit = inject(UserSelectedBusinessUnit);

  userLogInTracer.startTracing();

  return async () => {
    await Promise.all([
      appInitUserProfileProvider.init(),
      appInitBusinessUnitsProvider.init(),
    ])
    userSelectedBusinessUnit.init();
  }
}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.environment === EnvironmentType.production,
      registrationStrategy: 'registerWhenStable:10'
    }),
    AppRoutingModule,
    SharedModule,
    MicrosoftAuthModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: ErrorHandler,
      useFactory: () => new ICalcErrorHandler(
        Sentry.createErrorHandler({
          showDialog: false,
        })
      ),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true
    },

  ],
  bootstrap: [
    AppComponent,
  ],
  exports: []
})
export class AppModule {
}
