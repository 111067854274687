import {FieldType} from "@modules/product/product-category-fields/Domain/Field/Field/field-type";
import {Field} from "@modules/product/product-category-fields/Domain/Field/Field/VO/field";
import {
  InvalidFieldsConfigJson
} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/Exception/invalid-fields-config-json";
import {FieldConfigName} from "@modules/product/product-category-fields/Domain/Field/FieldConfig/VO/field-config-name";
import {FieldValidator} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/field-validator";
import {ValidationResult} from "@modules/product/product-category-fields/Domain/Field/FieldValidator/validation-result";

export class FieldConfig {
  constructor(
    public readonly name: FieldConfigName,
    public readonly label: string,
    public readonly description: string,
    public readonly type: FieldType,
    public readonly validator: FieldValidator,
    public readonly options?: string[],
  ) {
  }

  validate(field: Field): ValidationResult {
    if (field.type === FieldType.Dropdown && !this.options) {
      throw new InvalidFieldsConfigJson(`Field ${this.name} must have options`);
    }

    return this.validator.validate(field);
  }
}


