import {FoamProduct} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-product";
import {FoamProducts} from "@modules/product/old-products/Configurations/foam-product/Domain/foam-products";
import {
  FoamProductDynamicsProductsFactory
} from "@modules/product/old-products/Configurations/foam-product/Infrastructure/Factory/foam-dynamics-products-factory.service";
import {DynamicsProductConfiguration} from "@modules/product/old-products/Infrastructure/dynamics-product-configuration";

export const FoamDynamicsProductConfiguration: DynamicsProductConfiguration<FoamProducts, FoamProduct> = {
  productsTable: 'cr9b4_ifoamicalcproductsfoams',
  columns: [
    'cr9b4_ifoamicalcproductsfoamid',
    'cr9b4_manufacturer',
    'cr9b4_type',
    'cr9b4_name',
    'cr9b4_productyield',
    'cr9b4_costpersetor5gallonbucket',
  ],
  productsFactory: new FoamProductDynamicsProductsFactory()
}
