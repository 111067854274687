import {Injectable} from "@angular/core";
import {CalculatorTab} from "../../../Domain/CalculatorTab/calculator-tab";
import {CalculatorTabRepository} from "../../../Domain/CalculatorTab/Repository/calculator-tab-repository";

@Injectable({
  providedIn: 'root'
})
export class UpdateCalculatorTabUseCase {
  constructor(
    private calculatorTabRepository: CalculatorTabRepository,
  ) {
  }

  async execute(tab: Partial<CalculatorTab>): Promise<void> {
    await this.calculatorTabRepository.updateCalculatorTab(tab);
  }
}
